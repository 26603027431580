import React, { useState, useEffect } from 'react';
import './assets/costReports.css';
import { Bar } from 'react-chartjs-2';
import  StackedBarChart  from './StackedBar'
import DatabaseProductReports from './DatabaseProductReports'
import Loader from '../common/Loader'


function CostReports() {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [title, setTitle] = useState('')

	const [startDate, setStartDate] = useState('')

	const [data, setData] = useState({})

	const [endDate, setEndDate] = useState('')

	const [month , setMonth] = useState('')

	const [labels, setLabels] = useState([])

	const [dataset, setDataSet] = useState([])

	const [loadData, setLoadData] = useState(false)

	function getCurrentMonthDatesInISOFormat() {
		  
		  const currentDate = new Date();

		  const currentYear = currentDate.getFullYear();
		  const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so add 1.

		  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

		  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);

		  const startDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`;
		  const endDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(lastDayOfMonth.getDate()).padStart(2, '0')}`;

		  return { startDate, endDate };
	}


	function formatDateToMonthYear(dateString) {
		  const months = [
		    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		  ];

		  const [year, month, day] = dateString.split('-');
		  const formattedDate = `${months[parseInt(month) - 1]} ${year}`;
		  return formattedDate;
	}

	useEffect(() => {
		const { startDate, endDate } = getCurrentMonthDatesInISOFormat();
		setStartDate(startDate)
		setEndDate(endDate)
		console.log(formatDateToMonthYear(startDate))
		setMonth(formatDateToMonthYear(startDate))
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   fetch(BASE_URL + "/v1/report/graph", {
		     	method : 'GET',
		    	headers : {
		        	'x-api-key' : apikey,
		        	'oauth-token' : oauth_token
		      	}
		    }).then(response => response.json())
	   .then(data => {
	   		var response_data = data['message']
	   		var labels =  Object.keys(response_data)
	   		setData(response_data)
	   		
	   		console.log("Hi")
	   		var datasets = constructDataSet(labels, response_data)
	   		console.log("DAta sets is")
	   		console.log(datasets)
	   		setDataSet(datasets)
	   		setLoadData(true)
	   })

	}, [])


	function getRandomInt(min, max) {
   		return Math.floor(Math.random() * (max - min + 1)) + min;
	}


	function randomRGB() {
    	const r = getRandomInt(50, 250);
    	const g = getRandomInt(160, 190);
    	const b = getRandomInt(190, 250);
    	return `rgb(${r}, ${g}, ${b})`;
	}


	function constructDataSet(labels, response_data) {
		var dates = getMonths()
		var datasets = []
		for( var i in labels ) {
			var label = labels[i]
			var currDataSet = {}
			var currProductData = response_data[label]
			var productCostValues = []
			for ( var j in dates) {
				var date = dates[j]
				if( currProductData.hasOwnProperty(date) ) {
					productCostValues.push(currProductData[date])
				} else {
					productCostValues.push('0')
				}
			}
			currDataSet['label'] = label
			currDataSet['data'] = productCostValues.reverse()
			currDataSet['backgroundColor'] = randomRGB()
			datasets.push(currDataSet)
		}
		setLabels(dates.reverse())
		return datasets
	}

	function getMonths() {
		const today = new Date();
		today.setDate(15) // setting the date to 15, so that current month -1 doesnt return the same month for 31st
		const months = [];

		for (let i = 0; i < 12; i++) {
		    const year = today.getFullYear();
		    const month = today.getMonth() + 1; // Months are 0-based, so we add 1
		    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
		    const formattedDate = `${year}-${formattedMonth}`;
		    
		    months.push(formattedDate);

		    // Move to the previous month
		    today.setMonth(today.getMonth() - 1);
		}
		return months;
	}
 
	function handleDateChange(selectedMonth, startDate, endDate) {
		setMonth(selectedMonth)
		setStartDate(startDate)
		setEndDate(endDate)
	}

	return (
			<div className="App-CS-tab-container">
				{loadData ?
					<div className="App-CS-tab-container">
						<div className="App-CS-tab-container-header">
							<div className="App-CS-tab-container-header-title">
								Cost Report
							</div>
						</div>
						<div className="App-CS-tab-chart-container">
							<div className="App-CS-tab-chart-title-header">
								<div className="App-CS-tab-chart-title">
									Cost Report Home
								</div>
							</div>
							<div className="App-CS-tab-chart-graph">
								<div className="App-CS-tab-chart-graph-title-header"> 
									<div className="App-CS-tab-chart-graph-title"> 
										<div className="App-CS-tab-chart-graph-title-word">
											DB Costs by Products 
										</div>
									</div>
								</div>
								<div className="App-CS-tab-chart-graph-container">
									<StackedBarChart dataset={dataset} labels={labels} handleDateChange={handleDateChange} />
								</div>
							</div>
						</div>
						<div className="App-CS-tab-drilldown-container">
							<div className="App-CS-tab-drilldown-inner">
								<div className="App-CS-tab-drilldown-title-header"> 
									<div className="App-CS-tab-drilldown-title">
										<div className="App-CS-tab-drilldown-title-word">
											Cost Summary for {month}
										</div>
									</div>
								</div>
								<div className="App-CS-tab-drilldown">
									<DatabaseProductReports startDate={startDate} endDate={endDate} />
								</div>
							</div>
						</div>
					</div>
				: 
					<Loader />
				}
			</div>
		)
}

export default CostReports