import React, {useEffect} from 'react';
import logo from './tekion-logo.png';
import './assets/title.css';
import ninedots from './nine-dots.svg'

function Title(props) {

  function handleMenuClick() {
    props.handleMenuSelection(true)
  }

  return (
    <div className="App-title-block">
        <div className="App-title-block-9-icon">
            <img src={ninedots} className="App-title-block-9-image" onClick={handleMenuClick} />
        </div>
          {!( navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1 ) ?
                <div className="App-title-block-title">
                  <img src={logo} alt="" className="App-title-block-title-logo"/>
                </div>
              :
                <div className="App-title-block-title-safari">
                  <img src={logo} alt="" className="App-title-block-title-logo-safari"/>
                </div>
          }
        <div className="App-title-block-logout" onClick={props.handleLogout}>
            Logout
        </div>
    </div>
  );
}

export default Title;
