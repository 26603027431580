import React, { useState, useEffect } from 'react';
import './assets/databaseActivities.css'
import { Tabs, Button } from 'antd'
import DActivities from './DActivities'
import NeedsReview from './NeedsReview'
import Rejected from './Rejected'
import CreateActivity from './CreateActivity'

function DatabaseActivities(props) {

	const [size, setSize] = useState('large');

	const [showAdd, setShowAdd] = useState(false)

	const [showCreate, setShowCreate] = useState(false)

	const [loadData, setLoadData] = useState(true)

	const [tabActiveKey, setTabActiveKey] = useState('1')

	const items = [ {label : <div> Activities </div>, key : '1', children : <div className="App-DA-tab-container"> <DActivities loadData={loadData} handleLoadData={handleLoadData} handleLoadComplete={handleLoadComplete}/> </div>},
					{label : <div> Needs Review </div>, key : '2', children : <div className="App-DA-tab-container"> <NeedsReview loadData={loadData} handleLoadData={handleLoadData} handleLoadComplete={handleLoadComplete} /> </div>}, 
					{label : <div> Rejected </div>, key : '3', children : <div className="App-DA-tab-container"> <Rejected loadData={loadData} handleLoadData={handleLoadData} handleLoadComplete={handleLoadComplete} /> </div>}
				]

	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  		setTabActiveKey('2')
  	}

  	function handleTabChange(activeKey) {
  		setTabActiveKey(activeKey)
  		setLoadData(true)
  	}

  	function handleLoadComplete() {
  		setLoadData(false)
  	}

  	function handleLoadData() {
  		console.log("Inside Handle Load Data")
  		setLoadData(true)
  		setTabActiveKey('2')
  	}

	return(
			<div className="App-DA-table-container">
				<div className="App-DA-table-container-header">
					<div className="App-DA-table-container-header-title">
           				Database Activities
           			</div>
           			{showAdd ? 
	           			<div className="App-DI-table-container-header-add-new">
	           				<Button 
	              				type='primary'
	              				onClick = {() => handleNewClick()}
	              			>
              					Add Activity
            				</Button>
           				</div> 
           				: 
           					<div></div> 
           			}
           		</div>
				<div className="App-DA-table-container-body">
					<Tabs
				        defaultActiveKey={tabActiveKey}
				        items={items}
        				size={size}
        				onChange={handleTabChange}
        				activeKey={tabActiveKey}
        				tabBarGutter="2rem"
			      	/>
				</div>
				{showCreate ? <CreateActivity  handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}
			</div>
		)

}

export default DatabaseActivities