import React, { useState, useEffect, useMemo } from 'react';
import './assets/createCost.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateEnvironment(props) {

	const [form] = Form.useForm();

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const { TextArea } = Input;

	const [invId, setInvId] = useState('')

	const [cost, setCost] = useState('')

	const [usageType, setUsageType] = useState('')

	const [year, setYear] = useState('')

	const [month, setMonth] = useState('')

	const [disableSave, setDisableSave] = useState(true)

	const options=[
        {
          value: 'tk-prod',
          label: 'tk-prod'
        }]

  useEffect(() => {
  	if( invId != "" && cost != "") {
  		setDisableSave(false)
  	} else {
  		setDisableSave(true)
  	}

  }, [invId, cost])

	function handleInvIdChange(e) {
		setInvId(e.target.value)
	}

	function handleUsageTypeChange(e) {
		console.log(e.target.value)
		setUsageType(e.target.value)
	}

	function handleCostChange(e) {
		setCost(e.target.value)
	}

	function handleYearChange(e) {
		setYear(e.target.value)
	} 

	function handleMonthChange(e) {
		setMonth(e.target.value)
	}

	function handleClose() {
		props.handleClose()
	}

	function handleCreateNode() {
		console.log("Hello")
		var apikey = localStorage.getItem('db_portal_app_apikey')
   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/cost' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "db_line_item_usage_type": usageType,
	            "db_line_item_resource_id" : invId,
	            "db_cost" : cost,
	            "db_year" : year,
	            "db_month" : month
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-COST-create-node-container">
				<div className="App-COST-create-node-modal-content">
					<div className="App-COST-create-node-modal-header">
		 				<div className="App-COST-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-COST-create-node-modal-header-content"> Create Cost </div>
					</div>
					<div className="App-COST-create-node-modal-body">
						<div className="App-COST-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      					<div className="inline">
      							<Form.Item label="Inventory ID" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here"  onChange={handleInvIdChange} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Usage Type" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here"  onChange={handleUsageTypeChange} />
      							</Form.Item>
      							<Form.Item label="Cost">
        							<Input placeholder="Type Here"  onChange={handleCostChange} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Start Year" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleYearChange}  />
      							</Form.Item>
      							<Form.Item label="Start Month">
        							<Input placeholder="Type Here"  onChange={handleMonthChange} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Cost Date" style={{marginRight:'auto'}}>
        							<Input type='date' placeholder="Type Here"   />
      							</Form.Item>
      							<Form.Item label="Start Month">
        							<Input placeholder="Type Here"  onChange={handleMonthChange} />
      							</Form.Item>
      						</div>
      					</Form>
      					</div>
					</div>
					<div className="App-COST-create-node-modal-footer">
						<div className="App-COST-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateNode()}
	              				disabled = {disableSave}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateEnvironment
