import React, { useState, useEffect, useMemo, useRef } from 'react';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import './assets/unUsedIndex.css';
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'

function UnUsedIndex(props) {


	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [showTable, setShowTable] = useState(false)

	const [tableColumns, setTableColumns] = useState([])

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [loadData, setLoadData] = useState(false)

	const [showForm, setShowForm] = useState(false)

	const [showSearch, setShowSearch] = useState(false)

	const [uuiFilters, setUUIFilters] = useState({})

	const [selectedCollections, setSelectedCollections] = useState([])

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [disableCollections, setDisableCollections] = useState(true)

	const [availableCollections, setAvailableCollections] = useState([])

	const [originalData, setOriginalData] = useState([])

	const [goLiveData, setGoLiveData] = useState([])

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Collection Name",
				accessor : "collectionname",
				width : 300
			},
			{
				Header : "Index Name",
				accessor : "index_name"
			},
			{
				Header : "Min Ops",
				accessor : "min_ops"
			},
			{
				Header : "Max Ops",
				accessor : "max_ops"
			},
			{
				Header : "Average Ops",
				accessor : "avg_ops",
			},
			{
				Header : "Index Info",
				accessor : "index_info",
				width : 300
			},
			{
				Header : "Day Data",
				accessor : "day_data",
			},
			{
				Header : "Unused",
				accessor : "usused",
				width : 200,
				Cell : row => (
						row.row.original.usused ? <div className="App-UUI-Status-container" >  <div className="App-UUI-Success"> </div> </div> : <div> <div className="App-UUI-Fail"> </div> </div> 
					)
			}
		])

	useEffect(() => {
		if (loadData) {
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/filtered-collection?collectionName=" + selectedCollections.toString(),
	  		  	{
			      method : 'GET',	
			      headers : {
			        'x-api-key' : apikey,
			        'oauth-token' : oauth_token
			     }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Asdasda")
		    console.log(result)
		    console.log(transformJson(result))
		    setOriginalData(transformJson(result))
		    setGoLiveData(transformJson(result))
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	function transformJson(input) {
    	const message = input;
    	const transformedArray = [];

    	for (const collectionName in message) {
        	if (message.hasOwnProperty(collectionName)) {
            	message[collectionName].forEach(item => {
                	transformedArray.push({
                    	...item,
                    	collectionname: collectionName
                	});
            	});
        	}
    	}

    return  transformedArray;
}

	useEffect(() => {
		if(showForm) {
				setDisableCollections(false)
				setAvailableCollections(constructFilterArray(uuiFilters['coll_names']))
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])


	useEffect(() => {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   	const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/collections"
			      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setUUIFilters(result)
		    setShowForm(true)
		}
		doFetch()
	}, [])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

	function handleSelectionChange(event) {
		console.log(event)
		setSelectedCollections(event)
		setShowReportDisable(false)
	}

	function handleButtonClick(event) {
		console.log(event)
		setTableColumns(mongoColumns)
		if (!loadData) {
			setGoLiveData([])
			setShowTable(false)
			setLoadData(true)
		}
	}

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

  	const onSearch = (input) => globalSearch(input);

	function globalSearch(input)  {
	    let searchInput = input;
	    if (searchInput == "") {
	    	setGoLiveData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.collectionname &&  value.collectionname.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.index_name && value.index_name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setGoLiveData(filteredData);
			}
  	};


	function handleExportClick() {
	
	}

	function handleReset() {
		setSelectedCollections([])
		setShowReportDisable(true)
		setOriginalData([])
		setGoLiveData([])
	}

	return(
			<div className="App-GLDC-tab-container">
				<div className="App-body-GLDC-Header-Container">
					<div className="App-body-GLDC-Header-Title-Container">
						<div className="App-body-GLDC-Header-Title">
							<div className="App-body-GLDC-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-GLDC-Header-Title-Name">
								UnUsed Index
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ?
				<div className="App-GLDC-tab-body">
					<div className="App-GLDC-tab-mongo-container">
						<div className="App-GLDC-tab-mongo-filter">		
									<div className="App-GLDC-tab-mongo-form">
									{showForm ? 
										<div className="App-GLDC-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="GLDC-inline">
				      								<Form.Item label="Collection Name" style={{width : '65%', margin:'1em 2em'}}>
				      									<Select mode="multiple" disabled={disableCollections} options={availableCollections} value={selectedCollections} onChange={handleSelectionChange}/>
					      							</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : '3em 2em'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : '3em 1em'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-SQ-tab-form-loader">
			      							<Loader />
			      						</div>
									}
									</div>

						</div>
						<div className="App-GLDC-tab-mongo-data-container">
							<div className="App-GLDC-tab-body-table-header-container">
								<div className="App-GLDC-table-container-search-box">
									<div className="App-body-GLDC-header-export">
										<img src={ExportIcon} onClick={handleExportClick} />
									</div>
									{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    							</div>
    						</div>
							{showTable ?
								<div className="App-GLDC-tab-body-table-container">
									<NewTable data={goLiveData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-GLDC-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
					</div>			
				</div>
				: 
				 <div className="App-SQ-tab-form-loader">
				 	<Loader />
				 </div>
				}
			</div>	
		)

}

export default UnUsedIndex