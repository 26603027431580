import React, { useState, useEffect, useMemo } from 'react';
import './assets/dActivities.css'
import NewTable from '../common/NewTable'
import { Button, Radio, Input } from 'antd';
import SearchIcon from './assets/search.svg'
import SettingsIcon from './assets/settings.svg'
import AdditionalInsights from './AdditionalInsights'
import BurgerMenu from './BurgerMenu'
import {Modal} from 'antd'


function DActivities(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

  	const [showLoading, setShowLoading] = useState(true)

  	const [showCreate, setShowCreate] = useState(false)

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)

	const [showAddInfo, setShowAddInfo] = useState(false)

	const menu = ['Mark for Review']

	const onSearch = (input) => globalSearch(input);

	const { Search } = Input;
	const { confirm } = Modal;

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

  	const columns = useMemo(
	  () => [
	    {
	      Header: "Node Name",
	      accessor: "db_node_name"
	    },
	    {
	      Header: "Activity Type",
	      accessor: "db_activity_type"
	    },{
	      Header: "Field Changed",
	      accessor: "db_field_changed"
	    },{
	      Header: "Old Field Content",
	      accessor: "db_old_field_content"
	    },{
	      Header: "New Field Content",
	      accessor: "db_new_field_content"
	    },{
	      Header: "Jira",
	      accessor: "db_jira"
	    },{
	      Header: "Approved By",
	      accessor: "db_approved_by"
	    },
	    {
	      Header: "Updated Time",
	      accessor: "updated_time"
	    },
	    {
	      Header: "Comments",
	      accessor: "db_comments"
	    },
	    {
	      Header: "Cost Effect - System Generated",
	      accessor: "db_cost_effect_system",
	      Cell : row => (
	      		<div className="App-body-CS-Right-Cell">
	      			{row.row.original.db_cost_effect_system  && formatter.format(row.row.original.db_cost_effect_system)}
	      		</div>
	      	)
	    },
	    {
	      Header: "Cost Effect - Manual Estimated",
	      accessor: "db_cost_effect_manual",
	      Cell : row => (
	      		<div className="App-body-CS-Right-Cell">
	      			{formatter.format(row.row.original.db_cost_effect_manual)}
	      		</div>
	      	)
	    },
	    {
	      Header: "POC",
	      accessor: "db_poc"
	    },
	    {
	      Header : "Activity Date-time",
	      accessor : "created_time"
	    }
	  ],
	  []
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	const showConfirm = (data) => {
		console.log(data)
		confirm({
		title: 'Do you want to move this activity to the Needs Review tab?',
		content: '',
		onOk() {
		  return new Promise((resolve, reject) => {
		  	var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/activity", {
		      method : 'PUT',
		      headers : {
		      	'Accept':'application/json',
	        	'Content-Type':'application/json',
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      },
		      body : JSON.stringify({
	            		'db_endpoint' : data.db_endpoint,
	            		'db_node_name' : data.db_node_name,
	            		'db_activity_type' : data.db_activity_type,
	            		'db_field_changed' : data.db_field_changed,
	            		'db_old_field_content' : data.db_old_field_content,
	            		'db_new_field_content': data.db_new_field_content,
	            		'db_jira': data.db_jira,
	            		'db_inv_uuid' : data.db_inv_uuid,
	            		'db_comments': data.db_comments,
	            		'db_cost_effect_system': data.db_cost_effect_system,
	            		'db_cost_effect_manual': data.db_cost_effect_manual,
	            		'db_poc' : data.db_poc,
	            		'db_status' : 'needs_review',
	            		'db_activity_time': data.db_activity_time,
	            		'activity_id' : data.activity_id

	        		}
	        	)
		    })
		      const body = await response.json()
		      const result = body.message
		      
		      resolve()
		      props.handleLoadData()
		    }
	    	doFetch()
	        
	      }).catch(() => console.log('Oops errors!'));
		},
		onCancel() {
		  console.log('Cancel');
		},
		});
	};

	function handleMenu(item , data) {
		console.log("inside menu click")
		console.log(item)
		console.log(data)
		showConfirm(data)
	}

	function handleEdit(row) {
		setShowAddInfo(true)
	}

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	    	console.log(value)
	      	return (
				value.db_node_name.toLowerCase().includes(searchInput.toLowerCase()) 
	        	);
	    	});
	    	setData(filteredData);
		}
  	};

	useEffect(() => {
		if (props.loadData) {
			setShowLoading(true)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/activity/filter/status?status=approved", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		      props.handleLoadComplete()
		    }
	    	doFetch()
    	}
  	}, [props.loadData])

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleSuccess() {
  		console.log("inskde handel succedd")
  		setShowCreate(false)
  		setLoadData(false)
  		props.handleLoadData()
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

  	function handleAddInfo(event) {
	    setShowAddInfo(!showAddInfo)
	    event.stopPropagation()
  	} 

  	function handleAddInfoClose() {
    	setShowAddInfo(false)
  	}

	return(
		<div className="App-DA-AC-table-container">
    		<div className="App-DA-AC-table-container-search">
				<div className="App-DA-AC-table-container-search-box">
					{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    				{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    			</div>
    		</div>
	        <NewTable data={data} columns={columns} loading={showLoading} hiddenColumns={hiddenColumns} getRowProps={row => ({
	          style: {
	            cursor: "pointer",
	            height : "10px"
	          }
	        })} stickyTable={true} showMenu={true} menu={menu} handleMenu={handleMenu} />
	        {showCreate ? <div  handleClose={handleNewClose} handleSuccess={handleSuccess}> </div> : <div></div>}
    	</div>
	)
}

export default DActivities
