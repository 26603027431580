import React, { useState, useEffect, useMemo } from 'react';
import './assets/landingPage.css'
import { Tabs, Search, Input } from 'antd';
import InternalApp from './internalApp'
import Settings from './settings'


function LandingPage(props) {

	const [size, setSize] = useState('small');

  const [menuItem, setMenuItem] = useState([])

  const [developerRole, setDeveloperRole] = useState(false)

  const { Search } = Input;

  const onSearch = (input) => console.log("hello");

  const items = [ {label : <div>Apps</div>, key : '1', children : <InternalApp handleTabSelection={props.handleTabSelection}/>}, {label : 'Settings', key : '2', children : <Settings handleTabSelection={props.handleTabSelection}/>}]

  const devItems = [{label : <div>Apps</div>, key : '1', children : <InternalApp handleTabSelection={props.handleTabSelection}/>}]

  useEffect(() => {
    var db_role = localStorage.getItem('db_portal_user_role')
    if (db_role == 'developer') {
      setDeveloperRole(true)
      setMenuItem(devItems)
    } else {
      setDeveloperRole(false)
      setMenuItem(items)
    }
  }, [])

	return(
		<div className="App-DB-Landing-page-container">
      <div className="App-DB-Landing-page-body">
			<Tabs
        defaultActiveKey="3"
        type="card"
        size={size}
        tabBarGutter="30px"
        items={menuItem}
      />
      </div>
		</div>
	)

}

export default LandingPage
