import React, { useState, useEffect, useMemo } from 'react';
import './assets/createProduct.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateProduct(props) {

	const [form] = Form.useForm();

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const { TextArea } = Input;

	const [productName, setProductName] = useState('')

	const [moduleName, setModuleName] = useState('')

	const [disableSave, setDisableSave] = useState(true)


	function handleProductName(e) {
		setProductName(e.target.value)
	}

	function handleModuleName(e) {
		setModuleName(e.target.value)
	}

	function handleClose() {
		props.handleClose()
	}

	useEffect(() => {
  	if( moduleName != "" && productName != "") {
  		setDisableSave(false)
  	} else {
  		setDisableSave(true)
  	}

  }, [moduleName, productName])

	function handleCreateProduct() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/product' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "product" : productName,
	            "module" : moduleName
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-Product-create-node-container">
				<div className="App-Product-create-node-modal-content">
					<div className="App-Product-create-node-modal-header">
		 				<div className="App-Product-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-Product-create-node-modal-header-content"> Add Product / Module </div>
					</div>
					<div className="App-Product-create-node-modal-body">
						<div className="App-Product-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<Form.Item label="Product Name">
        						<Input placeholder="Type Here" value={productName} onChange={handleProductName}/>
      						</Form.Item>
      						<Form.Item label="Module Name">
        						<Input placeholder="Type Here" value={moduleName} onChange={handleModuleName} />
      						</Form.Item>
      					</Form>
      					</div>
					</div>
					<div className="App-Product-create-node-modal-footer">
						<div className="App-Product-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateProduct()}
	              				disabled = {disableSave}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateProduct
