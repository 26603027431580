import React, { useState, useEffect, useMemo } from 'react';
import './assets/atlasCosts.css';
import {DatePicker} from 'antd'
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import moment from 'moment';
import * as XLSX from 'xlsx';
import ExportIcon from './assets/export.svg'



function AtlasCosts(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	

	var currMonth = getCurrentMonth()
	var {start, end} = getMonthDates(currMonth)

	const [startDate, setStartDate] = useState(start)

	const [endDate, setEndDate] = useState(end)


	const [showLoader, setShowLoader] = useState(true)

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

	const [overallCost, setOverallCost] = useState([{
		'env' : 'production',
		'arc_us' : '$76639.51',
		'arc_eu' : 'NA',
		'aec_us' : '$11473',
		'aec_eu' : '$401',
		'tap_us' : '$5147',
		'tap_eu' : 'NA'
	}])

	const [arcusCost, setArcUsCost] = useState([{
		'project' : 'ARC US',
		'prod_common' : 'NA' ,
		'prod_pod0' : 'NA',
		'prod_pod1' : 'NA',
		'prod_pod2' : '$470',
		'prod_service' : '$3256',
		'prod_crm' : '$9777',
		'prod_internal_tool' : '$618'
	}])

	const [arceuCost, setArcEuCost] = useState([{
		'project' : 'ARC EU',
		'arc_eu_inchape' : 'NA',
		'arc_eu_rrg' : 'NA'
	}])

	const [aecusCost, setAecUsCost] = useState([{
		'project' : 'AEC US',
		'aec_gm_chev' : '$908',
		'aec_gm_sales' : '$2171',
		'aec_gm_cadillac' : '$781',
		'aec_aurora' : '$340'
	}])

	const [aeceuCost, setAecEuCost] = useState([{
		'project' : 'AEC EU',
		'aec_eu_ford_pro' : '$401'
	}])

	const overallCostColumns = useMemo(
		()  => [
			{
				Header:"",
				accessor : "env",
				width : 300
			},
			{
				Header:<div className="App-body-CSum-Right-Cell">ARC US</div>,
				accessor : "ARC_US",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.ARC_US ? formatter.format(Math.abs(row.row.original.ARC_US)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header:<div className="App-body-CSum-Right-Cell">ARC EU</div>,
				accessor : "ARC_EU",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.ARC_EU ? formatter.format(Math.abs(row.row.original.ARC_EU)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header:<div className="App-body-CSum-Right-Cell">AEC US</div>,
				accessor : "AEC_US",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.AEC_US ? formatter.format(Math.abs(row.row.original.AEC_US)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header:<div className="App-body-CSum-Right-Cell">AEC EU</div>,
				accessor : "AEC_EU",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.AEC_EU ? formatter.format(Math.abs(row.row.original.AEC_EU)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">TAP US</div>,
				accessor : "TAP_US",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.TAP_US ? formatter.format(Math.abs(row.row.original.TAP_US)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">TAP EU</div>,
				accessor : "tap_eu",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.TAP_EU ? formatter.format(Math.abs(row.row.original.TAP_EU)) : 'NA'}
	            	</div>
            	)
			}
		], [])

	const arcusCostColumns = useMemo(
		() => [
			{
				Header : "",
				accessor : "project",
				width : 300
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production Common</div>,
				accessor : "Prod_CMN",
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.Prod_CMN ? formatter.format(Math.abs(row.row.original.Prod_CMN)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production POD0</div>,
				accessor : "PROD_POD0",
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_POD0 ? formatter.format(Math.abs(row.row.original.PROD_POD0)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production POD1</div>,
				accessor : "PROD_POD1",
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_POD1 ? formatter.format(Math.abs(row.row.original.PROD_POD1)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production POD2</div>,
				accessor : "PROD_POD2",
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_POD2 ? formatter.format(Math.abs(row.row.original.PROD_POD2)) : 'NA'}
	            	</div>
            	)

			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production Service </div>,
				accessor : "PROD_SERV",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_SERV ? formatter.format(Math.abs(row.row.original.PROD_SERV)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production CRM </div>,
				accessor : "PROD_CRM",
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_CRM ? formatter.format(Math.abs(row.row.original.PROD_CRM)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Production Internal Tool</div>,
				accessor : "PROD_INT",
				width : 250,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.PROD_INT ? formatter.format(Math.abs(row.row.original.PROD_INT)) : 'NA'}
	            	</div>
            	)
			}
		], [])

	const arceuCostColumns = useMemo(
		() => [
			{
				Header : "",
				accessor : "project",
				width : 300
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Inchape</div>,
				accessor : "Inchcape",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.Inchcape ? formatter.format(Math.abs(row.row.original.Inchcape)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">RRG</div>,
				accessor : "RRG",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.RRG ? formatter.format(Math.abs(row.row.original.RRG)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : "",
				accessor : "a",
				width : 500
			},
			{
				Header : "",
				accessor : "b",
				width : 500
			}
		],[])

	const aecusCostColumns = useMemo(
		() => [
			{
				Header : "",
				accessor : "project",
				width : 300
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">GM_Chevy & GM_Bolt</div>,
				accessor : "GM_Chevy",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.GM_Chevy ? formatter.format(Math.abs(row.row.original.GM_Chevy)) : 'NA'}
	            	</div>
            	)

			},
			{
				Header : <div className="App-body-CSum-Right-Cell">GM_Salescloud</div>,
				accessor : "GM_Sales",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.GM_Sales ? formatter.format(Math.abs(row.row.original.GM_Sales)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">GM_Cadillac</div>,
				accessor : "GM_Cadillac",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.GM_Cadillac ? formatter.format(Math.abs(row.row.original.GM_Cadillac)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Aurora</div>,
				accessor : "Aurora",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.Aurora ? formatter.format(Math.abs(row.row.original.Aurora)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : "",
				accessor : "b",
				width : 500
			}
		],[])

	const aeceuCostColumns = useMemo(
		() => [
			{
				Header : "",
				accessor : "project",
				width : 300
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Ford_Pro</div>,
				accessor : "Ford_Pro",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.Ford_Pro ? formatter.format(Math.abs(row.row.original.Ford_Pro)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : "",
				accessor : "a",
				width : 500
			},
			{
				Header : "",
				accessor : "b",
				width : 400
			},
			{
				Header : "",
				accessor : "c",
				width : 200
			}
		],[])


	const [hiddenColumns, setHiddenColumns] = useState([])

	function handleMonthChange(date, dateString) {
		console.log("Month change")
		console.log(dateString)
		var month = dateString.split('-')[1]
		var year = dateString.split('-')[0]
		console.log(month)
		var {start, end} = getMonthDates(month, year)
		console.log("Start is ")
		console.log(start)
		console.log("End is")
		console.log(end)
		setStartDate(start)
		setEndDate(end)
	}

	function getMonthDates(month,  year) {
		if (month < 1 || month > 12) {
	    	throw new Error('Invalid month input. Month must be between 1 and 12.');
	  	}

	  	const currentDate = new Date();


	  	if ( !year ) {
	  		year = currentDate.getFullYear();
	  	}

	  	const startDate = new Date(year, month - 1, 1);
	  	const endDate = new Date(year, month, 0);

	 	const startDateFormatted = `${year}-${String(month).padStart(2, '0')}-01`;
	  	const endDateFormatted = `${year}-${String(month).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;

	  	return {
	    	start: startDateFormatted,
	    	end: endDateFormatted,
	  	};
	}

	function getCurrentMonth() {
  		const currentDate = new Date();
  		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  		return month;
	}


	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedCost = []
		combinedCost.push({'title' : 'Overall Cost', jsonData : overallCost})
		combinedCost.push({'title' : 'ARC US Cost', jsonData : arcusCost})
		combinedCost.push({'title' : 'ARC EU COst', jsonData : arceuCost})
		combinedCost.push({'title' : 'AEC US Cost', jsonData : aecusCost})
		combinedCost.push({'title' : 'AEC EU Cost', jsonData : aeceuCost})
		console.log(combinedCost)
		convertJSONsToXLSX(combinedCost)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "combined_data.xlsx");
    };

	function convertJSONsToCSV(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const csvRows = [];
        const headers = [];

        // Extract headers from all data sets
        dataSets.forEach(dataSet => {
            headers.push(...Object.keys(dataSet.jsonData[0]));
        });

        // Remove duplicate headers
        const uniqueHeaders = [...new Set(headers)];
        csvRows.push(uniqueHeaders.join(','));

        dataSets.forEach(dataSet => {
            dataSet.jsonData.forEach((dataObject) => {
                const values = uniqueHeaders.map(header => dataObject[header] || "");
                csvRows.push(values.join(','));
            });
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "combined_data.csv";
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    };


	function populateAtlasCost() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/atlas_cost_parallel?start_date=" + startDate + "&end_date=" + endDate
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    var overallCost = result['atlas_data']
		    overallCost['env'] = 'production'
		    setOverallCost([overallCost])
		    var arcUsCost = result['arc_us_data']
		    arcUsCost['project'] = 'ARC US'
		    setArcUsCost([arcUsCost])
		    var arcEuCost = result['arc_eu_data']
		    arcEuCost['project'] = 'ARC EU'
		    setArcEuCost([arcEuCost])
		    var aecUsCost = result['aec_us_data']
		    aecUsCost['project'] = 'AEC US'
		    setAecUsCost([aecUsCost])
		    var aecEuCost = result['aec_eu_data']
		    aecEuCost['project'] = 'AEC EU'
		    setAecEuCost([aecEuCost])
		    setShowLoader(false)
		}
		doFetch()
	}
	

	useEffect(() => {
		setShowLoader(true)
		populateAtlasCost()
	}, [startDate, endDate])

	return(
			<div className="App-AC-tab-container">
				<div className="App-body-AC-header-container">
					<div className="App-body-AC-header-title-container">
						<div className="App-body-AC-header-title">
							<div className="App-body-AC-header-title-name">
								Atlas Costs
							</div>
							<div className="App-body-AC-header-month-range">
								<DatePicker picker="month" value={moment(endDate, 'YYYY-MM-DD')} onChange={handleMonthChange}/>
							</div>
						</div>
					</div>
					<div className="App-body-AC-header-export">
						<img src={ExportIcon} onClick={handleExportClick} />
					</div>
				</div>
				<div className="App-body-AC-tab-body">
				{!showLoader ?
					<div className="App-body-AC-tab-body-container">
						<div className="App-body-AC-tab-body-overall-cost-container">
							<div className="App-body-AC-tab-body-overall-cost-title">
								Overall Cost
							</div>
							<div className="App-body-AC-tab-body-overall-cost-table">
								<NewTable data={overallCost} columns={overallCostColumns} hiddenColumns={hiddenColumns} />
							</div>
						</div>
						<div className="App-body-AC-tab-body-project-cost-container">
							<div className="App-body-AC-tab-body-project-cost-title">
								Cost by Track / Project
							</div>
							<div className="App-body-AC-tab-body-project-cost-table">
								<NewTable data={arcusCost} columns={arcusCostColumns} hiddenColumns={hiddenColumns} />
							</div>
							<div className="App-body-AC-tab-body-project-cost-table">
								<NewTable data={arceuCost} columns={arceuCostColumns} hiddenColumns={hiddenColumns} />
							</div>
							<div className="App-body-AC-tab-body-project-cost-table">
								<NewTable data={aecusCost} columns={aecusCostColumns} hiddenColumns={hiddenColumns} />
							</div>
							<div className="App-body-AC-tab-body-project-cost-table">
								<NewTable data={aeceuCost} columns={aeceuCostColumns} hiddenColumns={hiddenColumns} />
							</div>
						</div>
					</div>
					 : 
					 <div className="App-body-AC-tab-body-container">
					 	<Loader />
					 </div> 
					}
				</div>
			</div>
		)
}

export default AtlasCosts