import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Collapse } from 'antd';
import './assets/mongoAuditLogs.css'
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'
import * as XLSX from 'xlsx';


function MongoAuditLog() {

	const { Panel } = Collapse;

	const searchRef = useRef(null);

	const [active, isActive] = useState(true)

	const [form] = Form.useForm();

	const { Search } = Input;

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [mysqlFilters, setMysqlFilters] = useState({})

	const [loadData, setLoadData] = useState(false)

	const [selectedDBType, setSelectedDBType] = useState('Mongo')

	const [showForm, setShowForm] = useState(false)

	const [showTable, setShowTable] = useState(false)

	const [showLoading, setShowLoading] = useState(true)

	const [selectedCluster, setSelectedCluster] = useState('')

	const [tableColumns, setTableColumns] = useState([])

	const [productOptions, setProductOptions] = useState([])

	const [selectedProduct, setSelectedProduct] = useState('')

	const [disableCluster, setDisableCluster] = useState(true)

	const [moduleOptions, setModuleOptions] = useState([])

	const [disableModule, setDisableModule] = useState(true)

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [clusterOptions, setClusterOptions] = useState([])

	const [filters, setFilters] = useState({})

	const [selectedModule, setSelectedModule] = useState('')

	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [actionTypeOptions, setactionTypeOptions] = useState([])
	const [selectedActionType, setSelectedActionType] = useState('All')

	// Get current date and time
	const currentDate = new Date();
	const currentTime = currentDate.toLocaleTimeString();

	// Calculate time one hour ago
	const oneHourAgo = new Date(currentDate.getTime() - 60 * 60 * 1000);
	const oneHourAgoTime = oneHourAgo.toLocaleTimeString();
	const oneHourAgoDate = oneHourAgo.toLocaleDateString("en-GB");

	// Format today's date
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const todayDate = currentDate.toLocaleDateString("en-GB", options);

	const [startDate, setStartDate] = useState(convertDateString(oneHourAgoDate))

	const [startDateString, setStartDateString] = useState(convertDateString(oneHourAgoDate) + " " + oneHourAgoTime)

	const [endDate, setEndDate] = useState(convertDateString(todayDate))
	const [startTime, setStartTime] = useState(oneHourAgoTime)

	const [endDateString, setEndDateString] = useState(convertDateString(todayDate) + " " + currentTime)

	const [endTime, setEndTime] = useState(currentTime)

	const mongoColumns = useMemo(
		() => [
			{
				Header : "IP",
				accessor : "IP"
			},
			{
				Header : "User",
				accessor : "User"
			},
			{
				Header : "Action Type",
				accessor : "ActionType"
			},

			{
				Header : "Project Name",
				accessor : "ProjectName"
			},
			{
				Header : "Cluster Name",
				accessor : "ClusterName"
			},
			{
				Header : "DBName",
				accessor : "DBName"
			},
			{
				Header : "Collection",
				accessor : "Collection"
			},
			{
				Header : "New Collection",
				accessor : "NewCollection"
			},
			{
				Header : "Index Name",
				accessor : "indexName"
			},
			{
				Header : "Mechanism",
				accessor : "Mechanism"
			},
			{
				Header : "Date Time",
				accessor : "DateTime"
			}
		], [])

	useEffect(() => {
		populateMysqlFilter()
	}, [])

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var products = Object.keys(mysqlFilters)
				console.log(constructFilterArray(products))
				setProductOptions(constructFilterArray(products))
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	useEffect(() => {
		
		if (loadData) {
			setShowLoading(true)
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   		var dbStartTime = convertDBDateString(startDate) + " " + startTime
	   		var dbEndTime = convertDBDateString(endDate) + " " + endTime
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/auditlogs?db_type=" + selectedDBType +"&product=" + selectedProduct + "&cluster_name=" + selectedCluster +"&startTime="
		      	+ dbStartTime + "&endTime=" + dbEndTime + "&action_type=" + selectedActionType
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		   	setOriginalData(result)
		    setSlowQueryData(result)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	function convertDBDateString(dateString) {
		  var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}

	const onSearch = (input) => globalSearch(input);

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.DBName && value.DBName.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.ActionType && value.ActionType.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.ProjectName && value.ProjectName.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.Collection && value.Collection.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};


	function populateMysqlFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/auditlogs/getfilters?db_type=mongo"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message.content

		    console.log("Result is ")
		    console.log(result)
		    setMysqlFilters(result)

			var action_types = body.message.action_types
			action_types = ["All", ...action_types]
			setactionTypeOptions(constructFilterArray(action_types))

		    setShowForm(true)
		}
		doFetch()
	}

	function convertDateString(dateString) {
		  var dateParts = dateString.split('/');
		  var month = dateParts[0];
		  var day = dateParts[1];
		  var year = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + month + '-' + day;
	}

	function handleProductChange(fieldLabel, fieldValue) {
		setSelectedProduct(fieldLabel)
		var clusters = mysqlFilters[fieldLabel]
		setClusterOptions(constructFilterArray(clusters))
		setDisableCluster(false)
	}

	function handleActionTypeChange(fieldLabel, fieldValue) {
		setSelectedActionType(fieldLabel)
	}

	function handleClusterChange(fieldLabel, fieldValue) {
		setSelectedCluster(fieldLabel)
		setShowReportDisable(false)
	}



	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleStartDate(value, dateString) {
		console.log("Start Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		console.log("Converted date is ")
		console.log(convertRangeDateString(date))
		setStartDate(convertRangeDateString(date))
		setStartTime(time)
		setStartDateString(convertRangeDateString(date) + " " + time)
	}

	function handleStartTime(value, timeString) {
		console.log("Start Time Change")
		console.log(value, timeString)
		setStartTime(timeString)
	}

	function handleEndDate(value, dateString) {
		console.log("End Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		setEndDate(convertRangeDateString(date))
		setEndTime(time)
		setEndDateString(convertRangeDateString(date) + " " + time)
	}

	function handleEndTime(value, timeString) {
		console.log(" End Time Change ")
		console.log(value, timeString)
		setEndTime(timeString)
	}

	function convertRangeDateString(dateString) {
		console.log(" Date string is ")
		console.log(dateString)
		 var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}

	function handleButtonClick(){
		console.log("Button Click is")
		console.log("Loaad Data is")
		console.log(loadData)
		setTableColumns(mongoColumns)
		if (!loadData) {
			setSlowQueryData([])
			setShowTable(false)
			setLoadData(true)
		}
	}

	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedQuery = []
		combinedQuery.push({'title' : 'Mongo Audit Logs', jsonData : slowQueryData})
		convertJSONsToXLSX(combinedQuery)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "mongo_audit_log.xlsx");
    };

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
			if (selectedDBType == 'Mongo') {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var products = Object.keys(mysqlFilters)
				console.log(constructFilterArray(products))
				setProductOptions(constructFilterArray(products))
				setTableColumns(mongoColumns)
				setShowTable(true)
			}
		}

	}, [showForm])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleReset() {
		setSelectedDBType('Mongo')
		setSelectedProduct([])
		setSelectedModule([])
		setSelectedCluster([])
		setDisableModule(true)
		setDisableCluster(true)
		setSelectedProduct('')
		setFilters(mysqlFilters)
		setSelectedActionType('All')
		setShowReportDisable(true)
		var products = Object.keys(mysqlFilters)
		console.log(constructFilterArray(products))
		setProductOptions(constructFilterArray(products))
		setSlowQueryData([])
	}


	return(
			<div className="App-AL-tab-mongo-container">
			{ showForm && showTable ? 
				<div className="App-AL-tab-mongo-inner-container">
				<div className="App-AL-tab-mongo-filter">		
							<div className="App-AL-tab-mongo-form">
							{showForm ? 
								<div className="App-AL-tab-mongo-filter-form">
									<Form
		      							form={form}
		      							layout="vertical"
		      						>
		      							<div className="AL-inline">
		      								<Form.Item label="Product" style={{width : '20%', margin:'10px auto'}}>
			        							<Select options={productOptions} value={selectedProduct} onChange={handleProductChange} />
			      							</Form.Item>
			      							<Form.Item label="Cluster" style={{width : '20%', margin:'10px auto'}}>
			        							<Select disabled={disableCluster} options={clusterOptions} value={selectedCluster} onChange={handleClusterChange} />
			      							</Form.Item>

											<Form.Item label="ActionType" style={{width : '20%', margin:'10px auto'}}>
			        							<Select options={actionTypeOptions} value={selectedActionType} onChange={handleActionTypeChange} />
			      							</Form.Item>

			      							<Form.Item label="From Date" style={{width : '20%', margin:'10px auto'}}>
		        								<DatePicker showTime  style={{display:'flex'}} value={moment(startDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleStartDate} />
		      								</Form.Item>

		      								<Form.Item label="To Date" style={{width : '20%', margin:'10px auto'}}>
		        								<DatePicker  showTime style={{display:'flex'}} value={moment(endDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleEndDate} />
		      								</Form.Item>
			      							<Button
			      							 	disabled={showReportDisable}
					            				type='primary'
					            				onClick={handleButtonClick}
					            				style={{margin : 'auto', marginTop : '40px'}}
					              			>
		              							Show Report
		            						</Button>
		            						<Button
		            							onClick={handleReset}
		            							style={{ margin : 'auto', marginTop : '40px'}}
		            						>
		            							Reset
		            						</Button>	      							
		      							</div>
		      						</Form>
								</div>
							: 
								<div className="App-SQ-tab-form-loader">
	      							<Loader />
	      						</div>
							}
							</div>

				</div>
				<div className="App-AL-tab-mongo-data-container">
					<div className="App-AL-table-container-search-box">
						<div className="App-body-AL-header-export">
							<img src={ExportIcon} onClick={handleExportClick} />
						</div>
						{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
						{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    				</div>
					{showTable ?
						<div className="App-AL-tab-body-table-container">
							<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
						</div>
					: 
						<div className="App-AL-tab-body-table-loader">
							<Loader />
						</div> 
					}
				</div>
				</div> 
				:
					<div className="App-AL-tab-body-table-loader">
							<Loader />
						</div> 
				}
			</div>
		)
}

export default MongoAuditLog