import React, { useState, useEffect, useMemo } from 'react';
import './assets/createActivity.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';
import moment from 'moment'


function EditActivity(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { TextArea } = Input;

	const activityTypes = [
		{
			value: 'Upscale',
        	label: 'Upscale'
		}, 
		{
			value: 'Downscale',
          	label: 'Downscale'
		}, 
		{
			value: 'Removal',
          	label: 'Removal'
		}, 
		{
			value: 'Addition',
          	label: 'Addition'
		},
		{
			value: 'Cost saving',
			label: 'Cost saving'
		}]

	const [activityId, setActivityId] = useState(props.data.activity_id)

	const [nodeName, setNodeName] = useState(props.data.db_node_name)

	const [invUUid, setInvUUid] = useState(props.data.db_inv_uuid)
	
	const [endPoint, setEndPoint] = useState(props.data.db_endpoint)

	const [activityType, setActivityType] = useState(props.data.db_activity_type)

	const [buttonDisable, setButtonDisable] = useState(true)

	const [comments, setComments] = useState(props.data.db_comments)

	const [fieldChanged, setFieldChanged] = useState(props.data.db_field_changed)

	const [oldFieldContent, setOldFieldContent] = useState(props.data.db_old_field_content)

	const [newFieldContent, setNewFieldContent] = useState(props.data.db_new_field_content)

	const [jira, setJira] = useState(props.data.db_jira)

	const [costEffectSystem, setCostEffectSystem] = useState(props.data.db_cost_effect_system)

	const [costEffectManual, setCostEffectManual] = useState(props.data.db_cost_effect_manual)

	const [POC,setPOC] = useState(props.data.db_poc)

	const [dateChange, setDateChange] = useState(props.data.db_activity_time)

	const [momentDateChange, setMomentDateChange] = useState(moment(props.data.db_activity_time))


	function handleClose() {
		props.handleClose()
	}

	function handleEndPoint(e) {
		setEndPoint(e.target.value)
	}

	function handleOldFieldContent(e) {
		setOldFieldContent(e.target.value)
	}

	function handleNewFieldContent(e) {
		setNewFieldContent(e.target.value)
	}

	function handleActitvityType(e) {
		setActivityType(e)
	}

	function handleFieldChanged(e) {
		setFieldChanged(e.target.value)
	}

	function handleJira(e) {
		setJira(e.target.value)
	}

	function handleCostEffectSystem(e) {
		setCostEffectSystem(e.target.value)
	}

	function handleCostEffectManual(e) {
		setCostEffectManual(e.target.value)
	}

	function handlePOC(e) {
		setPOC(e.target.value)
	}

	function handleDateChange(value, dateString) {
		console.log("Date Changed")
		console.log(dateString)
		setDateChange(dateString)
		setMomentDateChange(moment(dateString))
	}

	function handleComments(e) {
		setComments(e.target.value)
	}


	useEffect(() => {
		var role = localStorage.getItem('db_portal_user_role')
		if ( activityType != ''  &&
				dateChange != '') {
			if( role == "dba_admin") {
				setButtonDisable(false)
			} else {
				setButtonDisable(true)
			}
		} else {
			setButtonDisable(true)
		}

	}, [endPoint, activityType, fieldChanged, oldFieldContent, newFieldContent, costEffectManual, dateChange])

	function handleCreateActivity() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		setButtonDisable(true)
   		console.log(props.data)
   		console.log(fieldChanged)
   		if (fieldChanged == undefined) {
   			setFieldChanged(' ')
   		}
   		if(endPoint == undefined) {
   			setEndPoint(' ')
   		}
   		if (activityType == undefined) {
   			setActivityType(' ')
   		}
   		if(oldFieldContent == undefined) {
   			setOldFieldContent(' ')
   		}
   		if(newFieldContent == undefined){
   			setNewFieldContent(' ')
   		}
   		if(jira == undefined) {
   			setJira(' ')
   		}
   		if(comments == undefined) {
   			setComments(' ')
   		}
   		if(costEffectSystem == undefined) {
   			setCostEffectSystem(' ')
   		}
   		if(costEffectManual == undefined) {
   			setCostEffectManual(' ')
   		}
   		if(POC == undefined) {
   			setPOC(' ')
   		}
   		if (nodeName == undefined) {
   			setNodeName(' ')   		
   		}
		fetch( BASE_URL + '/v1/activity' ,{
	    			method : 'PUT',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	    				'activity_id' : activityId,
	            		'db_endpoint' : endPoint,
	            		'db_activity_type' : activityType,
	            		'db_field_changed' : fieldChanged,
	            		'db_old_field_content' : oldFieldContent,
	            		'db_new_field_content': newFieldContent,
	            		'db_jira': jira,
	            		'db_comments': comments,
	            		'db_cost_effect_system': costEffectSystem,
	            		'db_cost_effect_manual': costEffectManual,
	            		'db_poc' : POC,
	            		'db_activity_time': dateChange,
	            		'db_node_name' : nodeName,
	            		'db_status' : 'approved',
	            		'db_inv_uuid' : invUUid

	        		}
	        	)
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setButtonDisable(false)
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	function handleReject() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		setButtonDisable(true)
		fetch( BASE_URL + '/v1/activity' ,{
	    			method : 'PUT',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	    				'activity_id' : activityId,
	            		'db_endpoint' : endPoint,
	            		'db_activity_type' : activityType,
	            		'db_field_changed' : fieldChanged,
	            		'db_old_field_content' : oldFieldContent,
	            		'db_new_field_content': newFieldContent,
	            		'db_jira': jira,
	            		'db_comments': comments,
	            		'db_cost_effect_system': costEffectSystem,
	            		'db_cost_effect_manual': costEffectManual,
	            		'db_poc' : POC,
	            		'db_activity_time': dateChange,
	            		'db_node_name' : nodeName,
	            		'db_inv_uuid' : invUUid,
	            		'db_status' : 'rejected'

	        		}
	        	)
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setButtonDisable(false)
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-DA-AC-create-node-container">
				<div className="App-DA-AC-create-node-modal-content">
					<div className="App-DA-AC-create-node-modal-header">
		 				<div className="App-DA-AC-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DA-AC-create-node-modal-header-content"> {nodeName} </div>
					</div>
					<div className="App-DA-AC-create-node-modal-body">
						<div className="App-DA-AC-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<div className="inline">
      							<Form.Item label="Inventory Unique Id *" style={{marginRight:'auto'}}>
        							<Input disabled value={invUUid} onChange={handleEndPoint} />
      							</Form.Item>
      							<Form.Item label="Activity Type *">
      								<Select onChange={handleActitvityType} value={activityType} options={activityTypes} />
      							</Form.Item>
      						</div>
      						<div className="inline-3">
      							<Form.Item label="Field Changed *" style={{marginRight:'auto'}}>
        							<Input disabled value={fieldChanged} onChange={handleFieldChanged} />
      							</Form.Item>
      							<Form.Item label="Old Field Content *" style={{marginRight:'auto'}}>
        							<Input disabled value={oldFieldContent} onChange={handleOldFieldContent} />
      							</Form.Item>
      							<Form.Item label="New Field Content *">
        							<Input disabled value={newFieldContent} onChange={handleNewFieldContent} />
      							</Form.Item>
      						</div>
      						<div className="fullWidth">
	      						<Form.Item label="Jira">
	        						<Input placeholder="Type Here" value={jira} onChange={handleJira} />
	      						</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Cost Effect - System Generated" style={{marginRight:'auto'}}>
        							<Input value={costEffectSystem} onChange={handleCostEffectSystem} />
      							</Form.Item>
      							<Form.Item label="Cost Effect - Manual Estimated *">
        							<Input placeholder="Type Here" value={costEffectManual} onChange={handleCostEffectManual} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="POC" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" value={POC} onChange={handlePOC} />
      							</Form.Item>
      							<Form.Item label="Activity Date-time *">
        							<DatePicker disabled showTime style={{display:'flex'}} value={momentDateChange} onChange={handleDateChange} />
      							</Form.Item>
      						</div>	
      						<div className="fullWidth">
						        <Form.Item label="Comments" style={{marginBottom : 'auto'}}>
						          <TextArea rows={4} value={comments} placeholder="Type Here" onChange={handleComments} />
						        </Form.Item>
					        </div>
      					</Form>
      					</div>
					</div>
					<div className="App-DA-AC-create-node-modal-footer">
						<div className="App-DA-AC-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleReject()}
	              				disabled={buttonDisable}
	              			>
	              				Reject
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateActivity()}
	              				disabled={buttonDisable}
	              			>
	              				Approve
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default EditActivity
