import './assets/login.css';
import loginImage from './assets/login.png'
import tekionLogo from './assets/tekion-logo.png'
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';



function Login(props) {

  	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [showLoader, setShowLoader] = useState(false)
	const [showDisabled, setShowDisabled] = useState(true)
	const [emailInputRef, setEmailInputRef] = useState('')
	const [loginEmail, setEmail] = useState(emailInputRef.value)
  	const [passwordInputRef, setPasswordInputRef] = useState('')
  	
  	const [userInfo, setUserInfo] = useState(null);

  	const validateEmail = (email) => {
	  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
	};

  	useEffect(() => {
  		if(loginEmail !== "" && validateEmail(loginEmail)) {
  			setShowDisabled(false)
  		} else {
  			setShowDisabled(true)
  		}
  	}, [loginEmail])

  	function setEmailInputReference(el) {
   		setEmailInputRef(el)
  	}

  	function handleEmailInput() {
  		setEmail(emailInputRef.value)
  	}

  	function handleOauthLogin() {
  		
  	}


	function handleLogin() {
		setShowLoader(true)
	    fetch(BASE_URL + 'v1/login', {
	      method : 'POST',
	      headers : {
	        'Accept':'application/json',
	        'Content-Type':'application/json'
	      },
	      body : JSON.stringify({
	            "password": passwordInputRef.value ,
	            "email": emailInputRef.value,
	        })
	    }).then(res => res.json())
	    .then((data) => {
	      	setShowLoader(false)
	      	if ( data['message']['status'] === "Success") {
	      		var apiKey = data['message']['api_key']
	      		var user_name = data['message']['user_name']
	      		var user_email = data['message']['email']
	      		props.handleLogin(apiKey, user_name, user_email)
	      	}
	    })
	    .catch(console.log)
	}

	function handleNewLogin() {
		var email = emailInputRef.value
		props.handleLogin(email )
	}

	return(
			<div className="App-login-container">
				<div className="App-login-logo-container">
					<div className="App-login-logo-div">
						<img alt='' src={loginImage} />
					</div>
				</div>
				<div className="App-login-form-container">
					<div className="App-login-form-header">
						<img alt='' src={tekionLogo} />
					</div>
					<div className="App-login-form-body">
						<div className="App-login-form-body-header">
							<div className="App-login-form-body-header-name">
							Login
							</div>
						</div>
						<div className="App-login-form-body-contents">
							<div className="App-login-form-body-email-container">
								<div className="App-login-form-body-email-label">
									Email
								</div>
								<input type="text" ref={el => setEmailInputReference(el)} onInput={handleEmailInput} className="App-login-form-body-email-text-area">
								</input>
							</div>
							
							<div className="App-login-form-body-login-button-container">
								<button className="App-login-form-body-login-button">
									{showLoader ? 
										<i class="fa fa-spinner fa-pulse"></i>
										:
										showDisabled ? 
											<div className="App-login-form-body-login-label-disabled">
												Login
											</div>
										:
											<div className="App-login-form-body-login-label" onClick={handleNewLogin}>
												Login
											</div>
									}
								</button>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		)
}

export default Login