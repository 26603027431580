import React, { useState, useEffect } from 'react';
import {Select} from 'antd'
import './assets/filterUnit.css'
import TrashIcon from './assets/trash.svg'

function NewFilterUnit(props) {

	const [availableFields, setAvailableFields] = useState([])

	const [comparators, setComparators] = useState(props.comparators)

	const [fieldValues, setFieldValues] = useState([])

	const [fields, setFields] = useState([])

	const [disableDelete, setDisableDelete] = useState(false)

	const [loadComplete, setLoadComplete] = useState(false)

	const [selectedFieldValue, setSelectedFieldValue] = useState('')

	const [selectedFilterValue, setSelectedFilterValue] = useState('')

	useEffect(() => {
		handleMandatory()	
	}, [])

	useEffect(() => {
		if (props.filterField) {
			setLoadComplete(false)
			var selectedField = props.availableOptions.filter(e => e.value == props.filterField.fieldValue)
			setSelectedFieldValue(selectedField[0].label)
			setSelectedFilterValue(props.filterField.selectedValue)
			setAvailableFields(props.availableOptions)
			setFieldValues(props.availableValues[props.filterField.fieldValue])
			handleMandatory()
			setLoadComplete(true)
		} else {
			setAvailableFields(handleAvailableOptions())
			setLoadComplete(true)
		}
	}, [props.filterField])


	useEffect(() => {
		setAvailableFields(handleAvailableOptions())
	}, [props.selectedFields])


	function handleMandatory() {
		if( props.index == 0 ) {
			setDisableDelete(true)
		}
	}

	function handleAvailableOptions() {
		var tempAvailOptions = []
		for ( var index in props.availableOptions) {
			var fieldOption = props.availableOptions[index]
			if ( !props.selectedFields.includes(fieldOption.value)) {
				tempAvailOptions = tempAvailOptions.concat(fieldOption)
			}
		}
		return tempAvailOptions
	}


	function handleFieldChange(fieldObj) {
		var selectedOldField = props.availableOptions.filter(e => e.label == selectedFieldValue)
		var oldField = selectedOldField[0].value
		setSelectedFieldValue(fieldObj)
		setSelectedFilterValue('')
		setFieldValues(props.availableValues[fieldObj])
		props.handleSelectedFieldChange(fieldObj, oldField)
	}

	function handleValueChange(filterValue) {
		setSelectedFilterValue(filterValue)
		var selectedField = props.availableOptions.filter(e => e.label == selectedFieldValue)
		props.handleSelectedFilterValue(selectedField[0].value, filterValue)
	}

	function handleDeleteFilter() {
		var selectedField = props.availableOptions.filter(e => e.label == selectedFieldValue)
		setLoadComplete(false)
		props.handleDeleteFilter(selectedField[0].value)
	}


	return(
			<div className="App-DB-FilterUnitCotainer">
				<div className="App-DB-FilterUnitField">
					{loadComplete && <Select disabled={disableDelete} className="App-DB-FilterUnitSelect" value={selectedFieldValue} options={availableFields} onChange={handleFieldChange}/> }
				</div>
				<div className="App-DB-FilterUnitComparator">
					<Select disabled className="App-DB-FilterUnitSelect" defaultValue={comparators[0]} options={comparators} onChange={props.handleChange} />
				</div>
				<div className="App-DB-FilterUnitValue">
					{loadComplete && <Select className="App-DB-FilterUnitSelect" value={selectedFilterValue} defaultValue={selectedFilterValue} options={fieldValues} onChange={handleValueChange}/> }
				</div>
				<div className="App-DB-FilterUnitDelete">
					{!disableDelete && <img src={TrashIcon} onClick={handleDeleteFilter} /> }
				</div>
			</div>
		)

}

export default NewFilterUnit