import React, { useState, useEffect, useMemo, useRef } from 'react';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import './assets/goLiveDataCopy.css';
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import PopupIcon from './assets/popup-icon-16.png'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'
import DataCopyDetail from './dataCopyDetail'


function GoLiveDataCopy(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [showTable, setShowTable] = useState(false)

	const [showForm, setShowForm] = useState(false)

	const [allowedDates, setAllowedDates] = useState([])

	const [disableRelease, setDisableRelease] = useState(true)

	const [tableColumns, setTableColumns] = useState([])

	const [goLiveFilters, setGoLiveFilters] = useState({})

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [showSearch, setShowSearch] = useState(false)

	const [loadData, setLoadData] = useState(false)

	const [goLiveData, setGoLiveData] = useState([])

	const [originalData, setOriginalData] = useState([])

	const [selectedOriginalMonth, setSelectedOriginalMonth] = useState()

	const [selectedDate, setSelectedDate] = useState()

	const [selectedData, setSelectedData] = useState({})

	const [searchValue, setSearchValue] = useState('')

	const [releaseOptions, setReleaseOptions] = useState([])

	const [selectedRelease, setSelectedRelease] = useState('')

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [disableJira, setDisableJira] = useState(true)

	const [jiraOptions, setJiraOptions] = useState([])

	const [selectedJira, setSelectedJira] = useState('')

	const [disableStatus, setDisableStatus] = useState(true)

	const [selectedDetails, setSelectedDetails] = useState({})

	const [showDetails, setShowDetails] = useState(false)

	const [statusOptions, setStatusOptions] = useState([])

	const [selectedStatus, setSelectedStatus] = useState('')

	const mongoColumns = useMemo(
		() => [
			{
				Header : "DB",
				accessor : "db",
				width : 300
			},
			{
				Header : "Status",
				accessor : "data_copy_status"
			},
			{
				Header : "Module",
				accessor : "module"
			},
			{
				Header : "Sub Module",
				accessor : "sub_module"
			},
			{
				Header : "Source Cluster",
				accessor : "source_cluster_fk",
				width : 300
			},
			{
				Header : "Destination Cluster",
				accessor : "destination_cluster_fk",
				width : 300
			},
			{
				Header : "Last Update Time",
				accessor : "last_update_time",
				width : 200
			},
			{
				Header : "Details",
				accessor : "query",
				Cell : row => (
						<div className="App-SQ-table-query" onClick={e=>handleDetailClick(row.row.original)}>
              				<img src={PopupIcon} />
           				 </div>
					)
			}

		])

	function handleClose() {
		setSelectedDetails({})
		setShowDetails(false)
	}

	function handleDetailClick(data_copy_detail) {
		setSelectedDetails(data_copy_detail)
		setShowDetails(true)
	}

	function handleReleaseChange(value) {
		setSelectedJira('')
		setSelectedStatus('')
		setDisableJira(true)
		setDisableStatus(true)
		setSelectedRelease(value)
		var currentSelectedDate = selectedDate.format('DD-MM-YYYY')
		setJiraOptions(constructFilterArray(Object.keys(goLiveFilters[currentSelectedDate][value])))
		setDisableJira(false)
		
	}

	function handleJiraChange(value) {
		setSelectedStatus('')
		setDisableStatus(true)
		setSelectedJira(value)
		var currentSelectedDate = selectedDate.format('DD-MM-YYYY')
		var currentSelectedRelease = selectedRelease
		setStatusOptions(constructFilterArray(goLiveFilters[currentSelectedDate][currentSelectedRelease][value]))
		setDisableStatus(false)
	}

	function handleStatusChange(value) {
		setSelectedStatus(value)
		setShowReportDisable(false)
	}

	function handleDateChange(date, dateString) {
		setSelectedRelease('')
		setSelectedJira('')
		setSelectedStatus('')
		setDisableRelease(false)
		setDisableStatus(true)
		setDisableJira(true)
		console.log("Date")
		console.log(date)
		console.log("Date String")
		console.log(dateString)
		setSelectedDate(date)
		console.log(date.format('DD-MM-YYYY'))
		console.log(goLiveFilters[date.format('DD-MM-YYYY')])
		setReleaseOptions(constructFilterArray(Object.keys(goLiveFilters[date.format('DD-MM-YYYY')])))
	}


	function handleButtonClick(){
		setTableColumns(mongoColumns)
		if (!loadData) {
			setGoLiveData([])
			setShowTable(false)
			setLoadData(true)
		}
	}

	useEffect(() => {
		if (loadData) {
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/golive_data_copy_status?jira=" + selectedJira + "&status=" + selectedStatus + "&task_id=" + selectedRelease,
	  		  	{
			      method : 'GET',	
			      headers : {
			        'x-api-key' : apikey,
			        'oauth-token' : oauth_token
			     }
		    })
		    const body = await response.json()
		    const result = body.message
		    setOriginalData(result['data_copy_status'])
		    setGoLiveData(result['data_copy_status'])
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])


	useEffect(() => {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   	const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/golive_data_copy_status/getfilters"
			      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    setGoLiveFilters(result)
		    setShowForm(true)
		}
		doFetch()
	}, [])

	useEffect(() => {
		if(showForm) {
				var available_dates = Object.keys(goLiveFilters)
				setAllowedDates(available_dates)
				setSelectedDate(moment(available_dates[0], 'DD-MM-YYYY'))
				setReleaseOptions(constructFilterArray(Object.keys(goLiveFilters[available_dates[0]])))
				setDisableRelease(false)
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

	const onSearch = (input) => globalSearch(input);

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setGoLiveData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.db &&  value.db.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.module &&  value.module.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.sub_module &&  value.sub_module.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.source_cluster_fk && value.source_cluster_fk.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.destination_cluster_fk &&  value.destination_cluster_fk.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setGoLiveData(filteredData);
			}
  	};

	const isDateAllowed = (date) => {
		return allowedDates.includes(date.format('DD-MM-YYYY'));
 	 };

	const disableDate = (current) => {
    	// Do not disable dates if current is not a moment date object
    	if (!current || !moment.isMoment(current)) {
     		 return false;
    	}
    	return !isDateAllowed(current);
  	};

  	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

  	function handleExportClick() {
	
	}

	function handleReset() {
		setSelectedDate('')
		setSelectedRelease('')
		setDisableRelease(true)
		setSelectedJira('')
		setDisableJira(true)
		setSelectedStatus('')
		setDisableStatus(true)
		setShowReportDisable(true)
		setGoLiveData([])
		setOriginalData([])
	}

	return (

			<div className="App-GLDC-tab-container">
				<div className="App-body-GLDC-Header-Container">
					<div className="App-body-GLDC-Header-Title-Container">
						<div className="App-body-GLDC-Header-Title">
							<div className="App-body-GLDC-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-GLDC-Header-Title-Name">
								GoLive Data Copy Status
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ?
				<div className="App-GLDC-tab-body">
					<div className="App-GLDC-tab-mongo-container">
						<div className="App-GLDC-tab-mongo-filter">		
									<div className="App-GLDC-tab-mongo-form">
									{showForm ? 
										<div className="App-GLDC-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="GLDC-inline">
				      								<Form.Item label="Data Copy Date" style={{width : '15%', margin:'1em 2em'}}>
				      									<DatePicker value={selectedDate} disabledDate={disableDate} onChange={handleDateChange}/>
					      							</Form.Item>
					      							<Form.Item label="Task Name" style={{width : '25%', margin:'1em 2em'}}>
					        							<Select disabled={disableRelease} options={releaseOptions} value={selectedRelease} onChange={handleReleaseChange} />
					      							</Form.Item>
					      							<Form.Item label="Jira" style={{width : '25%', margin : '1em 2em'}}>
					      								<Select disabled={disableJira} options={jiraOptions} value={selectedJira} onChange={handleJiraChange} />
					      							</Form.Item>
					      							<Form.Item label="Status" style={{width : '15%', margin : '1em 2em'}}>
					      								<Select disabled={disableStatus} options={statusOptions} value={selectedStatus} onChange={handleStatusChange} />
					      							</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : '3em 2em'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : '3em 1em'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-SQ-tab-form-loader">
			      							<Loader />
			      						</div>
									}
									</div>

						</div>
						<div className="App-GLDC-tab-mongo-data-container">
							<div className="App-GLDC-tab-body-table-header-container">
								<div className="App-GLDC-table-container-search-box">
									<div className="App-body-GLDC-header-export">
										<img src={ExportIcon} onClick={handleExportClick} />
									</div>
									{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    							</div>
    						</div>
							{showTable ?
								<div className="App-GLDC-tab-body-table-container">
									<NewTable data={goLiveData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-GLDC-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
					</div>	
					{showDetails && <DataCopyDetail data_copy_details={selectedDetails} handleClose={handleClose} /> }			
				</div>
				: 
				 <div className="App-SQ-tab-form-loader">
				 	<Loader />
				 </div>
				}
			</div>
		)

}

export default GoLiveDataCopy