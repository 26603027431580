import React, { useState, useEffect, useMemo, useRef } from 'react';
import LeftChevron from './assets/chevron-left.svg'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import { Input } from 'antd';


function DealerLevelCost(props) {

	function handleClose() {
		props.handleClose()
	}

	const searchRef = useRef(null);

	const { Search } = Input;

	const [searchValue, setSearchValue] = useState('')

	const onSearch = (input) => globalSearch(input);

	const [originalData, setOriginalData] = useState([])

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [showSearch, setShowSearch] = useState(false)

	const [dealerData, setDealerData] = useState([])

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

	useEffect(() => {
		setOriginalData(props.dealers)
		setDealerData(props.dealers)
	}, [])

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setDealerData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.dealer_name &&  value.dealer_name.toLowerCase().includes(searchInput.toLowerCase()) 
	      		);
	    	});
	    	setDealerData(filteredData);
			}
  	};


	const tableColumns = useMemo(
		() => [
			{
				Header : "Dealer DB Name",
				accessor : "dealer_name",
				width : 1000
			},
			{
				Header : <div className="App-body-CSum-Right-Cell">Dealer Cost</div>,
				accessor : "cost",
				Cell : row => (
					<div className="App-body-CSum-Right-Cell">
						{row.row.original.cost ? formatter.format(Math.abs(row.row.original.cost)) : 'NA'}
					</div>
					),
				width : 1000
			},
		])

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

	return(
			<div className="App-RJ-create-node-container">
				<div className="App-RJ-create-node-modal-content">
					<div className="App-RJ-create-node-modal-header">
		 				<div className="App-RJ-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-RJ-create-node-modal-header-content"> 
 							{props.tenant_name} - Dealer Cost Details
 						</div>
					</div>
					<div className="App-RJ-create-node-modal-body">
						<div className="App-RJ-create-node-modal-body">
							<div className="App-RJ-table-container-search-box">
								{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
	    						{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    						</div>
							<div className="App-RS-tab-body-table-container">
								<NewTable data={dealerData} columns={tableColumns} hiddenColumns={hiddenColumns} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)

}

export default DealerLevelCost