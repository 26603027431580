import React, { Fragment } from 'react';
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useFlexLayout,
} from 'react-table';
import { Button } from 'antd';
import { Table, Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import './assets/tableContainer.css'
import Loader from './Loader'



const TableContainer = ({ columns, data, loading, hiddenColumns, getRowProps }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15, hiddenColumns : hiddenColumns },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <div className="App-table-container-body">
    {loading ? <Loader /> :
      <div className="App-table-container-body-table">
        <Fragment>
          <Table bordered hover {...getTableProps()}>
            <thead className="App-table-header">
              {headerGroups.map((headerGroup) => (
                <tr key="" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key="" {...column.getHeaderProps()}>
                      <div {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {generateSortingIndicator(column)}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr className="App-table-row" {...row.getRowProps(getRowProps(row))}>
                      {row.cells.map((cell) => {
                        return (
                          <td className="App-table-row-cell" key="" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </Fragment>
      </div>
    }
      <div>
        <Fragment>
          <div className="App-table-container-footer">
            <div className="App-table-container-footer-page-size">
              <div className="App-table-container-footer-page-size-dropdown">
                <Input
                  type='select'
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  &gt;
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Input>          
              </div>
              <div className="App-table-container-footer-page-size-label">
                <div>
                Results Per Page
                </div>
              </div>
            </div>
            <div className="App-table-container-footer-page-link">
              <div className="App-table-container-footer-page-link-container">
                <div className="App-table-container-footer-page-link-button-fprev">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {'<<'}
                  </Button>
                </div>
                <div className="App-table-container-footer-page-link-button-prev">
                  <Button
                    color='primary'
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    {'<'}
                  </Button>
                </div>
            
                <div className="App-table-container-footer-page-link-detail" style={{ marginTop: 7 }}>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </div>

                <div className="App-table-container-footer-page-link-button-next">
                  <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                    {'>'}
                  </Button>
                </div>

                <div className="App-table-container-footer-page-link-button-fnext">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {'>>'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

TableContainer.propTypes = {
  columns : PropTypes.any,
  data : PropTypes.any,
  getRowProps : PropTypes.any,
  loading : PropTypes.any,
  hiddenColumns : PropTypes.array
}
export default TableContainer;