import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../common/TableContainer'
import CreateNodeClass from './createNodeClass'
import './assets/nodeClass.css'
import 'antd/dist/antd.css';
import SearchIcon from './assets/search.svg'
import { Button, Radio, Input } from 'antd';
import update from 'immutability-helper'
import Loader from '../common/Loader'
import { Collapse } from 'antd';
import DraggableTable from '../common/DraggableTable'

function NodeClass(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

	const { Panel } = Collapse;

  	const [showLoading, setShowLoading] = useState(true)

  	const [showCreate, setShowCreate] = useState(false)

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const { Search } = Input;

  	const columns = useMemo(
	  () => [
	    {
	      Header: "Cloud Provider",
	      accessor: "cloud_provider"
	    }
	  ],
	  []
	)

	const versionValues = useMemo(
		() => [
		    {
		    	Header : "",
		    	accessor : "action",
		    	width :'10px'
		    },
			{
				Header: "Class",
	      		accessor: "node_class"
			},
		]
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	    	console.log(value)
	      	return (
	        	value.name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
		}
  	};

	useEffect(() => {
		if (loadData) {
			setShowLoading(true)
		    console.log(BASE_URL)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/node", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      console.log(nodes)
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
    	}
  	}, [loadData])


  	function handleNodeUpdate(updatedData) {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/node' ,{
	    			method : 'PUT',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify(updatedData)
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setLoadData(true)
	    	})
	    	.catch(console.log)
	}

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function moveDataRow(dragIndex, hoverIndex) {
  		const dragRecord = data[dragIndex]
		setData(
		  update(data, {
		    $splice: [
		      [dragIndex, 1],
		      [hoverIndex, 0, dragRecord],
		    ],
		  })
		)
  	}

  	function handleDrag(parentId, value) {
  		console.log("Drag Value")
  		console.log(value)
  		var newData = []
  		var updatedData = {}
  		for ( var i in data) {
  			var currData = data[i]
  			console.log(currData)
  			if ( currData['node_class_id'] == parentId ) {
  				currData['value'] = value
  				updatedData = currData
  				newData.push(currData)
  			} else {
  				newData.push(currData)
  			}
  		}
  		console.log("Updated Data")
  		console.log(newData)
  		setData(newData)
  		handleNodeUpdate(updatedData)
  	}

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

	return(
		<div className="App-NodeClass-table-container">
        	<div className="App-NodeClass-table-container-header">
          		<div className="App-NodeClass-table-container-header-title">
           			Node Class
           		</div>
           		{showAdd ? <div className="App-NodeClass-table-container-header-add-new">
           			<Button 
              			type='primary'
              			onClick = {() => handleNewClick()}
              		>
              			Add Node Class
            		</Button>
           		</div> : <div></div> }
    		</div>
    		<div className="App-NodeClass-table-container-search">
				<div className="App-NodeClass-table-container-search-box">
					{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    				{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    			</div>
    		</div>
    		<div className="App-NodeClass-table-box-container">
		        { !showLoading ?
				    		<Collapse className="App-NodeClass-table-box-collapse">
				    			{data.map((node, index) => (
				    					<Panel header=<div> {node.cloud_provider}</div> key={index} >
				    						<DraggableTable data={node.value} columns={versionValues} loading={showLoading} hiddenColumns={hiddenColumns} getRowProps={row => ({
								          style: {
								            cursor: "pointer",
								            height : "10px",
								            borderLeft : "1px solid #4285F4"
								          }
								        })} stickyTable={false} parentId={node.node_class_id} handleDrag={handleDrag}/>
				    					</Panel>
				    				))}
				    		</Collapse>
		    		 	: 
		    		 		<Loader />
		    		}
	        </div>
	        {showCreate ? <CreateNodeClass  handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}

    	</div>
	)

}

export default NodeClass