import React, { useState, useEffect, useMemo } from 'react';
import './assets/createCluster.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateCluster(props) {

	const [form] = Form.useForm();

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const { TextArea } = Input;

	const [category, setCategory] = useState('')

	const [name, setName] = useState('')

	const [cloudProvider, setCloudProvider] = useState('')

	const [priority, setPriority] = useState('')

	const [disableSave, setDisableSave] = useState(true)

	const options=[
        {
          value: 'tk-prod',
          label: 'tk-prod'
        }]

  useEffect(() => {
  	if( name != "") {
  		setDisableSave(false)
  	} else {
  		setDisableSave(true)
  	}

  }, [name])

	const handleChange = (value: string | string[]) => {
  		console.log(`Selected: ${value}`);
  		setCategory(value)
	};

	function handleName(e) {
		console.log(e.target.value)
		setName(e.target.value)
	}

	function handleCloudProvider(e) {
		setCloudProvider(e.target.value)
	}

	function handlePriority(e) {
		setPriority(e.target.value)
	}

	function handleClose() {
		props.handleClose()
	}

	function handleCreateNode() {
		console.log("Hello")
		var apikey = localStorage.getItem('db_portal_app_apikey')
   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/cluster' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({ 
	            "name" : name,
	            "cloud_provider" : cloudProvider,
	            "priority" : priority
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-CP-create-node-container">
				<div className="App-CP-create-node-modal-content">
					<div className="App-CP-create-node-modal-header">
		 				<div className="App-CP-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-CP-create-node-modal-header-content"> Add Cluster </div>
					</div>
					<div className="App-CP-create-node-modal-body">
						<div className="App-CP-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<div className="inline">
      							<Form.Item label="Cluster Name">
        							<Input placeholder="Type Here" value={name} onChange={handleName}/>
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Cloud Provider">
        							<Input placeholder="Type Here" value={cloudProvider} onChange={handleCloudProvider}/>
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Priority">
        							<Input placeholder="Type Here" value={priority} onChange={handlePriority}/>
      							</Form.Item>
      						</div>
      					</Form>
      					</div>
					</div>
					<div className="App-CP-create-node-modal-footer">
						<div className="App-CP-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateNode()}
	              				disabled = {disableSave}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateCluster
