import React, { useState, useEffect, useMemo } from 'react';
import './assets/createNode.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select, TextArea } from 'antd';


function EditCluster(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { TextArea } = Input;

	const [editDisabled, setEditDisabled] = useState(false)

	const [clusterName, setClusterName] = useState(props.selectedCluster.cluster_display_name)

	const [nodeNames, setNodeNames] = useState('')

	const [invIds, setInvIds] = useState('')
 
	const [POC, setPOC] = useState(props.selectedCluster.poc)

	const [tags, setTags] = useState();


	useEffect(() => {
		var nodeNames = props.selectedCluster.inventory_list.map((inventory) => 
			{	return inventory.db_node_name})
		var inv_ids = props.selectedCluster.inventory_list.map((inventory) => 
			{	return inventory.db_inv_uuid })
		setNodeNames(nodeNames)
		setInvIds(inv_ids)
		if(props.selectedCluster.tags) {
			setTags(props.selectedCluster.tags)
		} else {
			setTags('{}')
		}
	}, [])


	function handlePOCChange(event)  {
		setPOC(event)
	}

	function handleTagChange(event) {
		if (event.target.value != '') {
			setTags(event.target.value)
		} else {
			setTags('{}')
		}
	}

	function handleClose() {
		props.handleClose()
	}

	function handleEditNode() {
		setEditDisabled(true)
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		var inv_ids = []
		fetch( BASE_URL + '/v1/inventory/cluster' ,{
	    			method : 'PUT',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            		"inv_ids" : invIds,
	            		"poc" : POC,
	            		"tags" : tags
	        })
	    })
	    .then(res =>
	    	res.json())
	    .then((data) => {
	    	setEditDisabled(false)
	    	props.handleSuccess()
	    })
	    .catch(console.log)
	}

	function handleClusterName() {

	}

	return(
			<div className="App-DI-create-node-container">
				<div className="App-DI-create-node-modal-content">
					<div className="App-DI-create-node-modal-header">
		 				<div className="App-DI-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DI-create-node-modal-header-content"> Edit Cluster  </div>
					</div>
					<div className="App-DI-create-node-modal-body">
						<div className="App-EC-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<Form.Item label="Cluster Name">
        						<Input placeholder={clusterName} onChange={handleClusterName} disabled />
      						</Form.Item>
      						<Form.Item label="Node Names">
        						<Input placeholder={nodeNames} onChange={handleClusterName} disabled />
      						</Form.Item>
      						<div className="App-DI-edit-cluster-modal-body" style={{'height' : 'auto'}}>
      							<Form.Item label="POC" >
      								<Select
      									value={POC}
      									mode="tags"
      									tokenSeparators={[',']}
      									dropdownStyle={{ display: 'none' }} 
      									style={{ width: '100%', height : '100%' }}
      									onChange={handlePOCChange} > 
      								</Select>
      							</Form.Item>
      						</div>
      						<div className="">
      						<Form.Item label="Tags" >
      							<TextArea rows={4} value={tags} onChange={handleTagChange} />
      						</Form.Item>
      						</div>
      					</Form>
      					</div>
					</div>
					<div className="App-DI-create-node-modal-footer">
						<div className="App-DI-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	            				disabled = {editDisabled}
	              				onClick = {() => handleEditNode()}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)
}

export default EditCluster