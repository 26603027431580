import React, { useState, useEffect, useMemo } from 'react';
import './assets/users.css'
import 'antd/dist/antd.css';
import SearchIcon from './assets/search.svg'
import NewTable from '../common/NewTable'
import { Button, Radio, Input } from 'antd';
import CreateUser from './createUser'
import EditUser from './editUser'
import SettingsIcon from './assets/settings.svg'
import OverFlowIcon from './assets/overflow-icon.svg'
import PopupIcon from './assets/popup-icon-16.png'
import UserAccessDetails from './userAccessDetails' 


function Users(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

	const [currUser, setCurrUser] = useState({})

  	const [showLoading, setShowLoading] = useState(true)

  	const [showCreate, setShowCreate] = useState(false)

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [selectedDetails, setSelectedDetails] = useState({})

	const [showDetails, setShowDetails] = useState(false)

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)

	const [showEdit, setShowEdit] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const { Search } = Input;

  	const columns = useMemo(
	  () => [
	    {
	      Header: "Name",
	      accessor: "user_name",
	      width : 400
	    },
	    {
	    	Header: "Email",
	    	accessor : "email",
	     	width : 400
	    },
	    {
	    	Header : "Role",
	    	accessor : "role",
	      	width : 300
	    },
	    {
	    	Header : "Active/Inactive",
	    	accessor : "",
	      	width : 300,
	      	Cell : row =>(
	      		<div className="App-UR-user-status">
	      			{row.row.original.active === true ? <span class="active-dot"></span> : <span class="inactive-dot"></span> }
	      			<div className="App-UR-user-status-word">
	      				{row.row.original.active ? "Active" : "Inactive"}
	      			</div>
	      		</div>
	      	)
	    },
	    {
	    	Header : "Last Access Details",
	    	accessor : "a",
	    	width : 100,
	    	Cell : row => (
	    			<div className="App-SQ-table-query" onClick={e=>handleDetailClick(row.row.original)}>
              			<img src={PopupIcon} />
           			</div>
	    		)
	    },
     	{
	    	Header: <div><img src={SettingsIcon} /></div>,
	        accessor: 'action',
	        width :  50,
	        Cell: row => (
	        <div onClick={e=> handleEdit(row.row.original)}>
	          	<img src={OverFlowIcon} />
	        </div>
	        ),
    	}
	  ],
	  []
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	    	console.log(value)
	      	return (
	        	value.user_name.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.email.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
		}
  	};

  	function handleDetailClick(user_detail) {
		setSelectedDetails(user_detail)
		setShowDetails(true)
	}

	useEffect(() => {
		if (loadData) {
			setShowLoading(true)
		    console.log(BASE_URL)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/users", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      console.log(nodes)
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
    	}
  	}, [loadData])

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleEdit(row) {
  		setCurrUser(row)
  		setShowEdit(true)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

  	function handleEditClose() {
  		setShowEdit(false)
  	}

  	function handleEditSuccess() {
  		setShowEdit(false)
  		setLoadData(true)
  	}

  	function handleAccessClose() {
  		setShowDetails(false)
  		setSelectedDetails({})
  	}


	return(
			<div className="App-UR-table-container">
				<div className="App-UR-table-container-header">
					<div className="App-UR-table-container-header-title">
           				Users
           			</div>
           			{showAdd ? <div className="App-UR-table-container-header-add-new">
           		</div> : <div></div> }
				</div>
				<div className="App-UR-table-container-search">
				<div className="App-UR-table-container-search-box">
					{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    				{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    			</div>
    		</div>
	        	<NewTable data={data} columns={columns} loading={showLoading} hiddenColumns={hiddenColumns} getRowProps={row => ({
								          style: {
								            cursor: "pointer",
								            height : "10px",
								            borderLeft : "1px solid #4285F4"
								          }
								        })} stickyTable={true} />
	        {showCreate ? <CreateUser  handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}
	        {showEdit ? <EditUser  currUser={currUser} handleClose={handleEditClose} handleSuccess={handleEditSuccess} /> : <div></div>}
	        {showDetails ? <UserAccessDetails user={selectedDetails} handleClose={handleAccessClose} /> : <div></div> }
			</div>
		)
}

export default Users