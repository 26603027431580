import React, {useState, useEffect, Fragment} from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import BurgerMenu from '../activities/BurgerMenu'
import draghandle from './assets/draghandle.svg'


function DragRow({tableRowClassName, row, index, moveRow, showMenu, menu, handleMenu}) {

	const moveDragRow = (dragIndex, hoverIndex) => {
    	moveRow(dragIndex, hoverIndex)
  	}

  	useEffect(() => {
  		console.log("inside Drag Row")
  		console.log(row)
  	}, [])

  	const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

	const DND_ITEM_TYPE = 'ROW'

  	const [, drop] = useDrop({
    
    	accept: 'ROW',
    
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveDragRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'ROW',
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

	return (
          <div ref={dropRef} className={tableRowClassName}>
              <div {...row.getRowProps()} className="tr">
            	<div ref={dragRef}>
              		 <img src={draghandle} />
            	</div>
              	{row.cells.map(cell => {
                	return (
                      	<div {...cell.getCellProps()} className="App-drag-table-row-cell td">
	                  		<div className="th-content">
	                         	{cell.render('Cell')}
	                        </div>
	                  		<div className='resizer'>
	                  		</div>
                	  	</div>
                    )
                   }
                  )
              	}
            {showMenu && <div className="App-drag-table-col-sticky"><BurgerMenu data={row.original} menu={menu} handleMenu={handleMenu} /></div> }
                </div>
          </div>
	)
}

export default DragRow