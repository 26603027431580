import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './assets/icon.css';

function Icon(props) {

  function handleClick() {
    props.handleClick(props.id);
  }

  return (
    <div className="App-body-block-left-tab-icon" onClick={handleClick}>
      <div className="App-body-block-left-tab-icon-title">
        {props.id}
      </div>
    </div>
  );
}

Icon.propTypes = {
    id: PropTypes.any,
    handleClick: PropTypes.func,
};

export default Icon;
