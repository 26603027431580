function TenantDealerCost (props) {

	function handleClick() {
		props.handleClick(props.tenant_name, props.dealers)
	}
	
	return (
			<div onClick={handleClick}>
				{props.tenant_name}
			</div>
	)
}

export default TenantDealerCost