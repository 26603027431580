import React, { useState, useEffect, useMemo } from 'react';
import './assets/settings.css'
import PageTile from './pageTile'

function Settings(props) {

	const [developerRole, setDeveloperRole] = useState(false)

	useEffect(() => {
		var db_role = localStorage.getItem('db_portal_user_role')
		if (db_role == 'developer') {
			setDeveloperRole(true)
		}
	}, [])

	return (
			<div className="App-DB-settings-app-container">
				{!developerRole && <PageTile title="PM" name="Products and Modules" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && <PageTile title='EV' name="Database Engine Versions" handleTabSelection={props.handleTabSelection} /> }			
				{!developerRole && <PageTile title="AZ" name="Availability Zones" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && <PageTile title="RS" name="Regions" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && <PageTile title="CP" name="Cloud Providers" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && <PageTile title="NC" name="Node Class" handleTabSelection={props.handleTabSelection}/>}
				{!developerRole && <PageTile title="U" name="Users" handleTabSelection={props.handleTabSelection} />}
			</div>
		)
}

export default Settings