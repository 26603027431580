import React, { useState, useEffect, useMemo } from 'react';
import './assets/createNode.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateNode(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { TextArea } = Input;

	const [nodeName, setNodeName] = useState('')

	const [nodeClass, setNodeClass] = useState('')

	const [engine, setEngine] = useState('')

	const [endPoint, setEndPoint] = useState('')

	const [storage, setStorage] = useState('')

	const [cloudProvider, setCloudProvider] = useState('')

	const [backupRetention, setBackupRetention] = useState('')

	const [availability, setAvailability] = useState('')

	const [engineVersion, setEngineVersion] = useState('')

	const [environment, setEnvironment] = useState('')

	const [clusterName, setClusterName] = useState('')

	const [inventoryUniqId, setInventoryUniqId] = useState('')

	const [nodeRegion, setNodeRegion] = useState('')

	const [subscriptionAccount, setSubscriptionAccount] = useState('')

	const [nodeDeleteProtection, setNodeDeleteProtection] = useState('')

	const [product, setProduct] = useState('')

	const [module, setModule] = useState('')

	const [status, setStatus] = useState('')

	const [buttonDisable, setButtonDisable] = useState(true)

	const [miscellaneous, setMiscellaneous] = useState('')

	const [comments, setComments] = useState('')

	useEffect(() => {

		if ( nodeName != '' && engine != '' && endPoint != '' && cloudProvider != '' && inventoryUniqId != '') {
			setButtonDisable(false)
		} else {
			setButtonDisable(true)
		}

	}, [nodeName, engine, endPoint, cloudProvider])


	function handleClose() {
		props.handleClose()
	}

	function handleNodeName(e) {
		setNodeName(e.target.value)
	}

	function handleNodeClass(e) {
		setNodeClass(e.target.value)
	}

	function handleEngine(e) {
		setEngine(e)
	}

	function handleEndPoint(e) {
		setEndPoint(e.target.value)
	}

	function handleStorage(e) {
		setStorage(e.target.value)
	}

	function handleBackupRetention(e) {
		setBackupRetention(e.target.value)
	}

	function handleAvailability(e) {
		setAvailability(e.target.value)
	}

	function handleEngineVersion(e) {
		setEngineVersion(e.target.value)
	}

	function handleCloudProvider(e) {
		setCloudProvider(e.target.value)
	}

	function handleEnvironment(e) {
		setEnvironment(e)
	}

	function handleClusterName(e) {
		setClusterName(e.target.value)
	}

	function handleInventoryUniqId(e) {
		setInventoryUniqId(e.target.value)
	}

	function handleNodeRegion(e) {
		setNodeRegion(e.target.value)
	}

	function handleSubscriptionAccount(e) {
		setSubscriptionAccount(e.target.value)
	}

	function handleProduct(e) {
		setProduct(e)
	}

	function handleNodeDeleteProtection(e) {
		setNodeDeleteProtection(e.target.value)
	}

	function handleModule(e) {
		setModule(e)
	}

	function handleStatus(e) {
		setStatus(e.target.value)
	}

	function handleMiscellaneous(e) {
		setMiscellaneous(e.target.value)
	}

	function handleComments(e) {
		setComments(e.target.value)
	}

	function handleCreateNode() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		setButtonDisable(true)
		fetch( BASE_URL + '/v1/inventory' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "db_node_name": nodeName,
	            "db_node_class" : nodeClass,
	            "db_engine" : engine,
	            "db_endpoint" : endPoint,
	            "db_storage" : storage,
	            "db_node_created_time" : "",
	            "db_backup_retention_period" : backupRetention,
	            "db_availability_zone" : availability,
	            "db_engine_version" : engineVersion,
	            "db_host_cloud_provider": cloudProvider,
	            "db_node_region" : nodeRegion,
	            "db_status" : status,
	            "db_subscription_account_name" : subscriptionAccount,
	            "db_product" : product,
	            "db_module" : module,
	            "db_misc" : miscellaneous,
	            "db_comments" : comments,
	            "db_cluster_name" : clusterName,
	            "db_node_delete_protection_flag" : nodeDeleteProtection,
	            "db_env" : environment,
	            "db_inv_uuid": inventoryUniqId
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setButtonDisable(false)
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-DI-create-node-container">
				<div className="App-DI-create-node-modal-content">
					<div className="App-DI-create-node-modal-header">
		 				<div className="App-DI-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DI-create-node-modal-header-content"> Create Node </div>
					</div>
					<div className="App-DI-create-node-modal-body">
						<div className="App-DI-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<Form.Item label="Node Name *">
        						<Input placeholder="Type Here" onChange={handleNodeName} />
      						</Form.Item>
      						<div className="inline">
      							<Form.Item label="Node Class" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleNodeClass} />
      							</Form.Item>
      							<Form.Item label="Engine *">
      								<Select options={props.defaultFilterValues.engine} onChange={handleEngine} />
      							</Form.Item>
      						</div>
      						<Form.Item label="End Point *">
        						<Input placeholder="Type Here" onChange={handleEndPoint} />
      						</Form.Item>
      						<div className="inline">
      							<Form.Item label="Storage" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleStorage} />
      							</Form.Item>
      							<Form.Item label="Node Created Time">
        							<DatePicker style={{display:'flex'}} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Backup Retention Period" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleBackupRetention} />
      							</Form.Item>
      							<Form.Item label="Availability Zone">
        							<Input placeholder="Type Here" onChange={handleAvailability} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Engine Version" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleEngineVersion} />
      							</Form.Item>
      							<Form.Item label="Environment">
      								<Select options={props.defaultFilterValues.env} onChange={handleEnvironment} />
      							</Form.Item>
      						</div>
      						<Form.Item label="Cluster Name">
        						<Input placeholder="Type Here" onChange={handleClusterName} />
      						</Form.Item>
      						<div className="inline">
      							<Form.Item label="Inventory Unique Id *" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleInventoryUniqId} />
      							</Form.Item>
      							<Form.Item label="Node Region">
        							<Input placeholder="Type Here" onChange={handleNodeRegion} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Node Delete Protection Flag" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleNodeDeleteProtection} />
      							</Form.Item>
      							<Form.Item label="Host Cloud Provider *">
        							<Input placeholder="Type Here" onChange={handleCloudProvider} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Subscription Account Name" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleSubscriptionAccount} />
      							</Form.Item>
      							<Form.Item label="Product">
      								<Select options={props.defaultFilterValues.product} onChange={handleProduct} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Module" style={{marginRight:'auto'}}>
      								<Select options={props.defaultFilterValues.module} onChange={handleModule} />
      							</Form.Item>
      							<Form.Item label="Status">
        							<Input placeholder="Type Here" onChange={handleStatus} />
      							</Form.Item>
      						</div>
      						<Form.Item label="Miscellaneous">
					          <TextArea rows={4} placeholder="Type Here" onChange={handleMiscellaneous} />
					        </Form.Item>
					        <Form.Item label="Comments">
					          <TextArea rows={4} placeholder="Type Here" onChange={handleComments} />
					        </Form.Item>
      					</Form>
      					</div>
					</div>
					<div className="App-DI-create-node-modal-footer">
						<div className="App-DI-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateNode()}
	              				disabled={buttonDisable}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateNode
