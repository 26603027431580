import React, { useState , useEffect} from 'react';
import LeftTab from './lefttab'
import RightTab from './righttab'
import './assets/body.css';


function Body(props) {

  const [selectedTab, setSelectedTab] = useState("Dashboard")

  function handleTabSelection(tabName) {
    setSelectedTab(tabName);
    props.handleMenuSelection(false);
  }

  useEffect(() => {
    if ( props.showMenu) {
      setSelectedTab("Menu")
    } 
  }, [props.showMenu])

  return (
    <div className="App-body-block">
      <div className="App-body-block-upper-title-tab">
      </div>
      <div className="App-body-block-content">
        <div className="App-body-block-left-tab">
          <LeftTab handleTabSelection={handleTabSelection}/>
        </div>
        <div className="App-body-block-right-tab">
          <RightTab selectedTab={selectedTab} handleTabSelection={handleTabSelection}/>
        </div>
      </div>
    </div>
  );
}

export default Body;
