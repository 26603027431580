import React, { useState, useEffect, useMemo } from 'react';
import './assets/pageTile.css'

function PageTile(props) {

	function handleClick() {
		props.handleTabSelection(props.title)
	}

	return (
			<div className="App-DB-pageTile-container" onClick={handleClick}>
				<div className="App-DB-pageTile-icon">
					<div className="App-DB-pageTile-icon-title">{props.title}</div>
				</div>
				<div className="App-DB-pageTile-name">
					{props.name}
				</div>
			</div>
		)
}

export default PageTile