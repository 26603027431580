import React, { useState, useEffect, useMemo } from 'react';
import './assets/createUser.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';
import { Switch } from 'antd';
import Loader from '../common/Loader'


function EditUser(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { TextArea } = Input;

	const [userName, setUserName] = useState(props.currUser.user_name)

	const [userEmail, setUserEmail] = useState(props.currUser.email)

	const [showForm, setShowForm] = useState(false)

	const [checked, setChecked] = useState(props.currUser.isActive)

	const availableRoles = [
			{
				value : "dba_admin",
				label : "dba_admin"
			},
			{
				value : "dba_user",
				label : "dba_user"
			},
			{
				value : "developer",
				label : "developer"
			},
			{
				value : "cost_user",
				label : "cost_user"
			}
	]

	const [availableMongoAppConfigs, setAvailableMongoAppConfigs] = useState([])

	const [availableMysqlAppConfigs, setAvailableMysqlAppConfigs] = useState([])

	const [role, setRole] = useState(props.currUser.role)

	const [mongoAppConfigs, setMongoAppConfigs] = useState(props.currUser.mongo_app_config)

	const [mysqlAppConfigs, setMysqlAppConfigs] = useState(props.currUser.mysql_app_config)

	const [active, setActive] = useState(props.currUser.active)

	const [editDisabled, setEditDisabled] = useState(false)


	useEffect(() => {
		if (!props.currUser.mongo_app_config) {
			setMongoAppConfigs([])
		}
		if (!props.currUser.mysql_app_config) {
			setMysqlAppConfigs([])
		}
	}, [])


	function handleClose() {
		props.handleClose()
	}

	function handleActive(e) {
		setActive(e)
	}

	function handleUserName(e) {
		setUserName(e.target.value)
	}

	function handleMongoChange(e) {
		console.log("Mongo Change")
		console.log(e)
		setMongoAppConfigs(e)
	}


	function handleMysqlChange(e) {
		console.log("Mysql Change")
		console.log(e)
		setMysqlAppConfigs(e)
	}

	function handleUserEmail(e) {
		setUserEmail(e.target.value)
	}

	function handleRole(e) {
		setRole(e)
	}

	useEffect(()  => {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		fetch( BASE_URL + '/v1/slowqueries/getApplications?db_type=mongodb', {
   			method : 'GET',
   			headers : {
   				'Accept' : 'application/json',
   				'x-api-key' : apikey,
   				'oauth-token' : oauth_token,
   				'Content-Type' : 'application/json'
   			}
   		})
		.then(res =>
			res.json())
		.then((data) => {
			var availableMongoConfigs = constructFilterArray(data['message']['mongodb'])
			var availableMysqlConfigs = constructFilterArray(data['message']['mysql'])
			console.log(availableMongoConfigs)
			setAvailableMongoAppConfigs(availableMongoConfigs)
			setAvailableMysqlAppConfigs(availableMysqlConfigs)
			setShowForm(true)
		})
		.catch(console.log)
	}, [])


	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}


	function handleEditNode() {
		console.log(props.currNode)
		setEditDisabled(true)
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/user/'+userEmail ,{
	    			method : 'PUT',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "role" : role,
	            "isActive" : active,
	            "mongo_app_config" : mongoAppConfigs,
	            "mysql_app_config" : mysqlAppConfigs
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setEditDisabled(false)
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-DI-create-node-container">
			{showForm ?
				<div className="App-DI-create-node-modal-content">
					<div className="App-DI-create-node-modal-header">
		 				<div className="App-DI-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DI-create-node-modal-header-content"> Edit User </div>
					</div>
					<div className="App-DI-create-node-modal-body">
						<div className="App-DI-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<Form.Item label="User Name">
        						<Input placeholder={userName} onChange={handleUserName} disabled />
      						</Form.Item>
      						<div className="inline">
      							<Form.Item label="Email" style={{marginRight:'auto'}}>
        							<Input placeholder={userEmail} onChange={handleUserEmail} disabled/>
      							</Form.Item>
      						</div>
      						
  							<Form.Item label="Role">
  								<Select placeholder={role} options={availableRoles} onChange={handleRole} />
  							</Form.Item>

  							<Form.Item label="Mysql App Config">
  								<Select mode="multiple" defaultValue={mysqlAppConfigs} options={availableMysqlAppConfigs} onChange={handleMysqlChange} />
  							</Form.Item>

  							<Form.Item label="Mongo App Config">
  								<Select mode="multiple" defaultValue={mongoAppConfigs} options={availableMongoAppConfigs} onChange={handleMongoChange} />
  							</Form.Item>

      						<Form.Item label="Active">
      							<Switch checked={active} onChange={handleActive} />
      						</Form.Item>
      					</Form>
      					</div>
					</div>
					<div className="App-DI-create-node-modal-footer">
						<div className="App-DI-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	            				disabled = {editDisabled}
	              				onClick = {() => handleEditNode()}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
				: 
				<div className="App-DI-create-node-modal-content">
					<Loader />
				</div>
			}
			</div>
		)

}

export default EditUser
