import React, { useState, useEffect, useMemo, useRef } from 'react';
import './assets/inventoryContracts.css';
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import HamburgerIcon from './assets/Hamburger.svg'
import Loader from '../common/Loader'
import  {Input} from 'antd'


function InventoryContracts(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const searchRef = useRef(null);

	const [showLoading, setShowLoading] = useState(true)

	const [slowQueryData, setSlowQueryData] = useState([])

	const { Search } = Input;

	const [totalCount, setTotalCount] = useState(0)

	const [showSearch, setShowSearch] = useState(false)

	const [loadData, setLoadData] = useState(false)

	const [showTable, setShowTable] = useState(false)

	const [searchValue, setSearchValue] = useState('')

	const [originalData, setOriginalData] = useState([])

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [tableColumns, setTableColumns] = useState([])

	const sortBy = useMemo(() => [{
		'id' : "startDates[0]",
		'desc' : true
	}])

	useEffect(() => {
		setShowLoading(true)
		setTableColumns(mongoColumns)
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')

	   	 const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/inventory_contract"
  		      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setOriginalData(result['data'])
		    setSlowQueryData(result['data'])
		    setTotalCount(result['count'])
		    setShowTable(true)
		    setLoadData(false)
		}
		doFetch()

	}, [])

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Name",
				accessor : "name",
				width : 500
			},
			{
				Header : "Start Date",
				accessor : "startDates[0]",
				Cell : row => (
						<div>
							{row.row.original.startDates.join(',')}
						</div>
					),
				width : 100
			},
			{
				Header : "Initial Parties",
				accessor : "internalParties",
				Cell : row => (
					<div>
						{row.row.original.internalParties.join(',')}
					</div>
				),
				width : 300
			},
			{
				Header : "Counter Parties",
				accessor : "counterParties",
				Cell : row => (
					<div>
						{row.row.original.counterParties.join(',')}
					</div>
				),
				width : 400
			},
			{
				Header : "Inital Expiration Date",
				accessor : "initialExpirationDate",
				Cell : row => (
					<div>
						{row.row.original.initialExpirationDate.join(',')}
					</div>
				),
				width : 100
			},
			{
				Header : "Upcoming Expiration Date",
				accessor : "upcomingExpirationDate",
				Cell : row => (
					<div>
						{row.row.original.upcomingExpirationDate.join(',')}
					</div>
				),
				width : 100
			}])

	useEffect(() => {
		setShowLoading(true)
		setTableColumns(mongoColumns)
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')

	   	 const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/inventory_contract"
  		      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    setOriginalData(result['data'])
		    setSlowQueryData(result['data'])
		    setTotalCount(result['count'])
		    setShowTable(true)
		    setLoadData(false)
		}
		doFetch()

	}, [])

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}


	const onSearch = (input) => globalSearch(input);

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.name &&  value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.internalParties && value.internalParties.join(',').toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.counterParties && value.counterParties.join(',').toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.initialExpirationDate && value.initialExpirationDate.join(',').toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.upcomingExpirationDate && value.upcomingExpirationDate.join(',').toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

	return (
			<div className="App-TLC-tab-container">
				<div className="App-body-RS-Header-Container">
					<div className="App-body-RS-Header-Title-Container">
						<div className="App-body-RS-Header-Title">
							<div className="App-body-RS-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-RS-Header-Title-Name">
								Inventory Contracts
							</div>
						</div>
					</div>
				</div>
				<div className="App-RJ-create-node-modal-body">
					<div className="App-RJ-create-node-modal-body">
						<div className="App-RJ-table-container-search-box">
							{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    						{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
						</div>
						{showTable ?
							<div className="App-RS-tab-body-table-container">
								<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} sortBy={sortBy} />
							</div>
						: 
							<div className="App-RS-tab-body-table-loader">
								<Loader />
							</div> 
						}
					</div>
				</div>
			</div>
		)

}

export default InventoryContracts