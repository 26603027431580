import React, { useState, useEffect, useMemo } from 'react';
import NewTable from '../common/NewTable'
import LeftChevron from './assets/chevron-left.svg';


function UserAccesDetails(props) {

	const [accessDetails ,setAccessDetails] = useState([])

	const [tableColumns, setTableColumns] = useState([])

	const [hiddenColumns, setHiddenColumns] = useState([])
 
	const mongoColumns = useMemo(
		() => [
			{
				Header : "Module Name",
				accessor : "module_name",
				width : 400
			},
			{
				Header : "Last Accessed Time",
				accessor : "last_accessed_time",
				width : 400
			}
		])
 
	useEffect(() => {
		console.log("User Access")
		console.log(props.user.user_access)
		var access_array = []
		if (props.user.user_access) {
			access_array = dictToArray(props.user.user_access)
		}
		setAccessDetails(access_array)
		setTableColumns(mongoColumns)
	}, [])


	function dictToArray(dict) {
		return Object.keys(dict).map(key => {
			return {
				'module_name' : key,
				'last_accessed_time' : dict[key]
			}
		})
	}

	function handleClose() {
		props.handleClose()
	}

	return (
			<div className="App-SQ-create-node-container">
				<div className="App-SQ-create-node-modal-content">
					<div className="App-SQ-create-node-modal-header">
		 				<div className="App-SQ-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-SQ-create-node-modal-header-content"> 
 							Usage Metrics - {props.user.user_name}
 						</div>
					</div>
					<div className="App-SQ-create-node-modal-body">
						<div className="App-DCD-modal-table">
							<NewTable data={accessDetails} columns={tableColumns} hiddenColumns={hiddenColumns} />
						</div>
					</div>
				</div>
			</div>
		)

}

export default UserAccesDetails