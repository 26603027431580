import React, {useState, useEffect, Fragment} from 'react'
import { 
	useTable, 
	useBlockLayout, 
	useResizeColumns,
  	useSortBy,
  	useExpanded,
  	usePagination 
  } from 'react-table'
import Loader from './Loader'
import './assets/draggabletable.css'
import {Input} from 'reactstrap'
import { Button } from 'antd';
import SettingsIcon from './assets/settings.svg'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import DragRow from './dragRow'
import {HTML5Backend} from 'react-dnd-html5-backend'
import BurgerMenu from '../activities/BurgerMenu'
import update from 'immutability-helper'


function DraggableTable( { columns, data, loading, hiddenColumns, getRowProps, stickyTable, showMenu, menu, handleMenu, parentId, handleDrag} ) {

  const [tableHeaderClassName, setTableHeaderClassName] = useState('App-drag-table-header')
  const [tableRowClassName, setTableRowClassName] = useState('App-drag-table-row')
  const [showFooter, setShowFooter] = useState(true)

  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const moveRow = (dragIndex, hoverIndex) => {
    moveDataRow(dragIndex, hoverIndex)
  }

  function moveDataRow(dragIndex, hoverIndex) {
    console.log("Data Moving ")
    const dragRecord = data[dragIndex]
    const newData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    console.log(newData)
    if (parentId != null) {
      handleDrag(parentId, newData)
    }
  }

  useEffect(() => {
    if(stickyTable) {
      setTableHeaderClassName('App-drag-table-header-sticky')
      setTableRowClassName('App-drag-table-row-sticky')
    }
  }, [])


  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      maxWidth: 400,
      display : 'flex',
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    resetResizing,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns : hiddenColumns },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination
  )

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  useEffect(() => {
    if ( rows.length > 10) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [page])

  return (
    <DndProvider backend={HTML5Backend} >
      <div className="App-drag-table-container-body">
      {loading ? 
      		<Loader /> 
      		:
		      	<div className="App-drag-table-container-body-table">
		      		<Fragment >
			        <div {...getTableProps()} className="draggable-new-table">
			          <div className={tableHeaderClassName}>
			            {headerGroups.map(headerGroup => (
			              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      <div className="draggable-th">
                        <div className="draggable-th-content">
                        </div>
                      </div>
			                {headerGroup.headers.map(column => (
			                  <div {...column.getHeaderProps()}  className="draggable-th">
			                  <div {...column.getSortByToggleProps()} className="draggable-th-content">
			                    {column.render('Header')}
			                    </div>
			                    {/* Use column.getResizerProps to hook up the events correctly */}
			                    <div
			                      {...column.getResizerProps()}
			                      className={`resizer ${
			                        column.isResizing ? 'isResizing' : ''
			                      }`}
			                    />
			                  </div>
			                ))}
                      {showMenu && <div className="App-drag-table-col-header-sticky"><img src={SettingsIcon} /></div> }
			              </div>
			            ))}
			          </div>
			          <div {...getTableBodyProps()}>
			            {page.map((row, i) => {
                    prepareRow(row) 
                    return (<DragRow tableRowClassName={tableRowClassName} row={row} index={i}
                      moveRow={moveRow}
                      showMenu={showMenu}
                      menu={menu}
                      handleMenu={handleMenu}
                      /> )
                    
			            })
                }
			          </div>
		          </div>
		          	</Fragment>
		        </div>
    	}
      {showFooter ?
    	<div>
        <Fragment>
          <div className="App-table-container-footer">
            <div className="App-table-container-footer-page-size">
              <div className="App-table-container-footer-page-size-dropdown">
                <Input
                  type='select'
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  &gt;
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Input>          
              </div>
              <div className="App-table-container-footer-page-size-label">
                <div>
                Results Per Page
                </div>
              </div>
            </div>
            <div className="App-table-container-footer-page-link">
              <div className="App-table-container-footer-page-link-container">
                <div className="App-table-container-footer-page-link-button-fprev">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {'<<'}
                  </Button>
                </div>
                <div className="App-table-container-footer-page-link-button-prev">
                  <Button
                    color='primary'
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    {'<'}
                  </Button>
                </div>
            
                <div className="App-table-container-footer-page-link-detail" style={{ marginTop: 7 }}>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </div>

                <div className="App-table-container-footer-page-link-button-next">
                  <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                    {'>'}
                  </Button>
                </div>

                <div className="App-table-container-footer-page-link-button-fnext">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {'>>'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
      : <div></div> }
      </div>
      </DndProvider>
  )
}

export default DraggableTable