import React, { useState, useEffect, useMemo } from 'react';
import OverFlowIcon from './assets/overflow-icon.svg'

function ClusterPanelHeader(props) {

	

	function handleEdit(event) {
		event.stopPropagation()
		props.handleEdit(props.cluster)
	}

	function handlePOCPopup(event) {
		var poc = props.cluster.poc
		if ( poc && poc.length > 0) {
			event.stopPropagation()
			props.handlePopupOpen('JSON', props.cluster.cluster_display_name + ' - POC', poc)
		}
	}

	function handleTagPopup(event) {
		var tags = props.cluster.tags
		if (tags) {
			event.stopPropagation()
			props.handlePopupOpen('JSON', props.cluster.cluster_display_name + ' - Tags', tags)
		}
	}

	

	return(
			<div className="App-DI-table-panel-cluster">
				<div className="App-DI-table-panel-cluster-name">
					<div className="App-DI-table-panel-cluster-name-text">
						{props.cluster.cluster_display_name}
					</div>
				</div>
				<div className="App-DI-table-panel-cluster-name" onClick={e => handlePOCPopup(e)} style={{'overflow' : 'auto'}}> 
					<div className="App-DI-table-panel-cluster-name-text" >
						{props.cluster.poc.toString()} 
					</div>
				</div>
				<div className="App-DI-table-panel-cluster-name" onClick={e => handleTagPopup(e)} style={{'overflow' : 'auto'}}> 
					<div className="App-DI-table-panel-cluster-name-text">
						{props.cluster.tags.toString()} 
					</div>
				</div>
				<div  className="App-DI-table-panel-cluster-edit" onClick={e=> handleEdit(e)}>
					<div className="App-DI-table-panel-cluster-name-text">
              			<img src={OverFlowIcon} />
              		</div>
            	</div>
			</div>
		)
}

export default ClusterPanelHeader