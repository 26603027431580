import React, { useState, useEffect, useMemo } from 'react';
import LeftChevron from './assets/chevron-left.svg';
import { format } from 'sql-formatter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './assets/querydisplayer.css'
import Loader from '../common/Loader'

function QueryDisplayer(props) {

	const [query, setQuery] = useState('')
	const [additionalQuery, setAdditionalQuery] = useState('')
	const [showLoader, setShowLoader] = useState(true)

	const [completeQuery, setCompleteQuery] = useState(true)

	const BASE_URL = process.env.REACT_APP_BASE_URL;


	function get_mongo_query(db_type, query, operation) {
		    var quoted_query = query.replace(/'/g, '"')
		    var none_removed_query = quoted_query.replace(/None/g, 'null')
			var final_query = none_removed_query.replace(/False/g, 'false')
			final_query = final_query.replace(/True/g, 'true')
			setQuery(final_query)
			try {
				if ( operation == 'update') {
					var start_position = final_query.search("}{")
					var first_json = final_query.substring(0, start_position + 1)
					var second_json = final_query.substring(start_position + 1, final_query.length)
					var array = []
					array[0] = JSON.parse(first_json)
					array[1] = JSON.parse(second_json)
					const prettyJsonString = JSON.stringify(array, null, 2);
					setQuery(prettyJsonString)
				} else {
					const jsonObject = JSON.parse(final_query);
					const prettyJsonString = JSON.stringify(jsonObject, null, 2);
					setQuery(prettyJsonString)
				}
			} catch (error) {
				setQuery(final_query)
			}
	
		setShowLoader(false)
	}

	function get_mysql_query(db_type, query_id) {

	}

	function areQuotesClosed(inputString) {
	  	const stack = [];

	  	const bracketStack = []

	  	for (let i = 0; i < inputString.length; i++) {
	    	const char = inputString[i];

		    if (char === "'" || char === '"') {
		      if (stack.length === 0 || stack[stack.length - 1] !== char) {
		        stack.push(char);
		      } else {
		        stack.pop();
		      }
		    } else if ( char === "(" || char === "{")  {
		    	bracketStack.push(char)
		    } else if ( char === "}" || char === ")") {
		    	bracketStack.pop()
		    }
	  	}

	  	return stack.length === 0 && bracketStack.length === 0;
	}

	useEffect(() => {
		if (props.db_type == "Mongo") {
			setShowLoader(false)
			get_mongo_query(props.db_type, props.query.actual_query, props.query.operation)
		} else {
			if ( !areQuotesClosed(props.query.example_query)) {
				setCompleteQuery(false)
				setQuery(props.query.example_query)
			} else {
				setCompleteQuery(true)
				setQuery(props.query.example_query)
			}			
			setShowLoader(false)
		}
	}, [])

	function handleClose() {
		props.handleClose()
	}

	return(
			<div className="App-SQ-create-node-container">
				<div className="App-SQ-create-node-modal-content">
					<div className="App-SQ-create-node-modal-header">
		 				<div className="App-SQ-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-SQ-create-node-modal-header-content"> 
 							Distillate - {props.query.cluster_name}
 						</div>
					</div>
					<div className="App-SQ-create-node-modal-body">
					{!showLoader ?
							<div className="App-SQ-create-node-content">
								{ 
									props.db_type == 'Mysql' ? 
										completeQuery ?
										<SyntaxHighlighter language="sql" style={docco}>
			      							{format(query, 
			      								{
				      								language : 'mysql', 
				      								 
				      								tabWidth: 1,
				      								keywordCase: 'upper'
			      								}
			      							)}
										</SyntaxHighlighter>
										: <SyntaxHighlighter language="mysql" style={docco}>{query}</SyntaxHighlighter>
									: 
										<SyntaxHighlighter language="json" style={docco}>
			      							{query}
										</SyntaxHighlighter>

								}
							</div>
						: 
							<Loader/>
					}
					</div>
				</div>
			</div>
		)

}

export default QueryDisplayer