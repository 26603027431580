import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import Title from './components/title/title'
import Body from './components/body/body'
import Loader from './components/common/Loader'
import "bootstrap/dist/css/bootstrap.min.css"
import { useOktaAuth } from '@okta/okta-react';
import './App.css';

function DBPortalApp(props) {

  const { authState, oktaAuth } = useOktaAuth();

  const [showMenu, setShowMenu] = useState(false)

      useEffect(() => {
            if(authState.isAuthenticated) {
                  props.sucessfullLogin(authState)
            }
        }, 
        [])

    function handleMenuSelection(showMenu) {
      setShowMenu(showMenu)
    }

  return (
    <div className="App-body-container">
      {props.loadApp ?
              <div className="App-body-full-container">
                <Title handleLogout={props.handleLogout} handleMenuSelection={handleMenuSelection} />
                <Body showMenu={showMenu} handleMenuSelection={handleMenuSelection} />
              </div>
        : 
            <Loader /> 
      }
    </div>
  );
}

export default DBPortalApp;
