import React,  { useEffect, useState } from 'react'

function ReleaseSuccessCell(props) {

	const [successCellCss, setSuccessCellCss] = useState('App-RS-Success')


	useEffect(() => {
		console.log("Data")
		console.log(props.data)
		if( props.data.Success > 0 ) {
			if ((props.data.Failed && props.data.Failed > 0) || 
				(props.data.Initiated && props.data.Initiated > 0) ||
				(props.data.To_be_executed && props.data.To_be_executed  > 0)) {
				setSuccessCellCss('App-RS-Cell')
			}
		}
	
	}, [])


	function  handleClick() {
		props.handleClick(props.data)
	}
	
	return(
		<div className={successCellCss} onClick={handleClick} >
			{props.data.Success}
		</div>
	)
}

export default ReleaseSuccessCell
