import React, { useState, useEffect, useMemo, useRef } from 'react';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import './assets/mongoUsers.css';
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'


function MongoUsers(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [showTable, setShowTable] = useState(false)

	const [showForm, setShowForm] = useState(false)

	const [disableOrg, setDisableOrg] = useState(true)

	const [tableColumns, setTableColumns] = useState([])

	const [mongoUserFilters, setMongoUserFilters] = useState({})

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [showSearch, setShowSearch] = useState(false)

	const [loadData, setLoadData] = useState(false)

	const [mongoUserData, setMongoUserData] = useState([])

	const [originalData, setOriginalData] = useState([])

	const [orgOptions, setOrgOptions] = useState([])

	const [selectedOrg, setSelectedOrg] = useState('')

	const [disableProject, setDisableProject] = useState(true)

	const [projectOptions, setProjectOptions] = useState([])

	const [selectedProject, setSelectedProject] = useState('')

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [searchValue, setSearchValue] = useState('')

	const mongoColumns = useMemo(
		() => [
			{
				Header : "UserName",
				accessor : "username",
				width : 300
			},
			{
				Header : "scopes",
				accessor : "scopes",
				width : 300
			},
			{
				Header : "roles",
				accessor : "roles",
				width : 300
			}

		])

	const onSearch = (input) => globalSearch(input);

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setMongoUserData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.databaseName &&  value.databaseName.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.username && value.username.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setMongoUserData(filteredData);
			}
  	};

  	useEffect(() => {
		if (loadData) {
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/mongousers/getusers?organization=" + selectedOrg + "&project=" + selectedProject,
	  		  	{
			      method : 'GET',	
			      headers : {
			        'x-api-key' : apikey,
			        'oauth-token' : oauth_token
			     }
		    })
		    const body = await response.json()
		    const result = body.message
		    setOriginalData(result['users'])
		    setMongoUserData(result['users'])
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

  	useEffect(() => {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   	const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/mongousers/getorgs"
			      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMongoUserFilters(result)
		    setShowForm(true)
		}
		doFetch()
	}, [])

	useEffect(() => {
		if(showForm) {
				setOrgOptions(constructFilterArray(Object.keys(mongoUserFilters['organizations'])))
				setDisableOrg(false)
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleProjectChange(value) {
  		setSelectedProject(value)
  		setShowReportDisable(false)
  	}

  	function handleOrgChange(value) {
  		setSelectedOrg(value)
  		setDisableProject(false)
  		var selectedProjects = mongoUserFilters['organizations'][value]['projects']
  		setProjectOptions(constructFilterArray(selectedProjects))
  	}

  	function handleButtonClick() {
  		setTableColumns(mongoColumns)
		if (!loadData) {
			setMongoUserData([])
			setShowTable(false)
			setLoadData(true)
		}
  	}

  	function handleReset() {
  		setSelectedOrg('')
  		setSelectedProject('')
  		setProjectOptions([])
  		setDisableProject(true)
  		setShowReportDisable(true)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}


	return(
			<div className="App-GLDC-tab-container">
				<div className="App-body-GLDC-Header-Container">
					<div className="App-body-GLDC-Header-Title-Container">
						<div className="App-body-GLDC-Header-Title">
							<div className="App-body-GLDC-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-GLDC-Header-Title-Name">
								Mongo Users
							</div>
						</div>
					</div>
				</div>
				{
					showForm && showTable ?
						<div className="App-GLDC-tab-body">
							<div className="App-GLDC-tab-mongo-container">
								<div className="App-GLDC-tab-mongo-filter">		
											<div className="App-GLDC-tab-mongo-form">
											{showForm ? 
												<div className="App-GLDC-tab-mongo-filter-form">
													<Form
						      							form={form}
						      							layout="vertical"
						      						>
						      							<div className="GLDC-inline">
						      								<Form.Item label="Organization" style={{width : '25%', margin:'1em 2em'}}>
							        							<Select disabled={disableOrg} options={orgOptions} value={selectedOrg} onChange={handleOrgChange} />
							      							</Form.Item>
							      							<Form.Item label="Projects" style={{width : '25%', margin : '1em 2em'}}>
							      								<Select disabled={disableProject} options={projectOptions} value={selectedProject} onChange={handleProjectChange} />
							      							</Form.Item>
							      							<Button
							      							 	disabled={showReportDisable}
									            				type='primary'
									            				onClick={handleButtonClick}
									            				style={{margin : '3em 2em'}}
									              			>
						              							Show Report
						            						</Button>
						            						<Button
						            							onClick={handleReset}
						            							style={{ margin : '3em 1em'}}
						            						>
						            							Reset
						            						</Button>	      							
						      							</div>
						      						</Form>
												</div>
											: 
												<div className="App-SQ-tab-form-loader">
					      							<Loader />
					      						</div>
											}
											</div>

								</div>
								<div className="App-GLDC-tab-mongo-data-container">
									<div className="App-GLDC-tab-body-table-header-container">
										<div className="App-GLDC-table-container-search-box">
											{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
		    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
		    							</div>
		    						</div>
									{showTable ?
										<div className="App-GLDC-tab-body-table-container">
											<NewTable data={mongoUserData} columns={tableColumns} hiddenColumns={hiddenColumns} />
										</div>
									: 
										<div className="App-GLDC-tab-body-table-loader">
											<Loader />
										</div> 
									}
								</div>
							</div>			
						</div>
					: 
						 <div className="App-SQ-tab-form-loader">
						 	<Loader />
						 </div>
				}
			</div>
		)
}

export default MongoUsers