import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../common/TableContainer'
import './assets/products.css'
import 'antd/dist/antd.css';
import { Button, Radio } from 'antd';
import CreateProduct from './createProduct'



function Products(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

  	const [showLoading, setShowLoading] = useState(true)

  	const [showCreate, setShowCreate] = useState(false)

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)


  	const columns = useMemo(
	  () => [
	    {
	      Header: "Product",
	      accessor: "product"
	    },
	    {
	      Header: "Module",
	      accessor: "module",
	    },
	  ],
	  []
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

  	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	    	console.log(value)
	      	return (
	        	value.product.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.module.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
		}
  	};

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

	useEffect(() => {
		if ( loadData) {
			setShowLoading(true)
		    console.log(BASE_URL)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/product", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      console.log(nodes)
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
    	}
  	}, [loadData])


	return(
		<div className="App-Product-table-container">
        	<div className="App-Product-table-container-header">
          		<div className="App-Product-table-container-header-title">
           			Products and Modules
           		</div>
           		{showAdd ? <div className="App-Product-table-container-header-add-new">
           			<Button 
              			type='primary'
              			onClick = {() => handleNewClick()}
              		>
              			Add Product and Module
            		</Button>
           		</div> : <div></div> }
    		</div>
	        <TableContainer data={data} columns={columns} loading={showLoading} hiddenColumns={hiddenColumns} getRowProps={row => ({
	          style: {
	            cursor: "pointer",
	            height : "10px"
	          }
	        })} />
	        {showCreate ? <CreateProduct  handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}
    	</div>
	)

}

export default Products