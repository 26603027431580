import React, { useState, useEffect, useMemo } from 'react';
import './assets/databaseCostSavingsReport.css'
import HamburgerIcon from './assets/HamburgerIcon.svg'
import { DatePicker } from 'antd';
import NewTable from '../common/NewTable'
import Loader from '../common/Loader'
import moment from 'moment';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';


const { RangePicker } = DatePicker;


function DatabaseCostSavingsReport(props) {

	const { Panel } = Collapse;

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [showTable, setShowTable] = useState(false)

	const [loadData, setLoadData] = useState(false)

	var today = new Date();

	// Get the start date of the previous month
	var startOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
	var formattedStartOfPreviousMonth = startOfPreviousMonth.getFullYear() + '-' + ('0' + (startOfPreviousMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + startOfPreviousMonth.getDate()).slice(-2);

	// Format today's date as YYYY-MM-DD
	var formattedToday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);

	console.log(formattedToday)
	console.log(formattedStartOfPreviousMonth)

	const [startDate, setStartDate] = useState(formattedStartOfPreviousMonth)

	const [endDate, setEndDate] = useState(formattedToday)

	const [data, setData] = useState([])

	const hiddenColumns = []

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

	const columns = useMemo(
	  () => [
	    {
	      	Header: "Date",
	      	accessor: "db_activity_time",
	      	width : 150
	    },
	    {
	    	Header : "Nodes Affected",
	    	accessor : "db_endpoint",
	    	width : 300
	    },
	    {
	      	Header: "Jira",
	      	accessor: "db_jira",
	      	width : 300
	    },
	    {
	      	Header: "Comments",
	      	accessor: "db_comments",
	      	width : 300
	    },
	    {
	    	Header : "POC",
	    	accessor : "db_poc",
	    	width : 150
	    },
	    {
	    	Header : <div className="App-body-CS-Right-Cell">Savings (USD)</div>,
	    	accessor : "db_cost_effect_manual",
	    	width : '250',
	    	Cell: row => (
            <div className="App-body-CS-Right-Cell">
              	{formatter.format(Math.abs(row.row.original.db_cost_effect_manual))}
            </div>
            ),
	    }
	   ]
	) 


	function handleDateChange(value, dateString) {
		setStartDate(dateString[0])
		setEndDate(dateString[1])
		setShowTable(false)
	}

	useEffect(() => {
		getSavingsReport()
	}, [startDate, endDate])

	useEffect(() => {
		getSavingsReport()
  	}, [])

  	function getSavingsReport() {
  		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	    const doFetch = async () => {
	      const response = await fetch(BASE_URL + "/v1/cost_savings_report?start_date=" + startDate
	      		+ "&end_date=" + endDate, {
	      method : 'GET',
	      headers : {
	        'x-api-key' : apikey,
	        'oauth-token' : oauth_token
	      }
	    })
	      const body = await response.json()
	      const result = body.message
	      const nodes = result['data']
	      console.log(nodes)
	      setData(nodes)
	      setShowTable(true)
	      setLoadData(true)
	    }
    	doFetch()
  	}

	return(
			<div className="App-body-CS-Outer-Container">
				{loadData ?
					<div className="App-body-CS-Container">
						<div className="App-body-CS-Header-Container">
							<div className="App-body-CS-Header-Title-Container">
								<div className="App-body-CS-Header-Title">
									<div className="App-body-CS-Header-Title-Hamburger">
										<img src={HamburgerIcon} />
									</div>
									<div className="App-body-CS-Header-Title-Name">
										Cost Savings Report 
									</div>
								</div>
							</div>
							<div className="App-body-CS-Header-Date-Container">
								<div className="App-body-CS-Header-Date">
									<RangePicker  onChange={handleDateChange}  defaultValue={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]} />
								</div>
							</div>
						</div>
						<div className="App-body-CS-Body-Container">
							<div className="App-body-CS-Body-Outer-Container">
								<div className="App-body-CS-Body-Table">
									<div className="App-body-CS-Body-Table-Header">
										<div className="App-body-CS-Body-Table-Header-Title">
											<div className='App-body-CS-Body-Table-Header-Data'>
												Cost Savings Summary
											</div>
										</div>
									</div>
									<div className="App-body-CS-Body-Table-Body">
										<div className="App-CS-table-container-table">
											<div className="App-CS-table-header">
		    									<div className="App-CS-table-header-expand">
		    									</div>
		    									<div className="App-CS-table-header-content">
								    			<div className="App-CS-table-header-cs-month-name">
								    				<div className="App-CS-table-header-cs-name-text">
								    					Month
								    				</div>
								    			</div>
								    			<div className="App-CS-table-header-cs-savings">
								    				<div className="App-CS-table-header-data-cs-savings-header-text">
								    					<div className="App-body-CS-Right-Cell-Header">Total Savings (USD) </div>
								    				</div>
								    			</div>
								    			</div>
		    								</div>
		    								
		    									{showTable ?
									    		<Collapse>
									    			{data.map((savings, index) => (
									    					<Panel header=
									    							<div className="App-CS-table-header-data">
									    								<div className="App-CS-table-header-cs-month-name">
								    										<div className="App-CS-table-header-data-cs-name-text">
								    											{savings.month}
								    										</div>
								    									</div>
								    									<div className="App-CS-table-header-cs-savings">
																    		<div className="App-CS-table-header-data-cs-savings-text">
																    			<div className="App-body-CS-Right-Cell">{formatter.format(Math.abs(savings.total_cost))}</div>
																    		</div>
																    	</div>
									    							</div>
									    					>
									    						<NewTable data={savings.data} columns={columns} loading={!showTable} hiddenColumns={hiddenColumns} getRowProps={row => ({
													          style: {
													            cursor: "pointer",
													            height : "10px",
													            borderLeft : "1px solid #4285F4"
													          }
													        })} />
									    					</Panel>
									    				))}
									    		</Collapse>
		    		 						: 
		    		 							<Loader />
		    								}
		    								
										</div>
									</div>
								</div>
								<div>
								</div>
							</div>
						</div>
					</div>
				: 
				<Loader />
			}
		</div>
		)

}

export default DatabaseCostSavingsReport