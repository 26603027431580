import React, { useState, useEffect,  useMemo, useRef} from 'react';
import './assets/releaseDatabaseList.css';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'
import * as XLSX from 'xlsx';

function ReleaseDatabaseList(props) {

	const [active, isActive] = useState(true)

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [mysqlFilters, setMysqlFilters] = useState({})

	const [loadData, setLoadData] = useState(false)

	const [selectedDBType, setSelectedDBType] = useState('Mongo')

	const [showForm, setShowForm] = useState(false)

	const [selectedDB, setSelectedDB] = useState([])

	const [showTable, setShowTable] = useState(false)

	const [showLoading, setShowLoading] = useState(true)

	const [selectedModule, setSelectedModule] = useState('')

	const [selectedEnv, setSelectedEnv] = useState('')

	const [selectedPod, setSelectedPod] = useState('')

	const [disableEnvironment, setDisableEnvironment] = useState(true)

	const [disablePod, setDisablePod] = useState(true)

	const [disableProduct, setDisableProduct] = useState(true)

	const [tableColumns, setTableColumns] = useState([])

	const [dbOptions, setDBOptions] = useState([])

	const [productOptions, setProductOptions] = useState([])

	const [envOptions, setEnvOptions ] = useState([])

	const [podOptions, setPodOptions] = useState([])

	const [selectedProduct, setSelectedProduct] = useState('')

	const [disableModule, setDisableModule] = useState(true)

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [totalCount, setTotalCount] = useState(0)

	const [dealerCount, setDealerCount] = useState(0)

	const [tenantCount, setTenantCount] = useState(0)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [moduleOptions, setModuleOptions] = useState([])

	const [filters, setFilters] = useState({})

	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])


	const mongoColumns = useMemo(
		() => [
			{
				Header : "DB Name",
				accessor : "db_name",
				width : 1000
			},
			{
				Header : "Pod",
				accessor : "pod",
				width : 1000
			},
			{
				Header : "",
				accessor : "dummy",
				width : 1000
			}
		], [])

	const onSearch = (input) => globalSearch(input);

	useEffect(() => {
		populateMysqlFilter()
	}, [])

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.db_name &&  value.db_name.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.pod && value.pod.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var available_databases = Object.keys(mysqlFilters)
				console.log("Availabel DBS")
				console.log(available_databases)
				setDBOptions(constructFilterArray(available_databases))
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	useEffect(() => {
		
		if (loadData) {
			setShowLoading(true)
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/release/filterdb?db=" + selectedDB +"&product=" + selectedProduct + "&module=" + selectedModule +"&env=" + selectedEnv +"&pod="
		      	+ selectedPod
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result['db_list'])
		    setOriginalData(result['db_list'])
		    setSlowQueryData(result['db_list'])
		    setTotalCount(result['total_count'])
		    setDealerCount(result['dealer_count'])
		    setTenantCount(result['tenant_count'])
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	function populateMysqlFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/release/dbListFilter"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMysqlFilters(result)
		    console.log("Hiiii")
		    setShowForm(true)
		}
		doFetch()
	}

	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedQuery = []
		combinedQuery.push({'title' : 'Release Databases', jsonData : slowQueryData})
		convertJSONsToXLSX(combinedQuery)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "release_databases.xlsx");
    };

	function handleDBChange(fieldLabel, fieldValue) {
		console.log(fieldLabel)
		console.log(fieldValue)
		setSelectedDB(fieldLabel)
		var available_products = Object.keys(filters[fieldLabel])
		setProductOptions(constructFilterArray(available_products))
		setSelectedProduct('')
		setSelectedModule('')
		setSelectedEnv('')
		setSelectedPod('')
		setShowReportDisable(true)
		setDisableProduct(false)
		setDisableModule(true)
		setDisableEnvironment(true)
		setDisablePod(true)
 	}

	function handleProductChange(fieldLabel, fieldValue) {
		setSelectedProduct(fieldLabel)
		var available_modules = Object.keys(filters[selectedDB][fieldLabel])
		setModuleOptions(constructFilterArray(available_modules))
		setSelectedModule('')
		setSelectedEnv('')
		setSelectedPod('')
		setShowReportDisable(true)
		setDisableModule(false)
		setDisableEnvironment(true)
		setDisablePod(true)
	}

	function handleModuleChange(fieldLabel, fieldValue) {
		setSelectedModule(fieldLabel)
		var available_environments = Object.keys(filters[selectedDB][selectedProduct][fieldLabel])
		setEnvOptions(constructFilterArray(available_environments))
		setSelectedEnv('')
		setSelectedPod('')
		setShowReportDisable(true)
		setDisableEnvironment(false)
		setDisablePod(true)
	}

	function handleEnvChange(fieldLabel, fieldValue) {
		setSelectedEnv(fieldLabel)
		var available_pods = filters[selectedDB][selectedProduct][selectedModule][fieldLabel]
		console.log("Available Pods")
		console.log(available_pods)
		setPodOptions(constructFilterArray(available_pods))
		setSelectedPod('')
		setShowReportDisable(true)
		setDisablePod(false)
	}

	function handlePodChange(fieldLabel, fieldValue) {
		setSelectedPod(fieldLabel)
		setShowReportDisable(false)
	}

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

	function handleButtonClick(){
		console.log("Button Click is")
		console.log("Load Data is")
		console.log(loadData)
		setTableColumns(mongoColumns)
		if (!loadData) {
			setSlowQueryData([])
			setShowTable(false)
			setLoadData(true)
		}
	}

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleReset() {
		setSelectedDBType('Mongo')
		setSelectedProduct()
		setSelectedModule()
		setSelectedDB([])
		setSelectedEnv()
		setDisableModule(true)
		setDisableProduct(true)
		setDisableEnvironment(true)
		setFilters(mysqlFilters)
		setShowReportDisable(true)
		setSlowQueryData([])
	}

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}


	return(
			<div className="App-RDB-tab-container">
				<div className="App-body-RDB-Header-Container">
					<div className="App-body-RDB-Header-Title-Container">
						<div className="App-body-RDB-Header-Title">
							<div className="App-body-RDB-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-RDB-Header-Title-Name">
								Release DB List 
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ?
				<div className="App-RDB-tab-body">
					<div className="App-RDB-tab-mongo-container">
						<div className="App-RDB-tab-mongo-filter">		
									<div className="App-RDB-tab-mongo-form">
									{showForm ? 
										<div className="App-RDB-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="RDB-inline">
				      								<Form.Item label="DB" style={{width : '15%', margin:'10px auto'}}>
					        							<Select options={dbOptions} value={selectedDB} onChange={handleDBChange} />
					      							</Form.Item>
					      							<Form.Item label="Product" style={{width : '15%', margin:'10px auto'}}>
					        							<Select disabled={disableProduct} options={productOptions} value={selectedProduct} onChange={handleProductChange} />
					      							</Form.Item>
					      							<Form.Item label="Module" style={{width : '15%', margin:'10px auto'}}>
					        							<Select disabled={disableModule} options={moduleOptions} value={selectedModule} onChange={handleModuleChange} />
					      							</Form.Item>
					      							<Form.Item label="Environment" style={{width : '15%', margin:'10px auto'}}>
					        							<Select disabled={disableEnvironment} options={envOptions} value={selectedEnv} onChange={handleEnvChange} />
					      							</Form.Item>
					      							<Form.Item label="POD" style={{width : '15%', margin:'10px auto'}}>
					        							<Select disabled={disablePod} options={podOptions} value={selectedPod} onChange={handlePodChange} />
					      							</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : 'auto', marginTop : '40px'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : 'auto', marginTop : '40px'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-SQ-tab-form-loader">
			      							<Loader />
			      						</div>
									}
									</div>

						</div>
						<div className="App-RDB-tab-mongo-data-container">
							<div className="App-RDB-tab-body-table-header-container">
								<div className="App-RDB-table-container-count-box">
									<div className="App-RDB-table-container-count"> Total Count :  {totalCount}</div>
									<div>| </div>
									<div className="App-RDB-table-container-count"> Dealer Count : {dealerCount}</div>
									<div>| </div>
									<div className="App-RDB-table-container-count"> Tenant Count : {tenantCount}</div>
								</div>
								<div className="App-RDB-table-container-search-box">
									<div className="App-body-RJ-header-export">
										<img src={ExportIcon} onClick={handleExportClick} />
									</div>
									{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    							</div>
    						</div>
							{showTable ?
								<div className="App-RDB-tab-body-table-container">
									<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-RDB-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
					</div>				
				</div>
				: 
				 <div className="App-SQ-tab-form-loader">
				 	<Loader />
				 </div>
				}
			</div>
		)

}

export default ReleaseDatabaseList