import React, { useState, useEffect, useMemo } from 'react';
import './assets/databaseCostSummaryReport.css'
import HamburgerIcon from './assets/HamburgerIcon.svg'
import { DatePicker } from 'antd';
import NewTable from '../common/NewTable'
import Loader from '../common/Loader'
import moment from 'moment';
import { Collapse } from 'antd';
import { Switch } from 'antd';
import 'antd/dist/antd.css';
import LeftChevron from './assets/chevron-left.svg';
import RightChevron from './assets/chevron-right.svg'


const { RangePicker } = DatePicker;


function DatabaseCostSavingsReport(props) {

	const { Panel } = Collapse;

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [showTable, setShowTable] = useState(false)

	const [loadData, setLoadData] = useState(false)

	const [tableColumns, setTableColumns] = useState([])

	const sortBy = [{
		'id' : "Total Cost ($) ",
		'desc' : false
	}]

	var today = new Date();

	// Get the start date of the previous month
	var startOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
	var formattedStartOfPreviousMonth = startOfPreviousMonth.getFullYear() + '-' + ('0' + (startOfPreviousMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + startOfPreviousMonth.getDate()).slice(-2);

	// Format today's date as YYYY-MM-DD
	var formattedToday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);

	console.log(formattedToday)
	console.log(formattedStartOfPreviousMonth)

	const [startDate, setStartDate] = useState(formattedStartOfPreviousMonth)

	const [endDate, setEndDate] = useState(formattedToday)

	const [data, setData] = useState([])

	const [showEnv, setShowEnv] = useState(true)

	const [showModule, setShowModule] = useState(true)

	const hiddenColumns = []

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})


	function convertToCSV(data) {
	  var csv = "";
	  var headers = Object.keys(data[0]).join(",") + "\n";
	  csv += headers;

	  for (var i = 0; i < data.length; i++) {
	    var row = Object.values(data[i]).join(",") + "\n";
	    csv += row;
	  }

	  return csv;
	}

	function downloadCSV(csv) {
	  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	  var link = document.createElement("a");
	  var url = URL.createObjectURL(blob);
	  link.setAttribute("href", url);
	  link.setAttribute("download", "data.csv");
	  link.style.visibility = "hidden";
	  document.body.appendChild(link);
	  link.click();
	  document.body.removeChild(link);
	}

	const columns = useMemo(
	  () => [
	    {
	      	Header: "Product",
	      	accessor: "db_product",
	    },
	    {
	    	Header : "Environment",
	    	accessor : "db_env",
	    },
	    {
	      	Header: "Module",
	      	accessor: "db_module",
	    },
	    {
	      	Header: "Atlas Cost ($) ",
	      	accessor: "atlas_cost",
	      	width : 150,
	      	Cell: row => (
	            <div className="App-body-CSum-Right-Cell">
	              	{row.row.original.atlas_cost && formatter.format(Math.abs(row.row.original.atlas_cost))}
	            </div>
            )
	    },
	    {
	    	Header : "RDS-PROD ($) ",
	    	accessor : "aws_rds_cost",
	    	width : 150,
	    	Cell: row => (
            	<div className="App-body-CSum-Right-Cell">
              		{row.row.original.aws_rds_cost && formatter.format(Math.abs(row.row.original.aws_rds_cost))}
            	</div>
            )
	    },
	    {
	    	Header : "AWS Prod/Non Prod EC2 ($) ",
	    	accessor : "aws_ec2_cost",
	    	width : 150,
	    	Cell: row => (
	            <div className="App-body-CSum-Right-Cell">
	              	{row.row.original.aws_ec2_cost && formatter.format(Math.abs(row.row.original.aws_ec2_cost))}
	            </div>
            )
	    },
	    {
	    	Header : "Azure PostgreSQL Server ($) ",
	    	accessor : "azure_vm_cost",
	    	width : 150,
	    	Cell: row => (
	            <div className="App-body-CSum-Right-Cell">
	              	{row.row.original.azure_vm_cost && formatter.format(Math.abs(row.row.original.azure_vm_cost))}
	            </div>
            )
	    },
	    {
	    	Header : "Azure VM Server / MongoDB ($) ",
	    	accessor : "azure_postgres_cost",
	    	width : 150,
	    	Cell: row => (
	            <div className="App-body-CSum-Right-Cell">
	              	{row.row.original.azure_postgres_cost &&  formatter.format(Math.abs(row.row.original.azure_postgres_cost))}
	            </div>
            )
	    },
	    {
	    	Header : "Total Cost ($) ",
	    	accessor : "",
	    	width : 200,
	    	Cell: row => (
	            <div className="App-body-CSum-Right-Cell">
	              	{formatter.format(Math.abs(row.row.original.azure_vm_cost) + Math.abs(row.row.original.azure_vm_cost)
	              		+ Math.abs(row.row.original.aws_ec2_cost) + Math.abs(row.row.original.aws_rds_cost)
	              		+ Math.abs(row.row.original.atlas_cost)
	              		)}
	            </div>
            )
	    }
	   ]
	) 

	useEffect(() => {
		console.log("Initial render")
		setTableColumns(columns)
		getSavingsReport()
  	}, [])


  	function handleLeftDateChange() {
		var newStartDate = moment(startDate, 'YYYY-MM-DD').subtract(1, 'M')
		var newEndDate = moment(endDate, 'YYYY-MM-DD').subtract(1, 'M')
		setStartDate(newStartDate.format('YYYY-MM-DD'))
		setEndDate(newEndDate.format('YYYY-MM-DD'))
		setShowTable(false)
	}


	function handleRightDateChange() {
		var newStartDate = moment(startDate, 'YYYY-MM-DD').add(1, 'M')
		var newEndDate = moment(endDate, 'YYYY-MM-DD').add(1, 'M')
		setStartDate(newStartDate.format('YYYY-MM-DD'))
		setEndDate(newEndDate.format('YYYY-MM-DD'))
		setShowTable(false)
	}

  	function removeObjectById(arr, id) {
  		const index = arr.findIndex(obj => obj.accessor === id)
  		if (index !== -1) {
  			arr.splice(index, 1)
  		}
  		return arr
  	}


  	useEffect(() => {
  		console.log("Show Env, sHow module")
  		setShowTable(false)
  		var newColumns = []
  		newColumns = columns
  		if(!showEnv) {
  			newColumns = removeObjectById(columns, 'db_env')
  		}
  		if(!showModule) {
  			newColumns = removeObjectById(newColumns, 'db_module')
  		}
  		console.log("new columns are")
  		console.log(newColumns)
  		setTableColumns(newColumns)
  		getSavingsReport()
  	}, [showEnv, showModule])

	function getSavingsReport() {
  		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	    fetch(BASE_URL + "/v1/cost_summary_report?start_date=" + startDate
	      		+ "&end_date=" + endDate + "&show_env=" + showEnv + "&show_module=" + showModule, {
	      method : 'GET',
	      headers : {
	        'x-api-key' : apikey,
	        'oauth-token' : oauth_token
	      }
	    }).then(res => res.json())
	    .then((data) => {
	      const result = data.message
	      const nodes = result
	      console.log(nodes)
	      setData(nodes)
	      setShowTable(true)
	      setLoadData(true)
	    })
  	}

  	function onEnvChange(checked){
  		setShowEnv(checked)
  	}

  	function onModuleChange(checked){
  		setShowModule(checked)
  	}


	function handleDateChange(value, dateString) {
		setStartDate(dateString[0])
		setEndDate(dateString[1])
		setShowTable(false)
	}

	useEffect(() => {
		console.log("Inside start date")
		getSavingsReport()
	}, [startDate, endDate])

	return(
			<div className="App-body-CSum-Outer-Container">
				{loadData ?
					<div className="App-body-CSum-Container">
						<div className="App-body-CSum-Header-Container">
							<div className="App-body-CSum-Header-Title-Container">
								<div className="App-body-CSum-Header-Title">
									<div className="App-body-CSum-Header-Title-Hamburger">
										<img src={HamburgerIcon} />
									</div>
									<div className="App-body-CSum-Header-Title-Name">
										Cost Summary 
									</div>
								</div>
							</div>
							<div className="App-body-CSum-Header-Date-Container">
								<div className="App-body-CSum-Header-Date">
									<div style={{'cursor':'pointer'}} onClick={handleLeftDateChange} > <img src={LeftChevron} /> </div>
										<RangePicker  onChange={handleDateChange}  value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]} />
									<div style={{'cursor':'pointer'}} onClick={handleRightDateChange} > <img src={RightChevron} /> </div>
								</div>
							</div>
						</div>
						<div className="App-body-CSum-Body-Container">
							<div className="App-body-CSum-Body-Outer-Container">
								<div className="App-body-CSum-Body-Table">
									<div className="App-body-CSum-Body-Table-Header">
										<div className="App-body-CSum-Body-Table-Header-Title">
											<div className='App-body-CSum-Body-Table-Header-Data'>
												Cost Summary Report
											</div>
										</div>
									</div>
									<div className="App-body-CSum-Filter">
										<div className="App-body-CSum-Filter-Toggles">
											<div className="App-body-CSum-Filter-Toggle">
												<Switch defaultChecked onChange={onEnvChange} />
												<div className="App-body-CSum-Filter-Toggle-Label">
													Show Environments
												</div>
											</div>
											<div className="App-body-CSum-Filter-Toggle">
												<Switch defaultChecked onChange={onModuleChange} />
												<div className="App-body-CSum-Filter-Toggle-Label">
													Show Modules
												</div>
											</div>
										</div>
									</div>
									<div className="App-body-CSum-Body-Table-Body">
										<div className="App-CSum-table-container-table">
		    								
		    									{showTable ?
									    		
									    			
									    				
									    					<NewTable data={data} columns={tableColumns} loading={!showTable} hiddenColumns={hiddenColumns}
													          sortBy={sortBy} getRowProps={row => ({
													          style: {
													            cursor: "pointer",
													            height : "10px",
													            borderLeft : "1px solid #4285F4"
													          }
													        })} />
									    				
		    		 						: 
		    		 							<Loader />
		    								}
		    								
										</div>
									</div>
								</div>
								<div>
								</div>
							</div>
						</div>
					</div>
				: 
				<Loader />
			}
		</div>
		)

}

export default DatabaseCostSavingsReport