import React, {useState, useEffect, Fragment} from 'react'
import { 
	useTable, 
	useBlockLayout, 
	useResizeColumns,
  	useSortBy,
  	useExpanded,
  	usePagination,
    useRowSelect
  } from 'react-table'
import Loader from './Loader'
import './assets/newtable.css'
import {Input} from 'reactstrap'
import { Button } from 'antd';
import BurgerMenu from '../activities/BurgerMenu'
import SettingsIcon from './assets/settings.svg'



function NewTable( { columns, data, loading, hiddenColumns, getRowProps, stickyTable, showMenu, menu, handleMenu, sortBy, allowMultiSelect, handleMultiSelect, handlePagination} ) {

  const [tableHeaderClassName, setTableHeaderClassName] = useState('App-table-header')
  const [tableRowClassName, setTableRowClassName] = useState('App-table-row')

  const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)


  const [showFooter, setShowFooter] = useState(true)

  const [defaultSortBy, setDefaultSortBy] = useState([])

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      maxWidth: 400,
      display : 'flex',
    }),
    []
  )

  useEffect(() => {
    if(stickyTable) {
      setTableHeaderClassName('App-table-header-sticky')
      setTableRowClassName('App-table-row-sticky')
    }
    if (sortBy ) {
      setDefaultSortBy(sortBy)
    }
  }, [])


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    resetResizing,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { 
        pageIndex: 0, 
        pageSize: 20, 
        hiddenColumns : hiddenColumns ,
        sortBy : defaultSortBy
      },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.columns.push(

        function(columns) { 
          if (allowMultiSelect) {
            return ([
               {
                  id: 'selection',
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    </div>
                  ),
                  width : 20,
                  Cell: ({ row }) => (
                    <div>
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                  ),
                },
              ...columns,
            ])
         } else {
          return (columns)
         }
      })
    }
  )

   useEffect(() => {
    if (allowMultiSelect) {
      handleMultiSelect(selectedFlatRows)
    }
}, [selectedFlatRows]);

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
    if (handlePagination) {
      handlePagination(Number(event.target.value))
    }
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  useEffect(() => {
    if ( rows.length > 10) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [page])

  return (
      <div className="App-table-container-body">
      {loading ? 
      		<Loader /> 
      		:
		      	<div className="App-table-container-body-table">
		      		<Fragment >
			        <div {...getTableProps()} className="new-table">
			          <div className={tableHeaderClassName}>
			            {headerGroups.map(headerGroup => (
			              <div {...headerGroup.getHeaderGroupProps()} className="tr">
			                {headerGroup.headers.map(column => (
			                  <div {...column.getHeaderProps()}  className="th">
			                  <div {...column.getSortByToggleProps()} className="th-content">
			                    {column.render('Header')}
			                    </div>
			                    {/* Use column.getResizerProps to hook up the events correctly */}
			                    <div
			                      {...column.getResizerProps()}
			                      className={`resizer ${
			                        column.isResizing ? 'isResizing' : ''
			                      }`}
			                    />
			                  </div>
			                ))}
                      {showMenu && <div className="App-table-col-header-sticky"><img src={SettingsIcon} /></div> }
			              </div>
			            ))}
			          </div>
			          <div {...getTableBodyProps()}>
			            {page.length > 0  && page.map((row, i) => {
			              prepareRow(row)
			              return (
                      <div className={tableRowClassName}>
			                <div {...row.getRowProps()} className="tr">
			                  {row.cells.map(cell => {
			                    return (
			                      <div {...cell.getCellProps()} className="App-table-row-cell td">
                              <div className="th-content">
			                         {cell.render('Cell')}
			                        </div>
                              <div className='resizer'>
                              </div>
                            </div>
			                    )
			                  })}
                        {showMenu && <div className="App-table-col-sticky"><BurgerMenu data={row.original} menu={menu} handleMenu={handleMenu} /></div> }
			                </div>
                      </div>
			              )
			            }) || <span>no data ....</span>
                }
			          </div>
		          </div>
		          	</Fragment>
		        </div>
    	}
      {showFooter ?
    	<div>
        <Fragment>
          <div className="App-table-container-footer">
            <div className="App-table-container-footer-page-size">
              <div className="App-table-container-footer-page-size-dropdown">
                <Input
                  type='select'
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  &gt;
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Input>          
              </div>
              <div className="App-table-container-footer-page-size-label">
                <div>
                Results Per Page
                </div>
              </div>
            </div>
            <div className="App-table-container-footer-page-link">
              <div className="App-table-container-footer-page-link-container">
                <div className="App-table-container-footer-page-link-button-fprev">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {'<<'}
                  </Button>
                </div>
                <div className="App-table-container-footer-page-link-button-prev">
                  <Button
                    color='primary'
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    {'<'}
                  </Button>
                </div>
            
                <div className="App-table-container-footer-page-link-detail" style={{ marginTop: 7 }}>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </div>

                <div className="App-table-container-footer-page-link-button-next">
                  <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                    {'>'}
                  </Button>
                </div>

                <div className="App-table-container-footer-page-link-button-fnext">
                  <Button
                    color='primary'
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {'>>'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
      : <div></div> }
      </div>
  )
}

export default NewTable