import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../common/icon'
import './assets/lefttab.css';


function LeftTab(props) {

  const [developerRole, setDeveloperRole] = useState(false)

  useEffect(() => {
    var db_role = localStorage.getItem('db_portal_user_role')
    if (db_role == 'developer') {
      setDeveloperRole(true)
    }
  }, [])

  return (
    <div className="App-body-block-left-tab-container">
      <div className="App-body-block-left-tab-icon-container">
        {!developerRole && <Icon id="DI" name="Database Inventory" handleClick={props.handleTabSelection}/> }
        {!developerRole && <Icon id="DC" name="Database Costs" handleClick={props.handleTabSelection}/> }
        {!developerRole && <Icon id="DA" name="Database Activities" handleClick={props.handleTabSelection}/> }
      </div>
      <div className="App-body-block-left-tab-avatar-container">
      </div>
    </div>
  );
}

LeftTab.propTypes = {
    handleTabSelection: PropTypes.func
};

export default LeftTab;
