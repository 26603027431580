import { Dropdown } from "react-bootstrap";
import OverFlowIcon from './assets/overflow-icon.svg'
import CustomDivToggle from "./CustomDivToggle";
import { BsThreeDots } from "react-icons/bs";


export const BurgerMenu = (props) => {

  function handleClick(e) {
    console.log(e.item)
    props.handleMenu(e.item, props.data)
  }

  return (
    <Dropdown >
      <Dropdown.Toggle as={CustomDivToggle} style={{ cursor: "pointer" }}>
        <BsThreeDots />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.menu.map((item) => {
          return (
              <Dropdown.Item onClick={() => handleClick({item})}>{item}</Dropdown.Item>
            )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BurgerMenu