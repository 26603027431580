import React, { useState, useEffect, useMemo } from 'react';
import './assets/createActivity.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateActivity(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { TextArea } = Input;
	
	const [endPoint, setEndPoint] = useState('')

	const [activityType, setActivityType] = useState('')

	const [buttonDisable, setButtonDisable] = useState(true)

	const [comments, setComments] = useState('')

	const [fieldChanged, setFieldChanged] = useState('')

	const [oldFieldContent, setOldFieldContent] = useState('')

	const [newFieldContent, setNewFieldContent] = useState('')

	const [jira, setJira] = useState('')

	const [costEffectSystem, setCostEffectSystem] = useState('')

	const [costEffectManual, setCostEffectManual] = useState('')

	const [POC,setPOC] = useState('')

	const [dateChange, setDateChange] = useState('')

	const [nodeName, setNodeName] = useState('')

	const [invUUid, setInvUUid] = useState('')

	const activityTypes = [
		{
			value: 'Upscale',
        	label: 'Upscale'
		}, 
		{
			value: 'Downscale',
          	label: 'Downscale'
		}, 
		{
			value: 'Removal',
          	label: 'Removal'
		}, 
		{
			value: 'Addition',
          	label: 'Addition'
		},
		{
			value: 'Cost saving',
			label: 'Cost saving'
		}]

	const dbFields = [
		{
			value : 'DB_Node_Class',
			label : 'DB_Node_Class'
		},
		{
			value : 'DB_Storage',
			label : 'DB_Storage'
		},
		{
			value : 'DB_Backup_Retention_Period',
			label : 'DB_Backup_Retention_Period'
		},
		{
			value : 'Provisioned_IOPS',
			label : 'Provisioned_IOPS'
		},
		{
			value : 'Backup_Snapshots',
			label : 'Backup_Snapshots'
		},
		{
			value : 'Removed',
			label : 'Removed'
		}]

	function handleClose() {
		props.handleClose()
	}

	function handleEndPoint(e) {
		setEndPoint(e.target.value)
	}

	function handleOldFieldContent(e) {
		setOldFieldContent(e.target.value)
	}

	function handleNewFieldContent(e) {
		setNewFieldContent(e.target.value)
	}

	function handleActitvityType(value) {
		setActivityType(value)
	}

	function handleFieldChanged(value) {
		setFieldChanged(value)
	}

	function handleJira(e) {
		setJira(e.target.value)
	}

	function handleCostEffectSystem(e) {
		setCostEffectSystem(e.target.value)
	}

	function handleCostEffectManual(e) {
		setCostEffectManual(e.target.value)
	}

	function handlePOC(e) {
		setPOC(e.target.value)
	}

	function handleNodeName(e) {
		setNodeName(e.target.value)
	}

	function handleDateChange(value, dateString) {
		setDateChange(dateString)
	}

	function handleComments(e) {
		setComments(e.target.value)
	}

	function handleInvUniqID(e) {
		setInvUUid(e.target.value)
	}

	useEffect(() => {
		if ( endPoint != '' && 
				activityType != '' && 
				fieldChanged != '' && 
				oldFieldContent != '' && 
				newFieldContent != '' && 
				costEffectManual != '' &&
				dateChange != '') {

			setButtonDisable(false)
		} else {

			setButtonDisable(true)

		}

	}, [endPoint, activityType, fieldChanged, oldFieldContent, newFieldContent, costEffectManual, dateChange])

	function handleCreateActivity() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
   		setButtonDisable(true)
		fetch( BASE_URL + '/v1/activity' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            		'db_endpoint' : endPoint,
	            		'db_activity_type' : activityType,
	            		'db_field_changed' : fieldChanged,
	            		'db_old_field_content' : oldFieldContent,
	            		'db_new_field_content': newFieldContent,
	            		'db_jira': jira,
	            		'db_comments': comments,
	            		'db_cost_effect_system': costEffectSystem,
	            		'db_cost_effect_manual': costEffectManual,
	            		'db_poc' : POC,
	            		'db_activity_time': dateChange,
	            		'db_status' : 'needs_review',
	            		'db_node_name' : nodeName,
	            		'db_inv_uuid' : invUUid,
	        		}
	        	)
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			setButtonDisable(false)
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-DA-AC-create-node-container">
				<div className="App-DA-AC-create-node-modal-content">
					<div className="App-DA-AC-create-node-modal-header">
		 				<div className="App-DA-AC-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DA-AC-create-node-modal-header-content"> Create Activity </div>
					</div>
					<div className="App-DA-AC-create-node-modal-body">
						<div className="App-DA-AC-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<div className="inline">
      							<Form.Item label="Inventory Unique Id *" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleInvUniqID} />
      							</Form.Item>
      							<Form.Item label="Node name *">
      							    <Input placeholder="Type Here" onChange={handleNodeName} />
      							</Form.Item>
      						</div> 
      						<div className="inline">
      							<Form.Item label="EndPoint *" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleEndPoint} />
      							</Form.Item>
      							<Form.Item label="Activity Type *">
      								<Select onChange={handleActitvityType} options={activityTypes} />
      							</Form.Item>
      						</div> 
      						<div className="inline-3">
      							<Form.Item label="Field Changed *" style={{marginRight:'auto'}}>
      								<Select onChange={handleFieldChanged} options={dbFields} />
      							</Form.Item>
      							<Form.Item label="Old Field Content *" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handleOldFieldContent} />
      							</Form.Item>
      							<Form.Item label="New Field Content *">
        							<Input placeholder="Type Here" onChange={handleNewFieldContent} />
      							</Form.Item>
      						</div>
      						<div className="fullWidth">
	      						<Form.Item label="Jira">
	        						<Input placeholder="Type Here" onChange={handleJira} />
	      						</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="Cost Effect - System Generated" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" disabled onChange={handleCostEffectSystem} />
      							</Form.Item>
      							<Form.Item label="Cost Effect - Manual Estimated *">
        							<Input placeholder="Type Here" onChange={handleCostEffectManual} />
      							</Form.Item>
      						</div>
      						<div className="inline">
      							<Form.Item label="POC" style={{marginRight:'auto'}}>
        							<Input placeholder="Type Here" onChange={handlePOC} />
      							</Form.Item>
      							<Form.Item label="Activity Date-time *">
        							<DatePicker showTime style={{display:'flex'}} onChange={handleDateChange} />
      							</Form.Item>
      						</div>	
      						<div className="fullWidth">
						        <Form.Item label="Comments" style={{marginBottom : 'auto'}}>
						          <TextArea rows={4} placeholder="Type Here" onChange={handleComments} />
						        </Form.Item>
					        </div>
      					</Form>
      					</div>
					</div>
					<div className="App-DA-AC-create-node-modal-footer">
						<div className="App-DA-AC-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateActivity()}
	              				disabled={buttonDisable}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateActivity
