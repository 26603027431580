import React, { useState, useEffect,  useMemo, useRef} from 'react';
import './assets/releaseJiras.css';
import LeftChevron from './assets/chevron-left.svg';
import  {Input} from 'antd'
import NewTable from '../common/NewTable'
import Loader from '../common/Loader'
import PopupIcon from './assets/popup-icon-16.png'
import QueryDisplayer from '../slowQuery/QueryDisplayer'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'
import * as XLSX from 'xlsx';

function ReleaseJiras(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const searchRef = useRef(null);

	const { Search } = Input;

	const [showLoading, setShowLoading] = useState(true)

	const [originalData, setOriginalData] = useState([])

	const [searchValue, setSearchValue] = useState('')

	const [slowQueryData, setSlowQueryData] = useState([])

	const [totalCount, setTotalCount] = useState(0)

	const [showTable, setShowTable] = useState(false)

	const [selectedDBType, setSelectedDBType] = useState('Mysql')

	const [loadData, setLoadData] = useState(false)

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [showSearch, setShowSearch] = useState(false)

	const [tableColumns, setTableColumns] = useState([])

	const [selectedQuery, setSelectedQuery] = useState('')

	const [showQuery, setShowQuery] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const mongoColumns = useMemo(
		() => [
			{
				Header : "DB Name",
				accessor : "db_name",
				width : 300
			},
			{
				Header : "Status",
				accessor : "status",
				width : 200
			},
			{
				Header : "Query",
				accessor : "query",
				Cell : row => (
						<div className="App-SQ-table-query" onClick={e=>handleQueryClick(row.row.original)}>
              				<img src={PopupIcon} />
           				 </div>
					),
			},
			{
				Header : "Comments",
				accessor : "comments",
				width : 900
			},
			{
				Header : "Start Time",
				accessor : "start_time",
				width : 200
			},
			{
				Header : "Execution Time (sec)",
				accessor : "execution_time",
				width : 100
			},
			{
				Header : "Cluster Name",
				accessor : "cluster_name",
				width : 300
			}
		], [])


	function handleClose() {
		props.handleClose()
	}

	function handleQueryClose() {
		setSelectedQuery('')
		setShowQuery(false)
	}

	useEffect(() => {
		setShowLoading(true)
		setTableColumns(mongoColumns)
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')

	   	 const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/release/releaseStatusData?release_name=" + props.releaseName + "&jira=" + props.data.jira_name +"&status=" + props.releaseStatus
  		      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setOriginalData(result['release_status'])
		    setSlowQueryData(result['release_status'])
		    setTotalCount(result['release_status'].length)
		    setShowTable(true)
		    setLoadData(false)
		}
		doFetch()

	}, [])

	function handleQueryClick(example_query) {
		console.log("example query is")
		console.log(example_query)
		example_query['example_query'] = example_query['query']
		setSelectedQuery(example_query)
		setShowQuery(true)
	}

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.db_name &&  value.db_name.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.cluster_name && value.cluster_name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

  	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedQuery = []
		combinedQuery.push({'title' : 'Release Jiras', jsonData : slowQueryData})
		convertJSONsToXLSX(combinedQuery)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "release_jiras.xlsx");
    };

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}


	return (
			<div className="App-RJ-create-node-container">
				<div className="App-RJ-create-node-modal-content">
					<div className="App-RJ-create-node-modal-header">
		 				<div className="App-RJ-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-RJ-create-node-modal-header-content"> 
 							Release Jiras
 						</div>
					</div>
					<div className="App-RJ-create-node-modal-body">
						<div className="App-RJ-create-node-modal-body">
							<div className="App-RJ-table-container-search-box">
								<div className="App-body-RJ-header-export">
									<img src={ExportIcon} onClick={handleExportClick} />
								</div>
								{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
	    						{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    						</div>
							{showTable ?
								<div className="App-RS-tab-body-table-container">
									<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-RS-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
						{showQuery ? <QueryDisplayer query={selectedQuery} db_type={selectedDBType} handleClose={handleQueryClose} /> : <div></div>}

					</div>
				</div>
			</div>
		)

}

export default ReleaseJiras
