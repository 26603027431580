import React, { useState, useEffect, useMemo } from 'react';
import './assets/slowQuery.css';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import PopupIcon from './assets/popup-icon-16.png'
import moment from 'moment';
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import NewTable from '../common/NewTable'
import Loader from '../common/Loader'
import QueryDisplayer from './QueryDisplayer'
import pako from 'pako';


const { RangePicker } = DatePicker;

function SlowQuery(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const [loadData, setLoadData] = useState(false)

	const [showForm, setShowForm] = useState(false)

	const [showLoading, setShowLoading] = useState(true)

	const [disableModule, setDisableModule] = useState(true)

	const [disableCluster, setDisableCluster] = useState(true)

	const [mongoFilters, setMongoFilters] = useState({})

	const [mysqlFilters, setMysqlFilters] = useState({})

	const [filters, setFilters] = useState({})

	const [showTable, setShowTable] = useState(false)

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [dbTypeOptions, setDbTypeOptions] = useState([
		 {
		 	value : "Mysql",
		 	label : "Mysql"
		 },
		 {
		 	value : "Mongo",
		 	label : "Mongo"
		 }
		])

	const [selectedDBType, setSelectedDBType] = useState('Mysql')

	const [productOptions, setProductOptions] = useState([])

	const [selectedProduct, setSelectedProduct] = useState('')

	const [moduleOptions, setModuleOptions] = useState([])

	const [selectedModule, setSelectedModule] = useState('')

	const [clusterOptions, setClusterOptions] = useState([])

	const [selectedCluster, setSelectedCluster] = useState('')

	const [selectedQuery, setSelectedQuery] = useState('')

	const [showQuery, setShowQuery] = useState(false)

	// Get current date and time
	const currentDate = new Date();
	const currentTime = currentDate.toLocaleTimeString();

	// Calculate time one hour ago
	const oneHourAgo = new Date(currentDate.getTime() - 60 * 60 * 1000);
	const oneHourAgoTime = oneHourAgo.toLocaleTimeString();
	const oneHourAgoDate = oneHourAgo.toLocaleDateString("en-GB");

	// Format today's date
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const todayDate = currentDate.toLocaleDateString("en-GB", options);

	// Output the results
	console.log('Current date:', convertDateString(todayDate));
	console.log('Current time:', currentTime);
	console.log('One hour ago:', convertDateString(oneHourAgoDate), oneHourAgoTime);


	const [startDate, setStartDate] = useState(convertDateString(oneHourAgoDate))

	const [startDateString, setStartDateString] = useState(convertDateString(oneHourAgoDate) + " " + oneHourAgoTime)

	const [endDate, setEndDate] = useState(convertDateString(todayDate))
	const [startTime, setStartTime] = useState(oneHourAgoTime)

	const [endDateString, setEndDateString] = useState(convertDateString(todayDate) + " " + currentTime)

	const [endTime, setEndTime] = useState(currentTime)

	const [tableColumns, setTableColumns] = useState([])

	const mysqlColumns = useMemo(
		() => [
			{
				Header : "DB",
				accessor : "db"
			},
			{
				Header : "Avg Query Time",
				accessor : "query_time_avg",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.query_time_avg}
						</div>
					)
			},
			{
				Header : "Avg Lock Time",
				accessor : "lock_time_avg",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.lock_time_avg}
						</div>
					)
			},
			{
				Header : "Avg Rows Examined",
				accessor : "rows_examined_avg",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.rows_examined_avg}
						</div>
					)
			},
			{
				Header : "Avg Rows Returned",
				accessor : "rows_sent_avg",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.rows_sent_avg}
						</div>
					)
			},
			{
				Header : "Operation",
				accessor : "",
				Cell : row => (
						<div>
							{row.row.original.distillate.split(' ')[0]}
						</div>
					)
			},
			{
				Header : "Distillate",
				accessor : "distillate",
				Cell : row => (
						<div>
							{row.row.original.distillate.substr(row.row.original.distillate.indexOf(" ") + 1)}
						</div>
					)
			},
			{
				Header : "Example Query",
				accessor : "example_query",
				Cell : row => (
						<div className="App-SQ-table-query" onClick={e=>handleQueryClick(row.row.original)}>
              				{row.row.original.example_query}
           				 </div>
					),
				width : 300
			},
			{
				Header : "Example Query Time",
				accessor : "example_query_time",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.example_query_time}
						</div>
				)
			},
			{
				Header : "Cluster",
				accessor : "cluster_name"
			},
			{

				Header : "Count",
				accessor : "query_count",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.query_count}
						</div>
				)
			},
		], [])

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Database",
				accessor : "db_name"
			},		
			{
				Header : "Collection",
				accessor : "collection_name"
			},
			{
				Header : "Operation",
				accessor : "operation"
			},		
			{
				Header : "Pattern",
				accessor : "pattern"
			},
			{
				Header : "Actual Query",
				accessor : "",
				Cell : row => (
						<div className="App-SQ-table-query" onClick={e=>handleQueryClick(row.row.original)}>
              				<img src={PopupIcon} />
           				 </div>
					),
				width : 300
			},	
			{
				Header  : "AppName",
				accessor : "appName"
			},		
			{

				Header : "Count",
				accessor : "sum(count)"
			},
			{
				Header : "Total Time",
				accessor : "total_time"
			},
			{
				Header : "Max",
				accessor : "max(max)"
			},
			{
				Header : "Average",
				accessor : "avg(mean)"
			},
			{
				Header : "Plan Summary Aggregated",
				accessor : "plan_summary_aggregated"
			},
			{
				Header : "Sort Used",
				accessor : "sort_used"
			},
			{
				Header : "Limits",
				accessor : "limits"
			},
			{
				Header : "Has Sort Stage",
				accessor : "has_sort_stage"
			},
			{
				Header : "Average Docs Examined",
				accessor : "avg(docsExamined)"
			},

			{
				Header : "Average Docs Returned",
				accessor : "avg(docsReturned)"
			},
			{
				Header : "Average Keys Examined",
				accessor : "avg(keysExamined)"
			},
			{
				Header : "Average Time Waiting",
				accessor : "avg(timeWaiting)"
			}
		], [])

	const [hiddenColumns, setHiddenColumns] = useState([])


	function convertDateString(dateString) {
		  var dateParts = dateString.split('/');
		  var month = dateParts[0];
		  var day = dateParts[1];
		  var year = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + month + '-' + day;
	}

	function convertDBDateString(dateString) {
		  var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}


	function convertRangeDateString(dateString) {
		console.log(" Date string is ")
		console.log(dateString)
		 var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}

	function handleQueryClick(example_query) {
		console.log("example query is")
		console.log(example_query)
		setSelectedQuery(example_query)
		setShowQuery(true)
	}

	function handleQueryClose() {
		setSelectedQuery('')
		setShowQuery(false)
	}


	function handleReset() {
		setSelectedDBType('Mysql')
		setSelectedProduct([])
		setSelectedModule([])
		setSelectedCluster([])
		setDisableModule(true)
		setDisableCluster(true)
		setSelectedProduct('')
		setFilters(mysqlFilters)
		setShowReportDisable(true)
		var products = Object.keys(mysqlFilters)
		console.log(constructFilterArray(products))
		setProductOptions(constructFilterArray(products))
		setSlowQueryData([])
	}

	function handleStartDate(value, dateString) {
		console.log("Start Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		console.log("Converted date is ")
		console.log(convertRangeDateString(date))
		setStartDate(convertRangeDateString(date))
		setStartTime(time)
		setStartDateString(convertRangeDateString(date) + " " + time)
	}

	function handleStartTime(value, timeString) {
		console.log("Start Time Change")
		console.log(value, timeString)
		setStartTime(timeString)
	}

	function handleEndDate(value, dateString) {
		console.log("End Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		setEndDate(convertRangeDateString(date))
		setEndTime(time)
		setEndDateString(convertRangeDateString(date) + " " + time)
	}

	function handleEndTime(value, timeString) {
		console.log(" End Time Change ")
		console.log(value, timeString)
		setEndTime(timeString)
	}

	function handleButtonClick(){
		console.log("Button Click is")
		console.log("Loaad Data is")
		console.log(loadData)
		if (selectedDBType == 'Mysql' ) {
			setTableColumns(mysqlColumns)
		} else if ( selectedDBType == "Mongo") {
			setTableColumns(mongoColumns)
		}
		if (!loadData) {
			setSlowQueryData([])
			setShowTable(false)
			setLoadData(true)
		}
	}


	function populateDefaultFilter() {
		populateMongoFilter()
		populateMysqlFilter()
	}

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
			if (selectedDBType == 'Mysql') {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var products = Object.keys(mysqlFilters)
				console.log(constructFilterArray(products))
				setProductOptions(constructFilterArray(products))
				setTableColumns(mysqlColumns)
				setShowTable(true)
			}
		}

	}, [showForm])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}


	function populateMongoFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/slowqueries/getfilters?db_type=mongodb"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMongoFilters(result['mongodb'])
		}
		doFetch()
	}


	function populateMysqlFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/slowqueries/getfilters?db_type=mysql"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMysqlFilters(result['mysql'])
		    setShowForm(true)
		}
		doFetch()
	}


	function handleDBTypeChange(fieldLabel, fieldValue){
		console.log("Field Label")
		console.log(fieldLabel)
		console.log("Field Value")
		console.log(fieldValue)
		setSelectedDBType(fieldLabel)
		console.log("Mysql filters")
		console.log(mysqlFilters)
		console.log("Mongo Filters")
		console.log(mongoFilters)
		if ( fieldLabel == 'Mysql' ) {
			setFilters(mysqlFilters)
			var products = Object.keys(mysqlFilters)
			setProductOptions(constructFilterArray(products))
			setSelectedProduct('')
			setSelectedModule('')
			setSelectedCluster('')
		} else if ( fieldLabel == 'Mongo' ) {
			setFilters(mongoFilters)
			var products = Object.keys(mongoFilters)
			setProductOptions(constructFilterArray(products))
			setSelectedProduct('')
			setSelectedModule('')
			setSelectedCluster('')
		}
	}

	function handleProductChange(fieldLabel, fieldValue) {
		setSelectedModule('')
		setSelectedProduct(fieldLabel)
		var modules = Object.keys(filters[fieldLabel])
		setModuleOptions(constructFilterArray(modules))
		setDisableModule(false)
	}

	function handleModuleChange(fieldLabel, fieldValue) {
		setSelectedModule(fieldLabel)
		var clusters = filters[selectedProduct][fieldLabel]
		setClusterOptions(constructFilterArray(clusters))
		setDisableCluster(false)
	}

	function handleClusterChange(fieldLabel, fieldValue) {
		setSelectedCluster(fieldLabel)
		setShowReportDisable(false)
	}

	useEffect(() => {
		
		if (loadData) {
			setShowLoading(true)
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   		var dbStartTime = convertDBDateString(startDate) + " " + startTime
	   		var dbEndTime = convertDBDateString(endDate) + " " + endTime
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/slowqueries?db_type=" + selectedDBType +"&cluster_name=" + selectedCluster +"&startTime="
		      	+ dbStartTime + "&endTime=" + dbEndTime
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setSlowQueryData(result)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	 const decodeAndDecompressResponse = async (encodedData) => {
	    try {
	      	// Decompress gzipped data using pako library
	      	const decompressedData = pako.inflate(encodedData, { to: 'string' });
	      	return decompressedData;
    	} catch (error) {
      		console.error('Error decoding and decompressing response:', error);
      		return null;
    	}
  	};


	useEffect(() => {
		populateDefaultFilter()
	}, [])
	
	return(
			<div className="App-SQ-tab-container">
				<div className="App-body-SQ-Header-Container">
					<div className="App-body-SQ-Header-Title-Container">
						<div className="App-body-SQ-Header-Title">
							<div className="App-body-SQ-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-SQ-Header-Title-Name">
								Slow Queries Report 
							</div>
						</div>
					</div>
				</div>
				{ showForm && showTable ? 
				<div className="App-SQ-tab-body">
					<div className="App-SQ-tab-body-filter-container">
						<div className="App-SQ-tab-form">
						{showForm ? 
							<div className="App-SQ-tab-filter-form">
								<Form
		      						form={form}
		      						layout="vertical"
		      					>
		      						<div className="SQ-inline">
		      							<Form.Item label="DB Type" style={{ margin:'auto 5px'}}>
		      								<Select options={dbTypeOptions} value={selectedDBType} onChange={handleDBTypeChange} />
		      							</Form.Item>
		      							<Form.Item label="Product" style={{ margin:'auto 5px'}}>
		        							<Select options={productOptions} value={selectedProduct} onChange={handleProductChange} />
		      							</Form.Item>
		      							<Form.Item label="Module" style={{margin:'auto 5px'}}>
		      								<Select options={moduleOptions} disabled={disableModule} value={selectedModule} onChange={handleModuleChange} />
		      							</Form.Item>
		      							<Form.Item label="Cluster" style={{margin:'auto 5px'}}>
		      								<Select options={clusterOptions} disabled={disableCluster} value={selectedCluster} onChange={handleClusterChange} />
		      							</Form.Item>
		      							<Form.Item label="From Date" style={{margin:'auto 5px'}}>
		        							<DatePicker showTime  style={{display:'flex'}} value={moment(startDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleStartDate} />
		      							</Form.Item>
		      							<Form.Item label="To Date" style={{margin:'auto 5px'}}>
		        							<DatePicker  showTime style={{display:'flex'}} value={moment(endDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleEndDate} />
		      							</Form.Item>
		      							<Button
		      							 	disabled={showReportDisable}
				            				type='primary'
				            				onClick={handleButtonClick}
				            				style={{margin : 'auto 5px', marginBottom : '0px'}}
				              			>
		              						Show Report
		            					</Button>
		            					<Button
		            						onClick={handleReset}
		            						style={{margin : 'auto 5px', marginBottom : '0px'}}
		            					>
		            						Reset
		            					</Button>
		      						</div>
		      					</Form>
	      					</div>
	      				: 
	      					<div className="App-SQ-tab-form-loader">
	      						<Loader />
	      					</div>
	      					}
      					</div>
					</div>
					{showTable ?
						<div className="App-SQ-tab-body-table-container">
							<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
						</div>
					: 
						<div className="App-SQ-tab-body-table-loader">
							<Loader />
						</div> 
					}
				</div>
					:
					<div className="App-SQ-tab-form-loader">
						<Loader />
					</div>
				}
				{showQuery ? <QueryDisplayer query={selectedQuery} db_type={selectedDBType} handleClose={handleQueryClose} /> : <div></div>}
			</div>
		)

}

export default SlowQuery