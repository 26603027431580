import React, { useState, useEffect } from 'react';
import './assets/auditLogs.css';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import { Tabs } from 'antd';
import MysqLAuditLog from './mysqlAuditLogs'
import MongoAuditLog from './mongoAuditLogs'
import PostgresAuditLog from './postgresAuditLogs'


function AuditLog(props) {


	const onChange = (key) => {
  		console.log(key);
	};

	const tableHeaderStyle = {
		'backgroundColor' : '#f4f4f4',
		'fontFamily' : 'Proxima Nova' ,
		'fontWeight' : 400
	}

	const items = [
	  {
	    key: '1',
	    label: 'Mongo',
	    children: <div className="App-AL-tab-body-tab-conatiner"> <MongoAuditLog /> </div>,
	  },
	  {
	    key: '2',
	    label: 'Mysql',
	    children: <div className="App-AL-tab-body-tab-conatiner"> <MysqLAuditLog /> </div>,
	  },
	];


	return(
			<div className="App-AL-tab-container">
				<div className="App-body-AL-Header-Container">
					<div className="App-body-AL-Header-Title-Container">
						<div className="App-body-AL-Header-Title">
							<div className="App-body-AL-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-AL-Header-Title-Name">
								Audit Logs 
							</div>
						</div>
					</div>
				</div>
				<div className="App-AL-tab-body">
					<Tabs defaultActiveKey="1" size="large" items={items} tabBarStyle={tableHeaderStyle} />
				</div>
			</div>
		)

}

export default AuditLog