import React,  { useEffect, useState } from 'react'

function ReleaseFailureCell(props) {

	const [successCellCss, setSuccessCellCss] = useState('App-RS-Failure')


	useEffect(() => {
		console.log("Data")
		console.log(props.data)
		console.log(successCellCss)
		console.log(!props.data.Failed)
		if ( !props.data.Failed ) {
			setSuccessCellCss('App-RS-Cell')
		}
	
	}, [])


	function  handleClick() {
		props.handleClick(props.data)
	}
	
	return(
		<div className={successCellCss} onClick={handleClick} >
			{props.data.Failed}
		</div>
	)
}

export default ReleaseFailureCell
