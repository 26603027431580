import React from "react";
import './assets/miniloader.css'

const MiniLoader = (props) => {
  return (
      <div className="mini-bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
  )
};

export default MiniLoader;