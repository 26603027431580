import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import NewTable from '../common/NewTable'
import CreateNode from './createNode'
import { Collapse } from 'antd';
import './assets/databaseInventory.css'
import 'antd/dist/antd.css';
import SearchIcon from './assets/search.svg'
import SettingsIcon from './assets/settings.svg'
import OverFlowIcon from './assets/overflow-icon.svg'
import { Button, Radio, Input } from 'antd';
import Loader from '../common/Loader'
import MiniLoader from '../common/MiniLoader'
import Filter from '../common/filter'
import NewFilter from '../common/newFilter'
import EditNode from './editNode'
import EditCluster from './editCluster'
import ClusterPanelHeader from './clusterPanelHeader'
import Popup from '../common/popup'


function NewDatabaseInventory(props) {

	const [showPopup, setShowPopup] = useState(false)

	const [popupDataType, setPopupDataType] = useState('')

	const [popupTitle, setPopupTitle] = useState('')

	const [popupDetails , setPopupDetails] = useState([])

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const { Panel } = Collapse;

	const searchRef = useRef(null);

	const [showClusterEdit, setShowClusterEdit] = useState(false)

	const [showLoader, setShowLoader] = useState(true)

	const [data, setData] = useState([])

	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [currNode, setCurrNode] = useState({})

	const [showEdit, setShowEdit] = useState(false)

	const { Search } = Input;

	const [filterDefaultValues, setFilterDefaultValues] = useState({})

  const [showLoading, setShowLoading] = useState(true)

  const [showFilter, setShowFilter] = useState(false)

  const [showCreate, setShowCreate] = useState(false)

  const [loadData, setLoadData] = useState(true)

  const [showAdd, setShowAdd] = useState(false)

  const [isFiltered, setIsFiltered] = useState(false)

  const [resultCount, setResultCount] = useState('')

  const [nodeCount, setNodeCount] = useState('')

  const [clusterCount, setClusterCount] = useState('')

  const [filterValues, setFilterValues] = useState([])

  const [selectedCluster, setSelectedCluster] = useState({})

  const [newFilterValues, setNewFilterValues] = useState([{"fieldValue" : "env", "selectedValue" : "prod"}])

  const onSearch = (input) => globalSearch(input);


  	useEffect(() => {
  		if(searchValue == '') {
  			setData(originalData)
  			setShowSearch(false)
  		}
  	}, [searchValue])

  	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.cluster_name.toLowerCase().includes(searchInput.toLowerCase()) 
	      		);
	    	});
	    	setData(filteredData);
			}
  	};

  	function handleReset() {
  		setFilterValues([])
  		setNewFilterValues([{"fieldValue" : "env", "selectedValue" : "prod"}])
  		if (isFiltered) {
  			setLoadData(true)
  		}
  	}

  	function handlePopupOpen(dataType, popupTitle, popupDetails) {
  		setPopupDataType(dataType)
  		setPopupTitle(popupTitle)
  		setPopupDetails(popupDetails)
  		if (popupDetails) {
  			setShowPopup(true)
  		}
  	}

  	function handlePopupClose() {
			setShowPopup(false)
		}

  	function handleFilter(selectedFilter) {
  			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    var queryString = constructQuery(selectedFilter)
		    setShowLoading(true)
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v2/inventory/filter" + queryString , {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      const count = result['count']
		      const sorted_nodes = nodes.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name))
		      setData(sorted_nodes)
		      setOriginalData(nodes)
		      setResultCount(count)
		      setNodeCount(count)
		      setClusterCount(nodes.length)
		      setIsFiltered(true)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
  	}

  	function handleNewFilter(filterValues) {
  		var apikey = localStorage.getItem('db_portal_app_apikey')
  		var oauth_token = localStorage.getItem('db_portal_oauth_token')
  		var selectedFilter = constructFilterMap(filterValues)
  		var queryString = constructQuery(selectedFilter)
		    setShowLoading(true)
		    setShowFilter(false)
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v2/inventory/filter" + queryString , {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      const count = result['count']
		      const sorted_nodes = nodes.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name))
		      setData(sorted_nodes)
		      setOriginalData(nodes)
		      setResultCount(count)
		      setNodeCount(count)
		      setClusterCount(nodes.length)
		      setNewFilterValues(filterValues)
		      setIsFiltered(true)
		      setShowFilter(true)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
  	}

  	function clearFilters() {
  		setFilterValues([])

  	}

  	function constructFilterMap(filterValues) {
  		var selectedFilter = {}
  		for ( var index in filterValues) {
  			var filterValue = filterValues[index]
  			if (filterValue.selectedValue != '') {
  					selectedFilter[filterValue.fieldValue] = filterValue.selectedValue
  			}
  		}
  		return selectedFilter
  	}


  	function constructQuery(selectedFilter) {
  		const queryString = '?' + Object.keys(selectedFilter).map(key => {
      			return `${key}=${encodeURIComponent(selectedFilter[key])}`;
   		}).join('&');
   		return queryString
  	}

  	function populateDefaultFilter() {
  		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
  		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/inventory/getfilters", {
		      	method : 'GET',
		      	headers : {
		        	'x-api-key' : apikey,
		        	'oauth-token' : oauth_token
		      	}
		    	})
	      const body = await response.json()
	      const result = body.message
	  		var engines = result['engines']
	  		var environments = result['environments']
	  		var modules = result['modules']
	  		var products = result['products']
	  		var defaultAvailableOptions = {}
	  		defaultAvailableOptions['product'] = constructFilterArray(products) 
	  		defaultAvailableOptions['module'] = constructFilterArray(modules)
	  		defaultAvailableOptions['env'] = constructFilterArray(environments)
	  		defaultAvailableOptions['engine'] = constructFilterArray(engines)
	  		setFilterDefaultValues(defaultAvailableOptions)
	  		setShowFilter(true)
  		}
  		doFetch()
  	}

  	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}


  	function handleEdit(row) {
  		setCurrNode(row)
  		setShowEdit(true)
  	}


  	const clusterColumns = useMemo(
  		() => [
  		{
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? '👇' : '👉'}
          </span>
        ),
      },
	  		{
		      Header: "Cluster",
		      accessor: "cluster_name"
		    	
			}
  		],
  		[]
  	)


  	const columns = useMemo(
	  () => [
	    {
	      Header: "Node Name",
	      accessor: "db_node_name"
	    },
	    {
	      Header: "Node Class",
	      accessor: "db_node_class",
	    },
	    {
	      Header : "Cluster Name",
	      accessor : "db_cluster_name"
	    },
	    {
	      Header: <div className="App-body-CS-Right-Cell">
	      					Storage ( GB )
	      				</div>,
	      accessor: "db_storage",
	      Cell: row => (
	      	<div className="App-body-CS-Right-Cell">
	      		{row.row.original.db_storage}
	      	</div>
	      )
	    },
	    {
	      Header: "Availability Zone",
	      accessor: "db_availability_zone",
	    },
	    {
	      Header: "Engine",
	      accessor: "db_engine",
	    },   
	    {
	      Header : "Product",
	      accessor : "db_product"
	    },    
	    {
	      Header : "Module",
	      accessor : "db_module"
	    },    
	    {
	      Header : "Environment",
	      accessor : "db_env"
	    },
	    {
	      Header : "Host Cloud Provider",
	      accessor : "db_host_cloud_provider"
	    },
	    {
	      Header : "Node Region",
	      accessor : "db_node_region"
	    },
	    {
	      Header: "End Point",
	      accessor: "db_endpoint",
	    },
	    {
	      Header : "Subscription Account Name",
	      accessor : "db_subscription_account_name"
	    },
	    {
	      Header: "Created Time",
	      accessor: "db_node_created_time",
	    },
	    {
	    	Header: <div><img src={SettingsIcon} /></div>,
            accessor: 'action',
            Cell: row => (
            <div onClick={e=> handleEdit(row.row.original)}>
              	<img src={OverFlowIcon} />
            </div>
            ),
	    }
	  ],
	  []
	)

	const hiddenColumns = []

	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])


  function loadInvData() {

  	var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    setShowLoading(true)
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v2/inventory", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      const count = result['count'] 
		      const sorted_nodes = nodes.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name))
		      setData(sorted_nodes)
		      setOriginalData(nodes)
		      setResultCount(count)
		      setNodeCount(count)
		      setClusterCount(nodes.length)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
  }

	useEffect(() => {
		if(loadData) {
				loadInvData()
				populateDefaultFilter()
	    }
  	}, [loadData])

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  	}

  	function handleEditClose() {
  		setShowEdit(false)
  	}


  	function handleEditSuccess() {
  		setShowEdit(false)
  		setLoadData(true)
  	}

  	function handleClusterEdit(cluster) {
  		setShowClusterEdit(true)
  		setSelectedCluster(cluster)
  	}

  	function handleClusterEditClose() {
  		setShowClusterEdit(false)
  	}

  	function handleClusterEditSuccess() {
  		setShowClusterEdit(false)
  		setLoadData(true)
  	}

  	function handleFilterValues(filterValues) {
  		
  		setFilterValues(filterValues)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

	return(
		<div className="App-DI-table-container">
        	<div className="App-DI-table-container-header">
          		<div className="App-DI-table-container-header-title">
           			Database Inventory
           		</div>
           		{showAdd ? 
           			<div className="App-DI-table-container-header-add-new">
           				<Button 
              			type='primary'
              			onClick = {() => handleNewClick()}
              		>
              			Add Node
            			</Button>
           			</div> 
           			: 
           				<div></div> 
           		}
    			</div>
    			<div className="App-DI-table-container-search">
    				<div className="App-DI-table-container-filter">
								{ showFilter ? 
										<NewFilter defaultFilterValues={filterDefaultValues} filterValues={newFilterValues} clusterCount={clusterCount} resultCount={nodeCount} handleReset={handleReset} handleApply={handleNewFilter} />
									: 
										<div className="App-DI-table-filter-loader"> <MiniLoader /> </div>
								}
						</div>
						<div className="App-DI-table-container-search-box">
							{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    					{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    				</div>
    			</div>
    			<div className="App-DI-table-container-table">
		    		<div className="App-DI-table-header">
		    			<div className="App-DI-table-header-expand">
		    			.
		    			</div>
		    			<div className="App-DI-table-header-cluster-name">
		    				<div className="App-DI-table-header-cluster-name-text">
		    					Cluster
		    				</div>
		    			</div>
		    			<div className="App-DI-table-header-cluster-name">
		    				<div className="App-DI-table-header-cluster-name-text">
		    					POC
		    				</div>
		    			</div>
		    			<div className="App-DI-table-header-cluster-name">
		    				<div className="App-DI-table-header-cluster-name-text">
		    					Tags
		    				</div>
		    			</div>
		    			<div className="App-DI-table-header-settings">
		    			</div>
		    		</div>
		    		{ !showLoading ?
				    		<Collapse>
				    			{data.map((cluster, index) => (
				    					<Panel header=<ClusterPanelHeader cluster={cluster} handleEdit={handleClusterEdit} handlePopupOpen={handlePopupOpen} /> key={index} >
				    						<NewTable data={cluster.inventory_list} columns={columns} loading={showLoading} hiddenColumns={hiddenColumns} className="di_table" getRowProps={row => ({
								          style: {
								            cursor: "pointer",
								            height : "10px",
								            borderLeft : "1px solid #4285F4",
								            overflow : "auto",
								            height : "100%"
								          }
								        })} stickyTable={true} />
				    					</Panel>
				    				))}
				    		</Collapse>
		    		 	: 
		    		 		<div className="App-DI-table-loader">
		    		 			<Loader />
		    		 		</div>
		    		}
    			</div>
	       {showCreate ? <CreateNode defaultFilterValues={filterDefaultValues} handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}
	       {showEdit ? <EditNode defaultFilterValues={filterDefaultValues} currNode={currNode} handleClose={handleEditClose} handleSuccess={handleEditSuccess} /> : <div></div>}
	       {showClusterEdit ? <EditCluster selectedCluster={selectedCluster} handleClose={handleClusterEditClose} handleSuccess={handleClusterEditSuccess} /> : <div></div>}
          {showPopup ?	<Popup dataType={popupDataType} showPopup={showPopup} title={popupTitle} details={popupDetails} handleClose={handlePopupClose} /> : <div></div> }
    	</div>
	)

}

export default NewDatabaseInventory