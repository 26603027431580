import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../common/TableContainer'
import CreateEngine from './createEngine'
import './assets/engines.css'
import 'antd/dist/antd.css';
import SearchIcon from './assets/search.svg'
import { Button, Radio, Input } from 'antd';
import update from 'immutability-helper'


function DatabaseEngines(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

  	const [showLoading, setShowLoading] = useState(true)

  	const [showCreate, setShowCreate] = useState(false)

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const { Search } = Input;

  	const columns = useMemo(
	  () => [
	    {
	      Header: "Engines",
	      accessor: "name"
	    }
	  ],
	  []
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	    	console.log(value)
	      	return (
	        	value.name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
		}
  	};

	useEffect(() => {
		if (loadData) {
			setShowLoading(true)
		    console.log(BASE_URL)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/engine", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      console.log(nodes)
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		    }
	    	doFetch()
    	}
  	}, [loadData])

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function moveDataRow(dragIndex, hoverIndex) {
  		const dragRecord = data[dragIndex]
		setData(
		  update(data, {
		    $splice: [
		      [dragIndex, 1],
		      [hoverIndex, 0, dragRecord],
		    ],
		  })
		)
  	}

  	function handleNewClick() {
  		setShowCreate(true)
  	}

  	function handleSuccess() {
  		setShowCreate(false)
  		setLoadData(true)
  	}

  	function handleNewClose() {
  		setShowCreate(false)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

	return(
		<div className="App-Engine-table-container">
        	<div className="App-Engine-table-container-header">
          		<div className="App-Engine-table-container-header-title">
           			Database Engines
           		</div>
           		{showAdd ? <div className="App-Engine-table-container-header-add-new">
           			<Button 
              			type='primary'
              			onClick = {() => handleNewClick()}
              		>
              			Add Engine
            		</Button>
           		</div> : <div></div> }
    		</div>
    		<div className="App-Engine-table-container-search">
				<div className="App-Engine-table-container-search-box">
					{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    				{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    			</div>
    		</div>
    		<div className="App-Engine-table-box-container">
		        <TableContainer data={data} columns={columns} loading={showLoading} hiddenColumns={hiddenColumns} getRowProps={row => ({
		          style: {
		            cursor: "pointer",
		            height : "10px"
		          }
		        })} moveDataRow={moveDataRow} />
	        </div>
	        {showCreate ? <CreateEngine  handleClose={handleNewClose} handleSuccess={handleSuccess} /> : <div></div>}

    	</div>
	)

}

export default DatabaseEngines