import React, { useState, useEffect, useMemo } from 'react';
import LeftChevron from './assets/chevron-left.svg';
import Loader from '../common/Loader'
import './assets/dataCopyDetail.css'
import NewTable from '../common/NewTable'


function DataCopyDetail(props) {

	function handleClose() {
		props.handleClose()
	}

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [tableColumns, setTableColumns] = useState([])

	const [goLiveData, setGoLiveData] = useState([])

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Table",
				accessor : "table_name",
				width : 200
			},
			{
				Header : "Table Backup Status",
				accessor : "table_backup_status",
				width : 100
			},
			{
				Header : "Table Restore Status",
				accessor : "table_restore_status",
				width : 100
			},
			{
				Header : "Comments",
				accessor : "comments",
				width : 200
			},
			{
				Header : "Backup Cloud Path",
				accessor : "backup_cloud_path",
				width : 400
			},
			{
				Header : "Last Update Time",
				accessor : "last_update_time",
				width : 200
			},
			{
				Header : "Jenkins Build Url",
				accessor : "jenkins_build_url",
				width : 400
			},

		])


	useEffect(() => {
		var data = []
		data.push(props.data_copy_details)
		setTableColumns(mongoColumns)
		setGoLiveData(data)
	}, [])


	return (
			<div className="App-SQ-create-node-container">
				<div className="App-SQ-create-node-modal-content">
					<div className="App-SQ-create-node-modal-header">
		 				<div className="App-SQ-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-SQ-create-node-modal-header-content"> 
 							DB - {props.data_copy_details.db}
 						</div>
					</div>
					<div className="App-SQ-create-node-modal-body">
						<div className="App-DCD-modal-table">
							<NewTable data={goLiveData} columns={tableColumns} hiddenColumns={hiddenColumns} />
						</div>
					</div>
				</div>
			</div>
		)

}

export default DataCopyDetail