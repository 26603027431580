import React, { useState, useEffect, useMemo } from 'react';
import './assets/dActivities.css'
import NewTable from '../common/NewTable'
import { Button, Radio, Input, Select } from 'antd';
import SearchIcon from './assets/search.svg'
import EditActivity from './editActivity'


function NeedsReview(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const API_KEY = process.env.REACT_APP_API_KEY

	const [data, setData] = useState([])

  	const [showLoading, setShowLoading] = useState(true)

  	const [selectedRowIds, setSelectedRowIds] = useState([])

  	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [loadData, setLoadData] = useState(true)

	const [showAdd, setShowAdd] = useState(false)

	const [currentRow, setCurrentRow] = useState({})

	const [pageSize, setPageSize] = useState(20)

	const [selectedFilter, setSelectedFilter] = useState('')

	const [buttonDisable, setButtonDisable] = useState(true)

	const [filterDisable, setFilterDisable] = useState(true)

	const menu = ['Review']

	const filterOptions = [
		{
			'label' : 'db_node_class',
			'value' : 'db_node_class'
		},
		{
			'label' : 'db_storage',
			'value' : 'db_storage'
		},
		{
			'label' : 'db_engine_version',
			'value' : 'db_engine_version'
		},
		{
			'label' : 'db_backup_retention_period',
			'value' : 'db_backup_retention_period'
		}
	]

	const [showEdit, setShowEdit] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const { Search } = Input;

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

  	const columns = useMemo(
	  () => [
	    {
	      Header: "Node Name",
	      accessor: "db_node_name"
	    },
	    {
	      Header: "Activity Type",
	      accessor: "db_activity_type"
	    },{
	      Header: "Field Changed",
	      accessor: "db_field_changed"
	    },{
	      Header: "Old Field Content",
	      accessor: "db_old_field_content"
	    },{
	      Header: "New Field Content",
	      accessor: "db_new_field_content"
	    },{
	      Header: "Jira",
	      accessor: "db_jira"
	    },{
	      Header: "Approved By",
	      accessor: "db_approved_by"
	    },
	    {
	      Header: "Created By",
	      accessor: "created_by"
	    },
	    {
	      Header: "Comments",
	      accessor: "db_comments"
	    },
	    {
	      Header: "Cost Effect - System Generated",
	      accessor: "db_cost_effect_system",
	      Cell : row => (
	      		<div className="App-body-CS-Right-Cell">
	      			{row.row.original.db_cost_effect_system && formatter.format(row.row.original.db_cost_effect_system)}
	      		</div>
	      	)
	    },
	    {
	      Header: "Cost Effect - Manual Estimated",
	      accessor: "db_cost_effect_manual",
	      Cell : row => (
	      		<div className="App-body-CS-Right-Cell">
	      			{row.row.original.db_cost_effect_manual &&  formatter.format(row.row.original.db_cost_effect_manual)}
	      		</div>
	      	)
	    },
	    {
	      Header: "POC",
	      accessor: "db_poc"
	    },
	    {
	      Header : "Activity Date-time",
	      accessor : "created_time"
	    }
	  ],
	  []
	)

	const hiddenColumns = []

	const [env, setEnv] = useState('All');

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.db_node_name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
		}
  	};

  	function handleMenu(item, row) {
  		setCurrentRow(row)
		setShowEdit(true)
	}


	function handleFilterChange(value) {
		setSelectedFilter(value)
		setFilterDisable(false)
	}


	function handleFilterClick() {
		let filteredData = originalData.filter(value => {
	      	return (
	        	value.db_field_changed && value.db_field_changed.toLowerCase().includes(selectedFilter.toLowerCase())
	      		);
	    	});
	    	setData(filteredData);
	}


	function handleReset() {
		setData(originalData)
		setSelectedFilter('')
		setFilterDisable(true)
	}


	function handleMultiSelect(selectedRows) {
		const selectedIds = selectedRows.map(row => row.original.activity_id);
		if ( selectedIds.length > 0 ) {
			setButtonDisable(false)
		} else {
			setButtonDisable(true)
		}
		console.log(selectedIds)
		setSelectedRowIds(selectedIds)
	}

	function handlePagination(size) {
		setPageSize(size)
	}

	function handleEditClose() {
		setShowEdit(false)
	}

	useEffect(() => {
		if (props.loadData) {
			setShowLoading(true)
		    console.log(BASE_URL)
		    var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/activity/filter/status?status=needs_review", {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		      const body = await response.json()
		      const result = body.message
		      const nodes = result['data']
		      console.log(nodes)
		      setData(nodes)
		      setOriginalData(nodes)
		      setShowLoading(false)
		      setLoadData(false)
		      props.handleLoadComplete()
		    }
	    	doFetch()
    	}
  	}, [props.loadData])

  	useEffect(() => {
  		var role = localStorage.getItem('db_portal_user_role')
  		if (role == "dba_admin") {
  			setShowAdd(true)
  		}
  	}, [])

  	function handleSuccess() {
  		setShowEdit(false)
  		setLoadData(false)
  		props.handleLoadData()
  	}

  	function handleEditClose() {
  		setShowEdit(false)
  	}

  	function handleShowSearch() {
  		setShowSearch(true)
  	}

  	function handleReviewClick() {
  		setShowLoading(true)
		console.log(BASE_URL)
		var selectedIds = selectedRowIds
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   	if(selectedRowIds.length > 20) {
	   		selectedIds = selectedRowIds.slice(0, 20)
	   	}
		const doFetch = async () => {
		    const response = await fetch(BASE_URL + "/v1/activity/mark_review", {
		    	method : 'POST',
		      	headers : {
		        	'x-api-key' : apikey,
		        	'Accept':'application/json',
	        		'Content-Type':'application/json',
		        	'oauth-token' : oauth_token
		      	},
		      	body : JSON.stringify({
		      		'activity_ids' : selectedIds,
		      		'page_size' : pageSize
		      	})
		    })
		   	const body = await response.json()
		   	const result = body.message
		    setShowLoading(false)
		    setLoadData(false)
		    setSelectedRowIds([])
		    props.handleLoadComplete()
		    props.handleLoadData()
		}
	    doFetch()
  	}

	return(
		<div className="App-DA-AC-table-container">
    		<div className="App-DA-AC-table-container-search">
    			<div className="App-DA-AC-table-container-mark-review"> 
    				<Button
    					type='primary'
    					disabled={buttonDisable}
    					onClick={handleReviewClick}
    				>
    						Mark Review
    				</Button>
    			</div>
    			<div className="App-DA-AC-Filter">
    				<Select className="App-DA-NS-FilterUnitSelect" options={filterOptions} value={selectedFilter} onChange={handleFilterChange} />
    				<Button
    					type='primary'
    					disabled={filterDisable}
    					onClick={handleFilterClick}
    					style={{'margin' : '5px'}}
    				>
    						Filter
    				</Button>
    				<Button
    					style={{'margin' : '5px'}}
    					onClick={handleReset}
    				>
    						Reset
    				</Button>
    			</div>
				<div className="App-DA-AC-table-container-search-box">
					{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    				{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    			</div>
    		</div>
	        <NewTable 
	        	data={data} 
	        	columns={columns} 
	        	loading={showLoading} 
	        	hiddenColumns={hiddenColumns} 
	        	getRowProps={row => ({
			        style: {
			            cursor: "pointer",
			            height : "10px"
			          }
		        	})
		        } 
		        stickyTable={true} 
	        	showMenu={true} 
	        	menu={menu} 
	        	handleMenu={handleMenu} 
	        	allowMultiSelect={true}
	        	handleMultiSelect={handleMultiSelect}
	        	handlePagination={handlePagination}
	        />
	        {showEdit ? <EditActivity data={currentRow} handleClose={handleEditClose} handleSuccess={handleSuccess} /> : <div></div>}

    	</div>
	)
}

export default NeedsReview
