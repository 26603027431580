import React, { useState, useEffect, useMemo } from 'react';
import './assets/createEnvironment.css'
import LeftChevron from './assets/chevron-left.svg';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';



function CreateEnvironment(props) {

	const [form] = Form.useForm();

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const { TextArea } = Input;

	const [category, setCategory] = useState('')

	const [name, setName] = useState('')

	const [displayName, setDisplayName] = useState('')

	const [disableSave, setDisableSave] = useState(true)

	const options=[
        {
          value: 'tk-prod',
          label: 'tk-prod'
        },
        {
          value: 'tk-nonprod',
          label: 'tk-nonprod'
        }]

  useEffect(() => {
  	if( name != "" && displayName != "") {
  		setDisableSave(false)
  	} else {
  		setDisableSave(true)
  	}

  }, [name, displayName])

	const handleChange = (value: string | string[]) => {
  		console.log(`Selected: ${value}`);
  		setCategory(value)
	};

	function handleName(e) {
		console.log(e.target.value)
		setName(e.target.value)
	}

	function handleDisplayName(e) {
		setDisplayName(e.target.value)
	}

	function handleClose() {
		props.handleClose()
	}

	function handleCreateNode() {
		console.log("Hello")
		var apikey = localStorage.getItem('db_portal_app_apikey')
   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		fetch( BASE_URL + '/v1/profile' ,{
	    			method : 'POST',
	    			headers : {
	    				'Accept':'application/json',
	        			'Content-Type':'application/json',
	    				'x-api-key' : apikey,
	    				'oauth-token' : oauth_token
	    			},
	    			body : JSON.stringify({
	            "category": category,
	            "profile_name" : name,
	            "display_name" : displayName
	        })
	    	})
	    	.then(res =>
	    		res.json())
	    	.then((data) => {
	    			props.handleSuccess()
	    	})
	    	.catch(console.log)
	}

	return (
			<div className="App-DI-create-node-container">
				<div className="App-DI-create-node-modal-content">
					<div className="App-DI-create-node-modal-header">
		 				<div className="App-DI-create-node-modal-back-icon" onClick={handleClose}>
		 					<img src={LeftChevron} />
		 				</div>
 						<div className="App-DI-create-node-modal-header-content"> Create Environment </div>
					</div>
					<div className="App-DI-create-node-modal-body">
						<div className="App-DI-create-node-form-container">
						<Form
      						form={form}
      						layout="vertical"
      					>
      						<div className="inline">
      							<Form.Item label="Category" style={{marginRight:'auto'}}>
        							<Select
								        size={'large'}
								        defaultValue="Select"
								        onChange={handleChange}
								        style={{ width: '100%' , textAlign : 'left'}}
								        options={options}
								     />
      							</Form.Item>	
      						</div>
      						<Form.Item label="Name">
        						<Input placeholder="Type Here" value={name} onChange={handleName}/>
      						</Form.Item>
      						<Form.Item label="Display Name">
        						<Input placeholder="Type Here" value={displayName} onChange={handleDisplayName}/>
      						</Form.Item>
      					</Form>
      					</div>
					</div>
					<div className="App-DI-create-node-modal-footer">
						<div className="App-DI-create-node-footer-button-container">
							<Button 
	              				onClick = {() => handleClose()}
	              			>
	              				Cancel
	            			</Button>
	            			<Button
	            				type='primary'
	              				onClick = {() => handleCreateNode()}
	              				disabled = {disableSave}
	              			>
	              				Save
	            			</Button>
            			</div>
					</div>
				</div>
			</div>
		)

}

export default CreateEnvironment
