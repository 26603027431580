import React, { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: '',
    },
    legend : {
      display : true,
      position : 'right'
    }
  },
  responsive: true,
  maintainAspectRatio : false,
  scales: {
    x: {
      stacked: true,
      barPercentage : 'flex'
    },
    y: {
      stacked: true,
    },
  },
};

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomRGB() {
    const r = getRandomInt(50, 250);
    const g = getRandomInt(160, 190);
    const b = getRandomInt(190, 250);
    console.log(r, g, b)
    return `rgb(${r}, ${g}, ${b})`;
}

function getLast12Months() {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const currentDate = new Date();

    currentDate.setDate(15) // setting the date to 15, so that current month -1 doesnt return the same month for 31st

    const last12Months = [];

    for (let i = 0; i < 12; i++) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const formattedMonth = `${monthNames[month]} ${String(year).slice(-2)}`;
      last12Months.unshift(formattedMonth);
      currentDate.setMonth(month - 1);
    }

    return last12Months;
}


const labels = getLast12Months();


export default function StackedBarChart(props) {

  // const labels = props.labels


  const data = {
  labels,
  datasets : props.dataset
};

  const chartRef = useRef();

  const onClick = (event) => {
    var eventDetails = getElementAtEvent(chartRef.current, event)
    console.log(eventDetails)
    console.log(eventDetails[0]['index'])
    var barIndex = eventDetails[0]['index']
    console.log(labels)
    var selectedMonth = labels[barIndex]
    console.log(selectedMonth)
    const {startDate, endDate} = getMonthDates(selectedMonth)
    console.log(startDate)
    console.log(endDate)
    props.handleDateChange(selectedMonth, startDate, endDate)

  }

  function getMonthDates(input) {
    const [monthStr, yearStr] = input.split(' ');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const monthIndex = monthNames.indexOf(monthStr);
    if (monthIndex === -1 || yearStr.length !== 2) {
      throw new Error('Invalid input format. Expected format: Mon YY (e.g., Jun 22)');
    }
    
    const fullYear = new Date().getFullYear().toString().substring(0, 2) + yearStr;
    const startDate = new Date(fullYear, monthIndex, 1);
    const endDate = new Date(fullYear, monthIndex + 1, 0);

    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0]
    };
}


  useEffect(() => {
    console.log("data is ")
    console.log(data)
  }, [])

  return <Bar 
    ref={chartRef}
    options={options} data={data} onClick={onClick} />;
}