import React, { useState, useEffect, useMemo, useRef } from 'react';
import './assets/tenantLevelCost.css';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import Loader from '../common/Loader'
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'
import DealerLevelCost from './dealerLevelCost'
import TenantDealerCost from './tenantDealerCost'
import ExportIcon from './assets/export.svg'
import * as XLSX from 'xlsx';


function TenantLevelCost(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [form] = Form.useForm();

	const { Search } = Input;

	const searchRef = useRef(null);

	const [showData, setShowData] = useState(false)

	const [showForm, setShowForm] = useState(false)

	const [showTable, setShowTable] = useState(false)

	const [totalTenantCost, setTotalTenantCost] = useState(0)

	const [totalDealerCost, setTotalDealerCost] = useState(0)

	const [selectedTenant, setSelectedTenant] = useState('')

	const [selectedDealers, setSelectedDealers] = useState('')

	const [hiddenColumns, setHiddenColumns] = useState([])

	const sortBy = useMemo(() => [{
		'id' : "total_cost",
		'desc' : true
	}])

	const [productOptions, setProductOptions] = useState([])

	const [envOptions, setEnvOptions] = useState([])

	const [disableRelease, setDisableRelease] = useState(true)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [showSearch, setShowSearch] = useState(false)

	const [selectedProduct, setSelectedProduct] = useState('')

	const [selectedEnv, setSelectedEnv] = useState('')

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [searchValue, setSearchValue] = useState('')

	const [originalData, setOriginalData] = useState([])

	const [filters, setFilters] = useState({})

	const [loadData, setLoadData] = useState(false)

	const onSearch = (input) => globalSearch(input);

	const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Tenant Name",
				accessor : "tenant_name",
				Cell : row => (
						<TenantDealerCost tenant_name={row.row.original.tenant_name} dealers={row.row.original.dealers} handleClick={handleTenantClick} />
					),
				width : 600
			},
			{
				Header : <div className="App-body-CSum-Right-Cell"> Per Month Cost </div>,
				accessor : "total_cost",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.total_cost ? formatter.format(Math.abs(row.row.original.total_cost)) : 'NA'}
	            	</div>
            	)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell"> Dealer Count </div>,
				accessor : "dealer_count",
				width : 200,
				Cell : row => (
						<div className="App-body-CSum-Right-Cell">
							{row.row.original.dealer_count}
						</div>
					)
			},
			{
				Header : <div className="App-body-CSum-Right-Cell"> Average Dealer Cost </div>,
				accessor : "average_dealer_cost",
				width : 200,
				Cell: row => (
	            	<div className="App-body-CSum-Right-Cell">
	              		{row.row.original.average_dealer_cost ? formatter.format(Math.abs(row.row.original.average_dealer_cost)) : 'NA'}
	            	</div>
            	)
			}])

	const [tableColumns, setTableColumns] = useState([])

	useEffect(() => {
		populateFilter()
	}, [])

	useEffect(() => {
		if(showForm) {
				console.log(filters)
				setFilters(filters)
				setDisableRelease(false)
				setTableColumns( mongoColumns )
				
		}

	}, [showForm])

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}


	useEffect(() => {
		
		if (loadData) {
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/tenant_level_cost?product=" + selectedProduct + 
		      		"&db_env=" + selectedEnv,
	  		  	{
			      method : 'GET',	
			      headers : {
			        'x-api-key' : apikey,
			        'oauth-token' : oauth_token
			     }
		    })
		    const body = await response.json()
		    const result = body.message
		    var reformedResult = reformatData(result)
		    setOriginalData(reformedResult['tenant_summary_data'])
		    setSlowQueryData(reformedResult['tenant_summary_data'])
		    calculateTotals(reformedResult['tenant_summary_data'])
		    setShowReportDisable(false)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	function handleClose() {
		setShowData(false)
	}

	function populateFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/tenant_level_cost_options"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    setFilters(result)
		    var products = Object.keys(result)
		    var defaultProduct = products[0]
		    setProductOptions(constructFilterArray(products))
		    setEnvOptions(constructFilterArray(result[defaultProduct]))
		    setSelectedProduct(defaultProduct)
		    setSelectedEnv(result[defaultProduct][0])
		    setLoadData(true)
		    setShowForm(true)
		}
		doFetch()
	}

	function handleTenantClick(tenant_name, dealers) {
		setSelectedTenant(tenant_name)
		setSelectedDealers(dealers)
		setShowData(true)
	}

	function reformatData(inputJson) {
    	const output = { 'tenant_summary_data': [] }; 

    	for (const [key, value] of Object.entries(inputJson['tenant_summary_data'])) {
        	const tenantData = {
            	tenant_name: key,
            	...value 
        	};
        	output['tenant_summary_data'].push(tenantData);
    	}

    	return output;
    }

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.tenant_name &&  value.tenant_name.toLowerCase().includes(searchInput.toLowerCase()) 
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

  	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedQuery = []
		combinedQuery.push({'title' : 'Tenant Level Costt', jsonData : slowQueryData})
		convertJSONsToXLSX(combinedQuery)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "tenant_level_cost.xlsx");
    };


	function handleProductChange(fieldLabel) {
		setSelectedProduct(fieldLabel)
		setEnvOptions(constructFilterArray(filters[fieldLabel]))
	}

	function handleEnvChange(fieldLabel) {
		setSelectedEnv(fieldLabel)
		console.log(fieldLabel)
		setShowReportDisable(false)
	}

	function handleButtonClick() {
		setShowTable(false)
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/tenant_level_cost?product=" + selectedProduct + 
		      		"&db_env=" + selectedEnv,
	  		  	{
			      method : 'GET',	
			      headers : {
			        'x-api-key' : apikey,
			        'oauth-token' : oauth_token
			     }
		    })
		    const body = await response.json()
		    const result = body.message
		    var reformedResult = reformatData(result)
		    setOriginalData(reformedResult['tenant_summary_data'])
		    setSlowQueryData(reformedResult['tenant_summary_data'])
		    calculateTotals(reformedResult['tenant_summary_data'])
		    setShowReportDisable(false)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
	}

	function calculateTotals(tenants) {
		let totalTenantCost = 0 
		let totalDealerCost = 0
		tenants.forEach(item => {
			totalTenantCost += item.total_cost ? item.total_cost : 0
			totalDealerCost += item.average_dealer_cost ? item.average_dealer_cost : 0
		})
		setTotalTenantCost(Math.abs(totalTenantCost/tenants.length))
		setTotalDealerCost(Math.abs(totalDealerCost/tenants.length))
	}

	function handleReset() {
		setSelectedProduct('')
		setSelectedEnv('')
		setShowReportDisable(true)
	}



	return(
			<div className="App-TLC-tab-container">
				<div className="App-body-TLC-Header-Container">
					<div className="App-body-TLC-Header-Title-Container">
						<div className="App-body-TLC-Header-Title">
							<div className="App-body-TLC-Header-Title-Name">
								Tenant Level Cost
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ? 
					<div className="App-TLC-tab-body">
						<div className="App-TLC-tab-mongo-container">
							<div className="App-TLC-tab-mongo-filter">
								<div className="App-TLC-tab-mongo-form">
									{showForm ? 
										<div className="App-TLC-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="TLC-inline">
				      								<Form.Item label="Product" style={{width : '15%', margin:'1em 2em'}}>
					        							<Select disabled={disableRelease} options={productOptions} value={selectedProduct} onChange={handleProductChange} />
					      							</Form.Item>
					      							<Form.Item label="Environment" style={{width : '25%', margin:'1em 2em'}}>
					        							<Select disabled={disableRelease} options={envOptions} value={selectedEnv} onChange={handleEnvChange} />
					      							</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : '3em 2em'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : '3em 1em'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-TLC-tab-form-loader">
			      							<Loader />
			      						</div>
									}
								</div>
							</div>
							<div className="App-TLC-tab-mongo-data-container">
									<div className="App-TLC-tab-body-table-header-container">
										<div className="App-TLC-table-container-count-box">
											<div className="App-TLC-table-container-count"> Avg Tenant Cost : <b> {formatter.format(totalTenantCost)} </b> </div>
											<div> | </div>
											<div className="App-TLC-table-container-count"> Avg Dealer Cost : <b> {formatter.format(totalDealerCost)} </b> </div>
										</div>
										<div className="App-TLC-table-container-search-box">
											<div className="App-body-TLC-header-export">
												<img src={ExportIcon} onClick={handleExportClick} />
											</div>
											{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
		    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
		    							</div>
		    						</div>
									{showTable ?
										<div className="App-TLC-tab-body-table-container">
											<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} sortBy={sortBy} />
										</div>
									: 
										<div className="App-RS-tab-body-table-loader">
											<Loader />
										</div> 
									}
								 </div>
						</div>
					</div>
				:
					<div className="App-TLC-tab-form-loader">
				 		<Loader />
				 	</div>
				}
				{showData ? <DealerLevelCost tenant_name={selectedTenant} dealers={selectedDealers} handleClose={handleClose} /> : <div></div>}

			</div>
		)
}

export default TenantLevelCost