import React, { useState, useEffect } from 'react';
import FilterIcon from './assets/filter.svg'
import './assets/filter.css'
import NewFilterUnit from './newFilterUnit'

function NewFilter(props) {

	var comparatorValue = [
		{
          value : '=',
          label : 'equals',
        }
    ]



    var defaultAvailableOptions = [
    	{
    		value : "product",
    		label : "Product"
    	},
    	{
    		value : "env",
    		label : "Environment"
    	},
    	{
    		value : "module",
    		label : "Module"
    	},
    	{
    		value : "engine",
    		label : "Engine"
    	}
    ]

    const defaultAvailableValues = {
    	product:[
    					{
    						value : "arc",
    						label : "arc"
    					},
    					{
    						value : "map",
    						label : "map"
    					},
    					{
    						value : "tap",
    						label : "tap"
    					},
    					{
    						value : "drp",
    						label : "drp"
    					},
    					{
    						value : "crm",
    						label : "crm"
    					},
    					{
    						value : "security",
    						label : "security"
    					}
    				],
    	env : 	[
    					{
							value : "stage",
							label : "stage"
    					},
    					{
							value : "prod",
							label : "prod"
    					},
    					{
    						value : "test",
    						label : "test"
    					},
    					{
    						value : "preprod",
    						label : "preprod"
    					},
    					{
    						value : "dev",
    						label : "dev"
    					}
    				],
    	engine :  [
    					{
    						value : "postgresql",
    						label : "postgresql"
    					},
    					{
    						value : "aurora-postgresql",
    						label : "aurora-postgresql"
    					},
    					{
    						value : "aurora-mysql",
    						label : "aurora-mysql"
    					},
    					{
    						value : "mariadb",
    						label : "mariadb"
    					},
    					{
    						value : "mongodb",
    						label : "mongo"
    					},
    					{
    						value : "mysql",
    						label : "mysql"
    					}
    			    ],
    	module :  [
    					{
    						value : "integration",
    						label : "integration"
    					},
    					{
    						value : "myvehicle",
    						label : "myvehicle"
    					},
    					{
    						value : "common",
    						label : "common"
    					},	
    					{
    						value : "service",
    						label : "service"
    					},
    					{
    						value : "conversion",
    						label : "conversion"
    					},	
    					{
    						value : "accounting",
    						label : "accounting"
    					},
    					{
    						value : "parts",
    						label : "parts"
    					},	
    					{
    						value : "sales",
    						label : "sales"
    					}
    			    ]
    }

    const [filterDefaultValues, setFilterDefaultValues] = useState(defaultAvailableValues)

    const [showFilters, setShowFilters] = useState(false)

    const [filterValues, setFilterValues] = useState([])

    
    const [showPopup, setShowPopup] = useState(false)

    const [selectedFields, setSelectedFields] = useState([])

    const [filterMap, setFilterMap] = useState({})

    const [filterIndex, setFilterIndex] = useState()

    useEffect(() => {
    	setFilterDefaultValues(props.defaultFilterValues)
    },[])

    useEffect(() => {
    	var tempFilters = []
    	var selectedPropFields = []
    	for ( var index in props.filterValues) {
    		var selectedFilter = props.filterValues[index]
    		selectedPropFields = selectedPropFields.concat(selectedFilter.fieldValue)
    	}
    	setSelectedFields(selectedPropFields)
    	setFilterValues(props.filterValues)
    	setFilterIndex(props.filterValues.length)
    	setShowFilters(true)
    }, [props.filterValues])


    function handlePopup() {
    	if(showPopup) {
			setShowPopup(false)
		} else {
			setShowPopup(true)
		}
    }

    function handleAddFilter() {
		var remainingAvailOptions = handleAvailableOptions()
    	if (remainingAvailOptions.length > 0) {
	    	var newFilterValues = filterValues.concat({"fieldValue" : remainingAvailOptions[0].value, "selectedValue" : ""})
	    	var newSelectedFields = selectedFields.concat(remainingAvailOptions[0].value)
	    	setFilterValues(newFilterValues)
    		setSelectedFields(newSelectedFields)
   		}
    }

    function handleApplyFilter() {
    	props.handleApply(filterValues)
    	setShowPopup(false)
    }


    function handleResetFilter() {
    	var defaultFilterValues = [{"fieldValue" : "env", "selectedValue" : "prod"}]
    	setSelectedFields(['env'])
    	setFilterValues(defaultFilterValues)
    }

    function handleSelectedFilterValue(fieldLabel, fieldValue) {
		var newFilterValues = []
		for ( var index in filterValues) {
			var currFilter = filterValues[index]
			if ( currFilter.fieldValue == fieldLabel) {
				currFilter.selectedValue = fieldValue
				newFilterValues = newFilterValues.concat(currFilter)
			} else {
				newFilterValues = newFilterValues.concat(currFilter)
			}
		}
		setFilterValues(newFilterValues)
    }

    function handleAvailableOptions() {
		var tempAvailOptions = []
		for ( var index in defaultAvailableOptions) {
			var fieldOption = defaultAvailableOptions[index]
			if ( !selectedFields.includes(fieldOption.value)) {
				tempAvailOptions = tempAvailOptions.concat(fieldOption)
			}
		}
		return tempAvailOptions
	}


    function handleSelectedFieldChange(fieldLabel, oldLabel) {
    	var newFilterValues = []
		for ( var index in filterValues) {
			var currFilter = filterValues[index]
			if ( currFilter.fieldValue == oldLabel) {
				var newFilter = [ { 'fieldValue': fieldLabel , 'selectedValue' : ''}]
				newFilterValues = newFilterValues.concat(newFilter)
			}  else {
				newFilterValues = newFilterValues.concat(currFilter)
			}
		}
		setFilterValues(newFilterValues)
    	if ( ! selectedFields.includes(fieldLabel) ) {
    		var newSelectedFields = selectedFields.concat(fieldLabel)		
    		if ( newSelectedFields.includes(oldLabel)){
    			newSelectedFields = newSelectedFields.filter(e => e !== oldLabel)
    		}
    		setSelectedFields(newSelectedFields)
    	}
    }

    function handleDeleteFilter(fieldLabel) {
    	var newSelectedFields = selectedFields.filter(e => e !== fieldLabel)
    	var newFilterValues = []
    	for ( var index in filterValues) {
			var currFilter = filterValues[index]
			if ( currFilter.fieldValue != fieldLabel) {
				newFilterValues = newFilterValues.concat(currFilter)
			}
		}
		setFilterValues(newFilterValues)
    	setSelectedFields(newSelectedFields)
    }

    function handlePopupClose() {
    	setShowPopup(false)
    }

    return(
			<div className="App-DB-Filter-container">
				<div className="App-DB-Filter-icon">
					<img src={FilterIcon}  onClick={handlePopup} />
					 {showPopup ? 
					 		<div className="popuptext" id="myPopup">
					 			<div className="App-DB-Filter-Popup-title">
					 				<div className="App-DB-Filter-Popup-Close"  onClick={handlePopupClose} > X </div>
					 			</div>
					 			{showFilters ?
							 			<div className="App-DB-Filter-Popup-Container">
							 				{filterValues.map((filterValue, index) => {
							 					return (
							 							<NewFilterUnit 
    														key={index}
    														index={index}
    														filterField={filterValue}
								    						comparators={comparatorValue}
								    						availableOptions={defaultAvailableOptions}
								    						availableValues={filterDefaultValues}
								    						selectedFields={selectedFields} 
								    						handleSelectedFilterValue={handleSelectedFilterValue}
								    						handleSelectedFieldChange={handleSelectedFieldChange}
								    						handleDeleteFilter={handleDeleteFilter} />
							 							)
							 				})}
							 			</div>
						 			: 
						 				<div className="App-DB-Filter-Popup-Container"></div>
					 			}
					 			<div className="App-DB-Filter-Footer">
					 				<div className="App-DB-Filter-AddFilter" >
					 					<div className="App-DB-Filter-AddFilter-Label" onClick={handleAddFilter} >Add Filter</div>
					 				</div>
					 				<div className="App-DB-Filter-ResetFilter" onClick={handleResetFilter}>
					 				 	<div className="App-DB-Filter-ResetFilter-Label">Reset</div>
					 				</div>
					 				<div className="App-DB-Filter-ApplyFilter" onClick={handleApplyFilter} >
					 					<div className="App-DB-Filter-ApplyFilter-Label">Apply</div>
					 				</div>
					 			</div>
					 		</div> 
					 	: 
					 		<div></div> 
					 }
				</div>
				<div className="App-DB-Filter-Reset">
					<div className="App-DB-Filter-Reset-label" onClick={props.handleReset}> Reset </div>
					<div className="App-DB-Filter-Reset-seperator"> | </div>
					<div className="App-DB-Filter-Reset-result">
							{props.clusterCount} Cluster(s) | {props.resultCount} Node(s) 
					</div>
				</div>
			</div>
		)

}

export default NewFilter