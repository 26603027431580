import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Popup = (props) => {

  const [defaultParser, setDefaultParser] = useState(true)

  const [jsonParser, setJsonParser] = useState(false)

  const [details,  setDetails] =  useState({})
  

  const handleCancel = () => {
    props.handleClose()
  };

  useEffect(() => {
    if(props.dataType == 'JSON') {
      setDetails(props.details.toString())
      setJsonParser(true)
      setDefaultParser(false)
    }
  }, [])

  return (
      <Modal size={"large"}  title={props.title} footer={null}  open={props.showPopup} onCancel={handleCancel}>
        {defaultParser && <div>{props.details}</div>}
        {jsonParser && <div> {details} </div>}
      </Modal>
  );
};
export default Popup;