import React, { useState, useEffect } from 'react';
import './assets/databaseProductReports.css'
import { DatePicker } from 'antd';
import NewTable from '../common/NewTable'
import Loader from '../common/Loader'
import moment from 'moment';

function DatabaseProductReports(props) {

	const [currStartLevel, setCurrStartLevel] = useState('PRD')

	const [currLevel, setCurrLevel] = useState(['PRD'])

	const [filterValues, setFilterValues] = useState([])

	const [showHeader, setShowHeader] = useState(true)

	const [paths, setPaths] = useState([])

	const [startDate, setStartDate] = useState(props.startDate)

	const [endDate, setEndDate] = useState(props.endDate)

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	var envData = [{
		"environment" : "prod",
		"cloud_provider" : "2",
		"cluster" : "3",
		"node" : "4",
		"ymd" : "2",
		"total_cost" : "8000"
	},
	{
		"environment" : "nonprod",
		"cloud_provider" : "3",
		"cluster" : "2",
		"node" : "5",
		"ymd" : "2",
		"total_cost" : "4000"
	}]

	var cloudData = [
		{
			"cloud_provider" : "aws",
			"cluster" : "2",
			"node" : "2",
			"ymd" : "2",
			"total_cost" : "1000"
		},
		{
			"cloud_provider" : "azure",
			"cluster" : "3",
			"node" : "1",
			"ymd" : "2",
			"total_cost" : "1500"
		}
	]

	var clusterData = [
		{
			"cluster" : "Cluster 1",
			"node" : 2,
			"total_cost" : "8000"
		}, {
			"cluster" : "Cluster 1",
			"node" : 2,
			"total_cost" : "8000"
		}
	]

	var nodeData = [
		{
			"node":"Node 1",
			"total_cost" : "2000"
		},
		{
			"node" : "Node 2",
			"total_cost" : "2500"
		}
	]

	const [tableData, setTableData] = useState(envData)

	const [showTable, setShowTable] = useState(false)

	const hiddenColumns = []

	const defaultColumn = {
   		width: "auto",
  	}

  	const sortBy = [
  		{
  			"id" : "Total Cost",
  			"desc" : true
  		}
  	]

	const [tableColumns, setTableColumns] = useState([])
 
	const [loadData, setLoadData] = useState(false)

	const [data, setData] = useState({})

	function handleColClick(props, filterValues) {
		setShowTable(false)
		var currFilterValues = []
		currFilterValues = filterValues
		constructFilter(props, currFilterValues)
		var nextColLevel = getLevelFromColumn(props.column.id)
		var newCurrLevel = currLevel
		newCurrLevel.push(nextColLevel)
		setCurrLevel(newCurrLevel)
	}

	function populateData(colLevel, tableColumns) {
		var queryJson = constructQueryJson(colLevel, tableColumns)
			var apikey = localStorage.getItem('db_portal_app_apikey')
   			var oauth_token = localStorage.getItem('db_portal_oauth_token')
		   fetch(BASE_URL + "/v1/report/drilldown", {
			     	method : 'POST',
			    	headers : {
			    		'Accept':'application/json',
	        			'Content-Type':'application/json',
			        	'x-api-key' : apikey,
			        	'oauth-token' : oauth_token
			      	},
			      	body : JSON.stringify(queryJson)
			    }).then(response => response.json())
		   .then(data => {
				setTableData(data['message'])
	   			setShowTable(true)
	   		})
	}

	function constructQueryJson(colLevel, tableColumns) {
		var queryJson = {}
		queryJson['dimensions'] = getColFromColumnId(colLevel)
		queryJson['distinct_count_columns'] = getOutputColFromLevel(colLevel)
		queryJson['metrics'] = getOutputColDetailFromLevel(colLevel)
		queryJson['table_name'] = data['monthly_cost_report']['table']
		queryJson['date_filter'] = "db_cost_date >= '"+ props.startDate + "' and db_cost_date < '" + props.endDate + "'"
		queryJson['other_filter']= getOutputFilter()
		return queryJson
	}

	function getOutputFilter() {
		var outputArray = []
		for ( var i in filterValues) {
			var currFilter = filterValues[i]
			var currFilterLevel = getColFromColumnId(currFilter.filterLevelName)
			var outputString = "" + currFilterLevel.name + "=" + "'" + currFilter.filterValue + "'"
			outputArray.push(outputString)
		}
		return outputArray
	}
 
	function constructFilter(props, currFilterValues) {
		var localFilter = {}
		var latestFilter = currLevel.slice(-1)[0]
		var latestFilterCol = getColFromColumnId(latestFilter)
		var latestFilterColName = latestFilterCol['displayName']
		var filterValue = getFilterValue(latestFilterColName, props)
		localFilter['filterName'] = latestFilterColName
		localFilter['filterValue'] = filterValue
		localFilter['filterLevelName'] = latestFilter
		setFilterValues((currFilterValues) => {
      			var filterValuesNew = [...currFilterValues];
      			filterValuesNew.push(localFilter);
      			return filterValuesNew;
    	})
	}

	function getFilterValue(latestFilterColName, props) {	
		const rowValue = props.row.original
		if (latestFilterColName in rowValue) {
			return rowValue[latestFilterColName]
		} else {
			return ''
		}
	}

	function ClickableCell(props) {
		return (
				<div className="App-body-DR-Right-Cell" onClick={e=> handleColClick(props, filterValues)}>
	              	<a className="App-body-DR-Path-Link">{props.value}</a>
	            </div>
			)
	}

	function RightCell(props) {

		const formatter = new Intl.NumberFormat('en-US', {
  			style: 'currency',
 			currency: 'USD',
 		})

		return (
				<div className="App-body-DR-Right-Cell">
	              	{formatter.format(props.value)}
	            </div>
			)
	}

	useEffect(() => {
		getReportConfig()
		console.log(props.showHeader)
		if (props.showHeader != undefined) {
			console.log(props.showHeader)
		}
		setShowHeader(props.showHeader)
	}, [])

	useEffect(() => {
		if( loadData) {
			var tableColumns = constructColumns(currStartLevel)
			populateData(currStartLevel, tableColumns)
		}

	}, [loadData])

	useEffect(() => {
		setShowTable(false)
		if (loadData) {
			setStartDate(props.startDate)
			setEndDate(props.endDate)
			var nextColLevel = currLevel.slice(-1)[0]
			var tableColumns =constructColumns(nextColLevel)
			populateData(nextColLevel, tableColumns)
		}
	}, [filterValues, props.startDate, props.endDate])


	function getLevelFromColumn(columnId) {
		var availableLevels = data['monthly_cost_report']['levelKeysMap']
		for ( var i in availableLevels ) {
			var availableLevel = availableLevels[i]
			if ( availableLevel['displayName'] == columnId) {
				return i
			}
		}
	}

	function getColFromColumnId(columnId) {
		var availableLevels = data['monthly_cost_report']['levelKeysMap']
		for ( var i in availableLevels ) {
			if ( i == columnId) {
				return availableLevels[i]
			}
		}
	}

	function handleLevelClick(filterLevel) {
		setShowTable(false)
		var filterLevelName = filterLevel.filterLevelName
		var availableNextLevels = data['monthly_cost_report']['nextLevelKeysMap'][filterLevelName]
		var levelsTobeRemoved = getColsFromLevels(availableNextLevels)
		levelsTobeRemoved.push(filterLevelName)
		var newFilterValues = []
		for (var i in filterValues) {
			var currFilterValue = filterValues[i]
			
			if(!levelsTobeRemoved.includes(currFilterValue.filterLevelName)) {
				newFilterValues.push(currFilterValue)
			}
		}
		var latestLastFilter = newFilterValues.slice(-1)
		if (newFilterValues.length > 0) {
			var latestCurrLevel = []
			for (var i in newFilterValues) {
				var currFilterValue = filterValues[i]
				latestCurrLevel.push(currFilterValue.filterLevelName)
			}
			latestCurrLevel.push(filterLevel.filterLevelName)
			setCurrLevel(latestCurrLevel)
		} else {
			setCurrLevel(['PRD'])
		}
		setFilterValues(newFilterValues)
	}


	function constructColumns(startLevel) {
		var outputCols = ["YMD"]
		var currLevelCols = []
		var currColDetails = []
		var currLevelOutputs = []
		var availableNextLevels = data['monthly_cost_report']['nextLevelKeysMap'][startLevel]
		var colDetails = data['monthly_cost_report']['levelKeysMap']
		currLevelCols = getColsFromLevels(availableNextLevels)
		var width = getColWidth(currLevelCols)
		currLevelOutputs = getOutputFromLevel(startLevel, width)
		var startColDetail = colDetails[startLevel]
		var curStartColDetail = {}
		curStartColDetail['Header'] = startColDetail['displayName']
		curStartColDetail['accessor'] = startColDetail['displayName']
		curStartColDetail['width'] = width
		currColDetails.push(curStartColDetail)
		for ( var i in currLevelCols) {
			var currLevelCol = currLevelCols[i]
			var colDetail = colDetails[currLevelCol]
			var curColDetail = {}
			curColDetail['Header'] = <div className="App-body-DR-Right-Cell">{colDetail['displayName']}</div>
			curColDetail['accessor'] = colDetail['displayName']
			curColDetail['width'] = width
			curColDetail['Cell'] = ClickableCell
			currColDetails.push(curColDetail)
		}
		currColDetails = currColDetails.concat(currLevelOutputs)
		setTableColumns(currColDetails)
		return currColDetails
	}

	function getColWidth(currLevelCols) {
		console.log(currLevelCols.length)
		var totalWidth = 1500
		var colWidth = totalWidth / ( currLevelCols.length + 2)
		console.log(colWidth)
		return colWidth
	}

	function getColsFromLevels(availableNextLevels) {
		var columns = []
		for ( var i in availableNextLevels) {
			var availableNextLevel = availableNextLevels[i]
			const levelTokens = availableNextLevel.split('-')
			columns.push(levelTokens[1])
		}
		return columns
	}

	function getOutputFromLevel(startLevel, width) {
		var outputColDetails = []
		var outputColLevels = data['monthly_cost_report']['levelMetricsMap'][startLevel]
		for ( var i in outputColLevels ) {
			var outputColDetail = {}
			var outputColLevel = outputColLevels[i]
			outputColDetail['Header'] = <div className="App-body-DR-Right-Cell">{outputColLevel['displayName']}</div>
			outputColDetail['accessor'] = outputColLevel['displayName']
			outputColDetail['width'] = width
			outputColDetail['Cell'] = RightCell
			outputColDetails.push(outputColDetail)
		}
		return outputColDetails
	}

	function getOutputColDetailFromLevel(startLevel) {
		var outputColDetails = []
		var outputColLevels = data['monthly_cost_report']['levelMetricsMap'][startLevel]
		for ( var i in outputColLevels ) {
			var outputColDetail = {}
			var outputColLevel = outputColLevels[i]
			outputColDetail['displayName'] = outputColLevel['displayName']
			outputColDetail['name'] = 'db_cost'
			outputColDetail['function'] = outputColLevel['function']
			outputColDetail['functionParam'] = outputColLevel['functionParam']
			outputColDetails.push(outputColDetail)
		}
		return outputColDetails
	}

	function getOutputColFromLevel(startLevel) {
		var colDetails = data['monthly_cost_report']['levelKeysMap']
		var outputColLevels = []
		var availableNextLevels = data['monthly_cost_report']['nextLevelKeysMap'][startLevel]
		var currLevelCols = getColsFromLevels(availableNextLevels)
		for ( var i in currLevelCols) {
			var currLevelCol = currLevelCols[i]
			var colDetail = colDetails[currLevelCol]
			var curColDetail = {}
			curColDetail['displayName'] = colDetail['displayName']
			curColDetail['name'] = colDetail['name']
			outputColLevels.push(curColDetail)
		}
		return outputColLevels
	}


	function getReportConfig() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   fetch(BASE_URL + "/v1/report/product_config", {
		     	method : 'GET',
		    	headers : {
		        	'x-api-key' : apikey,
		        	'oauth-token' : oauth_token
		      	}
		    }).then(response => response.json())
	   .then(data => {
	   		setData(data['message'])
	   		setLoadData(true)
	   })
	}

	return(
			<div className="App-body-DR-Outer-Container">
			{loadData ?
				<div className="App-body-DR-Body-Container">
						<div className="App-body-DR-Body-Outer-Container">
							<div className="App-body-DR-Body-Path-Container">
								<a className="App-body-DR-Path-Link">
									Cost Report Home </a> > {filterValues.map((filterValue) => <div onClick={e => handleLevelClick(filterValue)}> <a className="App-body-DR-Path-Link">{filterValue.filterValue} </a>></div>)}
							</div>
							<div className="App-body-DR-Body-Table">
								<div className="App-body-DR-Body-Table-Body">
									{showTable ? <NewTable data={tableData} columns={tableColumns} hiddenColumns={hiddenColumns} sortBy={sortBy}  />  : <Loader />}
								</div>
							</div>
							<div>
							</div>
						</div>
				</div>
					: 
					<Loader />
			}
			</div>
		)

}

export default DatabaseProductReports
