import React, { useState, useEffect,  useMemo, useRef} from 'react';
import './assets/releaseJiraList.css';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import PopupIcon from './assets/popup-icon-16.png'
import NewTable from '../common/NewTable'
import QueryDisplayer from '../slowQuery/QueryDisplayer'
import SearchIcon from './assets/search.svg'
import ExportIcon from './assets/export.svg'
import * as XLSX from 'xlsx';


function ReleaseJiraList(props) {

	const [active, isActive] = useState(true)

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [mysqlFilters, setMysqlFilters] = useState({})

	const [loadData, setLoadData] = useState(false)

	const [selectedDBType, setSelectedDBType] = useState('Mysql')

	const [showForm, setShowForm] = useState(false)

	const [allowedMonths, setAllowedMonths] = useState([])

	const [selectedMonth, setSelectedMonth] = useState()

	const [selectedOriginalMonth, setSelectedOriginalMonth] = useState()

	const [showTable, setShowTable] = useState(false)

	const [showLoading, setShowLoading] = useState(true)

	const [selectedJira, setSelectedJira] = useState('')

	const [selectedStatus, setSelectedStatus] = useState('')

	const [disableStatus, setDisableStatus] = useState(true)

	const [disableRelease, setDisableRelease] = useState(true)

	const [tableColumns, setTableColumns] = useState([])

	const [dbOptions, setDBOptions] = useState([])

	const [releaseOptions, setReleaseOptions] = useState([])

	const [envOptions, setEnvOptions ] = useState([])

	const [statusOptions, setStatusOptions] = useState([])

	const [selectedRelease, setSelectedRelease] = useState('')

	const [disableJira, setDisableJira] = useState(true)

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [totalCount, setTotalCount] = useState(0)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [jiraOptions, setJiraOptions] = useState([])

	const [filters, setFilters] = useState({})

	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])

	const [selectedQuery, setSelectedQuery] = useState('')

	const [showQuery, setShowQuery] = useState(false)


	const mongoColumns = useMemo(
		() => [
			{
				Header : "DB Name",
				accessor : "db_name",
				width : 300
			},
			{
				Header : "Status",
				accessor : "status",
				width : 200
			},
			{
				Header : "Query",
				accessor : "query",
				Cell : row => (
						<div className="App-SQ-table-query" onClick={e=>handleQueryClick(row.row.original)}>
              				<img src={PopupIcon} />
           				 </div>
					),
			},
			{
				Header : "Comments",
				accessor : "comments",
				width : 900
			},
			{
				Header : "Start Time",
				accessor : "start_time",
				width : 200
			},
			{
				Header : "Execution Time (sec)",
				accessor : "execution_time",
				width : 100
			},
			{
				Header : "Cluster Name",
				accessor : "cluster_name",
				width : 300
			}
		], [])

	const onSearch = (input) => globalSearch(input);

	useEffect(() => {
		populateMysqlFilter()
	}, [])

	function handleQueryClick(example_query) {
		console.log("example query is")
		console.log(example_query)
		example_query['example_query'] = example_query['query']
		setSelectedQuery(example_query)
		setShowQuery(true)
	}

	function handleQueryClose() {
		setSelectedQuery('')
		setShowQuery(false)
	}

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.db_name &&  value.db_name.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.cluster_name && value.cluster_name.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

  	function translateDateArray(inputArray) {
	    return inputArray.map(item => {
	        
	        return translateDate(item);
	    });
	}

	function translateDate(inputDate) {
		const parts = inputDate.split('-');
        // Assuming the format is always "MM-YYYY"
        const year = parts[1];
        const month = parts[0];

        return `${year}-${month}`;
	}
 
	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var available_months = Object.keys(mysqlFilters)
				var translated_months = translateDateArray(available_months)
				setAllowedMonths(translated_months)
				setSelectedOriginalMonth(translated_months[0] + '-01')
				setSelectedMonth(available_months[0])
				setReleaseOptions(constructFilterArray(Object.keys(mysqlFilters[available_months[0]])))
				setDisableRelease(false)
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	useEffect(() => {
		
		if (loadData) {
			setShowLoading(true)
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/release/releaseStatusData?release_name=" + selectedRelease + "&jira=" + selectedJira +"&status=" + selectedStatus
  		      	, {
		      method : 'GET',	
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result['release_status'])
		    setOriginalData(result['release_status'])
		    setSlowQueryData(result['release_status'])
		    setTotalCount(result['release_status'].length)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	function populateMysqlFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/release/releaseStatusFilter"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMysqlFilters(result)
		    console.log("Hiiii")
		    setShowForm(true)
		}
		doFetch()
	}


	function handleReleaseChange(fieldLabel, fieldValue) {
		setSelectedRelease(fieldLabel)
		console.log(filters)
		console.log(selectedMonth)
		console.log(filters[selectedMonth])
		var available_jiras = Object.keys(filters[selectedMonth][fieldLabel])
		console.log("Available Jiras")
		console.log(available_jiras)
		setJiraOptions(constructFilterArray(available_jiras))
		setSelectedJira('')
		setSelectedStatus('')
		setShowReportDisable(true)
		setDisableJira(false)
		setDisableStatus(true)
	}

	function handleJiraChange(fieldLabel, fieldValue) {
		setSelectedJira(fieldLabel)
		var available_statuses = filters[selectedMonth][selectedRelease][fieldLabel]
		setStatusOptions(constructFilterArray(available_statuses))
		setSelectedStatus('')
		setShowReportDisable(true)
		setDisableStatus(false)
	}

	function handleStatusChange(fieldLabel, fieldValue) {
		setSelectedStatus(fieldLabel)
		setShowReportDisable(false)
	}

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleExportClick() {
		console.log("Export icon is clicked")
		var combinedQuery = []
		combinedQuery.push({'title' : 'Release Jiras', jsonData : slowQueryData})
		convertJSONsToXLSX(combinedQuery)
	}

	function convertJSONsToXLSX(dataSets) {
        if (!dataSets || dataSets.length === 0) {
            console.error("No JSON data provided.");
            return;
        }

        const workbook = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet.jsonData);
            XLSX.utils.book_append_sheet(workbook, ws, dataSet.title || `Sheet${index + 1}`);
        });

        XLSX.writeFile(workbook, "release_jiras.xlsx");
    };

  	function handleMonthChange(date, dateString) {
  		console.log("Months change")
  		console.log(date)
  		console.log(dateString)
  		console.log("Translated Date")
  		setSelectedOriginalMonth(dateString + "-01")
  		var translatedDate = translateDate(dateString)
  		setSelectedMonth(translatedDate)
  		setReleaseOptions(constructFilterArray(Object.keys(filters[translatedDate])))
  		setSelectedRelease('')
		setSelectedStatus('')
		setShowReportDisable(true)
		setDisableRelease(false)
		setDisableJira(true)
		setDisableStatus(true)
  	}
 
	function handleButtonClick(){
		console.log("Button Click is")
		console.log("Load Data is")
		console.log(loadData)
		setTableColumns(mongoColumns)
		if (!loadData) {
			setSlowQueryData([])
			setShowTable(false)
			setLoadData(true)
		}
	}

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleReset() {
		setSelectedDBType('Mongo')
		setSelectedRelease('')
		setSelectedMonth()
		setSelectedOriginalMonth()
		setDisableJira(true)
		setDisableRelease(true)
		setFilters(mysqlFilters)
		setShowReportDisable(true)
		setSlowQueryData([])
	}

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

  	const isDateAllowed = (date) => {
    	return allowedMonths.some(month => {
      		const startOfMonth = moment(month).startOf('month');
      		const endOfMonth = moment(month).endOf('month');
      		return date.isSameOrAfter(startOfMonth) && date.isSameOrBefore(endOfMonth);
    	});
 	 };

  	// Function to disable dates that are not allowed
  	const disableDate = (current) => {
    	// Do not disable dates if current is not a moment date object
    	if (!current || !moment.isMoment(current)) {
     		 return false;
    	}

    	return !isDateAllowed(current);
  	};


	return(
			<div className="App-RJS-tab-container">
				<div className="App-body-RJS-Header-Container">
					<div className="App-body-RJS-Header-Title-Container">
						<div className="App-body-RJS-Header-Title">
							<div className="App-body-RJS-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-RJS-Header-Title-Name">
								Release Jira Status
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ?
				<div className="App-RJS-tab-body">
					<div className="App-RJS-tab-mongo-container">
						<div className="App-RJS-tab-mongo-filter">		
									<div className="App-RJS-tab-mongo-form">
									{showForm ? 
										<div className="App-RJS-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="RJS-inline">
				      								<Form.Item label="Release Time" style={{width : '15%', margin:'10px auto'}}>
				      									<DatePicker value={moment(selectedOriginalMonth)} picker="month" disabledDate={disableDate} onChange={handleMonthChange}/>
					      							</Form.Item>
					      							<Form.Item label="Release Name" style={{width : '25%', margin:'10px auto'}}>
					        							<Select disabled={disableRelease} options={releaseOptions} value={selectedRelease} onChange={handleReleaseChange} />
					      							</Form.Item>
					      							<Form.Item label="Jira" style={{width : '20%', margin:'10px auto'}}>
					        							<Select disabled={disableJira} options={jiraOptions} value={selectedJira} onChange={handleJiraChange} />
					      							</Form.Item>
					      							<Form.Item label="Status" style={{width : '15%', margin:'10px auto'}}>
					        							<Select disabled={disableStatus} options={statusOptions} value={selectedStatus} onChange={handleStatusChange} />
					      							</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : 'auto', marginTop : '40px'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : 'auto', marginTop : '40px'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-SQ-tab-form-loader">
			      							<Loader />
			      						</div>
									}
									</div>

						</div>
						<div className="App-RJS-tab-mongo-data-container">
							<div className="App-RJS-tab-body-table-header-container">
								<div className="App-RJS-table-container-count-box">
								</div>
								<div className="App-RJS-table-container-search-box">
									<div className="App-body-RJ-header-export">
										<img src={ExportIcon} onClick={handleExportClick} />
									</div>
									{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    								{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    							</div>
    						</div>
							{showTable ?
								<div className="App-RJS-tab-body-table-container">
									<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-RJS-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
					</div>				
				</div>
				: 
				 <div className="App-SQ-tab-form-loader">
				 	<Loader />
				 </div>
				}
				{showQuery ? <QueryDisplayer query={selectedQuery} db_type={selectedDBType} handleClose={handleQueryClose} /> : <div></div>}

			</div>
		)

}

export default ReleaseJiraList