import React, { useState, useEffect, useMemo, useRef } from 'react';
import HamburgerIcon from './assets/HamburgerIcon.svg'
import './assets/databaseTasks.css';
import { Button, DatePicker, Form, Input, Radio, Select, TimePicker} from 'antd';
import moment from 'moment';
import Loader from '../common/Loader'
import NewTable from '../common/NewTable'
import SearchIcon from './assets/search.svg'


function DatabaseTasksAssignee(props) {

	const [active, isActive] = useState(true)

	const [form] = Form.useForm();

	const searchRef = useRef(null);

	const { Search } = Input;

	const [hiddenColumns, setHiddenColumns] = useState([])

	const [mysqlFilters, setMysqlFilters] = useState({})

	const [loadData, setLoadData] = useState(false)

	const [jiraUrl, setJiraUrl] = useState('')

	const [selectedDBType, setSelectedDBType] = useState('Mongo')

	const [showForm, setShowForm] = useState(false)

	const [showDashButton, setShowDashButton] = useState(false)

	const [selectedProject, setSelectedProject] = useState([])

	const [showTable, setShowTable] = useState(false)

	const [showLoading, setShowLoading] = useState(true)

	const [selectedAgent, setSelectedAgent] = useState('')

	const [selectedModule, setSelectedModule] = useState('')

	const [selectedOption, setSelectedOption] = useState('')

	const [disableStatus, setDisableStatus] = useState(true)

	const [disableProduct, setDisableProduct] = useState(true)

	const [tableColumns, setTableColumns] = useState([])

	const [projectOptions, setProjectOptions] = useState([])

	const [agentOptions, setAgentOptions] = useState([])

	const [productOptions, setProductOptions] = useState([])

	const [statusOptions, setStatusOptions ] = useState([])

	const [selectedProduct, setSelectedProduct] = useState('')

	const [disableModule, setDisableModule] = useState(true)

	const [showReportDisable, setShowReportDisable] = useState(true)

	const [slowQueryData, setSlowQueryData] = useState([])

	const [moduleOptions, setModuleOptions] = useState([])

	const [filters, setFilters] = useState({})

	const [searchValue, setSearchValue] = useState('')

	const [showSearch, setShowSearch] = useState(false)

	const [originalData, setOriginalData] = useState([])


	// Get current date and time
	const currentDate = new Date();
	const currentTime = currentDate.toLocaleTimeString();

	// Calculate time one hour ago
	const oneHourAgo = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
	const oneHourAgoTime = oneHourAgo.toLocaleTimeString();
	const oneHourAgoDate = oneHourAgo.toLocaleDateString("en-GB");

	// Format today's date
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const todayDate = currentDate.toLocaleDateString("en-GB", options);

	const [startDate, setStartDate] = useState(convertDateString(oneHourAgoDate))

	const [startDateString, setStartDateString] = useState(convertDateString(oneHourAgoDate) + " " + oneHourAgoTime)

	const [endDate, setEndDate] = useState(convertDateString(todayDate))
	const [startTime, setStartTime] = useState(oneHourAgoTime)

	const [endDateString, setEndDateString] = useState(convertDateString(todayDate) + " " + currentTime)

	const [endTime, setEndTime] = useState(currentTime)

	const mongoColumns = useMemo(
		() => [
			{
				Header : "Project",
				accessor : "project_key"
			},
			{
				Header : "Product",
				accessor : "product"
			},
			{
				Header : "Module",
				accessor : "module"
			},

			{
				Header : "Environment",
				accessor : "environment"
			},
			{
				Header : "Priority",
				accessor : "priority"
			},
			{
				Header : "Database",
				accessor : "database"
			},
			{
				Header : "Status",
				accessor : "status"
			},
			{
				Header : "Summary",
				accessor : "summary"
			},
			{
				Header : "Issue Url",
				accessor : "issue_url",
				Cell : row => (
						<div>
							<a href={row.row.original.issue_url} target="_blank"> {row.row.original.issue_url}</a>
						</div>
					)
			},
			{
				Header : "Time Spent",
				accessor : "timespent_minutes",
				Cell : row => (
						<div className="App-body-CS-Right-Cell">
							{row.row.original.timespent_minutes}
						</div>
					)
			},
			{
				Header : "Date Created",
				accessor : "date_created_utc"
			},
			{
				Header : "Date Last Updated",
				accessor : "last_updated_utc"
			},
			{
				Header : "Misc",
				accessor : "misc"
			}
		], [])

	const onSearch = (input) => globalSearch(input);

	useEffect(() => {
		populateMysqlFilter()
	}, [])

	function globalSearch(input)  {
  		setSearchValue(input)
	    let searchInput = input;
	    if (searchInput == "") {
	    	setSlowQueryData(originalData)
	    	setShowSearch(false)
	    } else {
	    	let filteredData = originalData.filter(value => {
	      	return (
	        	value.project_key && value.project_key.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.product && value.product.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.module && value.module.toLowerCase().includes(searchInput.toLowerCase())  ||
	        	value.database && value.database.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.environment &&  value.environment.toLowerCase().includes(searchInput.toLowerCase()) ||
	        	value.summary && value.summary.toLowerCase().includes(searchInput.toLowerCase())
	      		);
	    	});
	    	setSlowQueryData(filteredData);
			}
  	};

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var projects = mysqlFilters['projects']
				var products = mysqlFilters['products']
				var modules = mysqlFilters['modules']
				var agents = mysqlFilters['assignee']
				var status = ['All', 'In Progress', 'Completed']
				console.log(constructFilterArray(projects))
				setProjectOptions(constructFilterArray(projects))
				setAgentOptions(constructFilterArray(agents))
				setStatusOptions(constructFilterArray(status))
				setTableColumns( mongoColumns )
				setShowTable(true)
		}

	}, [showForm])

	useEffect(() => {
		
		if (loadData) {
			setShowLoading(true)
			var apikey = localStorage.getItem('db_portal_app_apikey')
	   		var oauth_token = localStorage.getItem('db_portal_oauth_token')
	   		var dbStartTime = convertDBDateString(startDate) + " " + startTime
	   		var dbEndTime = convertDBDateString(endDate) + " " + endTime
		    const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/dbtasksbyassignee?project=" + selectedProject +"&assignee=" + selectedAgent + "&status=" + selectedOption +"&startTime="
		      	+ dbStartTime + "&endTime=" + dbEndTime
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setOriginalData(result)
		    setSlowQueryData(result)
		    setShowTable(true)
		    setLoadData(false)
		  }
		  doFetch()
		}
	}, [loadData])

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	function convertDBDateString(dateString) {
		  var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}

	function populateMysqlFilter() {
		var apikey = localStorage.getItem('db_portal_app_apikey')
	   	var oauth_token = localStorage.getItem('db_portal_oauth_token')
		const doFetch = async () => {
		      const response = await fetch(BASE_URL + "/v1/dbtasks/getfiltersbyassignee"
  		      	, {
		      method : 'GET',
		      headers : {
		        'x-api-key' : apikey,
		        'oauth-token' : oauth_token
		      }
		    })
		    const body = await response.json()
		    const result = body.message
		    console.log("Result is ")
		    console.log(result)
		    setMysqlFilters(result)
		    console.log("Hiiii")
		    setShowForm(true)
		}
		doFetch()
	}

	function convertDateString(dateString) {
		  var dateParts = dateString.split('/');
		  var month = dateParts[0];
		  var day = dateParts[1];
		  var year = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + month + '-' + day;
	}

	function handleProjectChange(fieldLabel, fieldValue) {
		console.log(fieldLabel)
		console.log(fieldValue)
		setSelectedProject(fieldLabel)
		setSelectedProduct('')
		setSelectedModule('')
		setSelectedOption('')
		setDisableProduct(false)
 	}


	function handleAgentChange(fieldLabel, fieldValue) {
		setSelectedAgent(fieldLabel)
		console.log(mysqlFilters['assignee_dashurl'][fieldLabel])
		setJiraUrl(mysqlFilters['assignee_dashurl'][fieldLabel])
		setDisableStatus(false)
	}

	function handleStatusChange(fieldLabel, fieldValue) {
		setSelectedOption(fieldLabel)
		setShowReportDisable(false)
	}

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleStartDate(value, dateString) {
		console.log("Start Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		console.log("Converted date is ")
		console.log(convertRangeDateString(date))
		setStartDate(convertRangeDateString(date))
		setStartTime(time)
		setStartDateString(convertRangeDateString(date) + " " + time)
	}

	function handleStartTime(value, timeString) {
		console.log("Start Time Change")
		console.log(value, timeString)
		setStartTime(timeString)
	}

	function handleEndDate(value, dateString) {
		console.log("End Date Change")
		console.log(value, dateString)
		var date = dateString.split(' ')[0]
		var time = dateString.split(' ')[1]
		setEndDate(convertRangeDateString(date))
		setEndTime(time)
		setEndDateString(convertRangeDateString(date) + " " + time)
	}

	function handleEndTime(value, timeString) {
		console.log(" End Time Change ")
		console.log(value, timeString)
		setEndTime(timeString)
	}

	function convertRangeDateString(dateString) {
		console.log(" Date string is ")
		console.log(dateString)
		 var dateParts = dateString.split('-');
		  var year = dateParts[0];
		  var month = dateParts[1];
		  var day = dateParts[2];

		  // Pad single-digit month and day with leading zero if necessary
		  if (month.length === 1) {
		    month = '0' + month;
		  }
		  if (day.length === 1) {
		    day = '0' + day;
		  }

		  return year + '-' + day + '-' + month;
	}

	function handleButtonClick(){
		console.log("Button Click is")
		console.log("Loaad Data is")
		console.log(loadData)
		setTableColumns(mongoColumns)
		if (!loadData) {
			setSlowQueryData([])
			setShowTable(false)
			setLoadData(true)
			setShowDashButton(true)
		}
	}

	useEffect(() => {
		console.log("Show Form change")
		console.log(showForm)
		if(showForm) {
			if (selectedDBType == 'Mongo') {
				console.log(mysqlFilters)
				setFilters(mysqlFilters)
				var projects = mysqlFilters['projects']
				var agents = mysqlFilters['assignee']
				var status = ['All', 'In Progress' ,'Completed']
				console.log(constructFilterArray(projects))
				setProjectOptions(constructFilterArray(projects))
				setAgentOptions(constructFilterArray(agents))
				setStatusOptions(constructFilterArray(status))
				setTableColumns(mongoColumns)
				setShowTable(true)
			}
		}

	}, [showForm])

	function constructFilterArray(values) {
  		var resultArray = []
  		for (var index in values) {
  			var value = values[index]
  			var result = {}
  			result['value'] = value
  			result['label'] = value
  			resultArray.push(result)
  		}
  		return resultArray.sort((a, b) => a.value.localeCompare(b.value))
  	}

  	function handleReset() {
		setSelectedDBType('Mongo')
		setSelectedProduct()
		setSelectedModule()
		setSelectedProject([])
		setSelectedOption()
		setDisableModule(true)
		setDisableProduct(true)
		setDisableStatus(true)
		setFilters(mysqlFilters)
		setShowReportDisable(true)
		setShowDashButton(false)
		setSlowQueryData([])
	}

	function handleShowSearch() {
  		setShowSearch(true)
  		
  		searchRef.current.focus({
      	cursor: 'start',
      })
  	}

	return(
			<div className="App-DT-tab-container">
				<div className="App-body-DT-Header-Container">
					<div className="App-body-DT-Header-Title-Container">
						<div className="App-body-DT-Header-Title">
							<div className="App-body-DT-Header-Title-Hamburger">
								<img src={HamburgerIcon} />
							</div>
							<div className="App-body-DT-Header-Title-Name">
								Database Tasks By Assignee
							</div>
						</div>
					</div>
				</div>
				{showForm && showTable ?
				<div className="App-DT-tab-body">
					<div className="App-DT-tab-mongo-container">
						<div className="App-DT-tab-mongo-filter">		
									<div className="App-DT-tab-mongo-form">
									{showForm ? 
										<div className="App-DT-tab-mongo-filter-form">
											<Form
				      							form={form}
				      							layout="vertical"
				      						>
				      							<div className="DT-inline">
				      								<Form.Item label="Project" style={{width : '20%', margin:'10px auto'}}>
					        							<Select mode="multiple" options={projectOptions} value={selectedProject} onChange={handleProjectChange} />
					      							</Form.Item>

					      							<Form.Item label="Assignee" style={{width : '20%', margin:'10px auto'}}>
			        									<Select options={agentOptions} value={selectedAgent} onChange={handleAgentChange} />
			      									</Form.Item>
					      							
					      							<Form.Item label="Status" style={{width : '10%', margin:'10px auto'}}>
					        							<Select disabled={disableStatus} options={statusOptions} value={selectedOption} onChange={handleStatusChange} />
					      							</Form.Item>
					      							<Form.Item label="From Date" style={{width : '15%', margin:'10px auto'}}>
				        								<DatePicker showTime  style={{display:'flex'}} value={moment(startDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleStartDate} />
				      								</Form.Item>

				      								<Form.Item label="To Date" style={{width : '15%', margin:'10px auto'}}>
				        								<DatePicker  showTime style={{display:'flex'}} value={moment(endDateString, 'YYYY-DD-MM HH:mm:ss')} onChange={handleEndDate} />
				      								</Form.Item>
					      							<Button
					      							 	disabled={showReportDisable}
							            				type='primary'
							            				onClick={handleButtonClick}
							            				style={{margin : 'auto', marginTop : '40px'}}
							              			>
				              							Show Report
				            						</Button>
				            						<Button
				            							onClick={handleReset}
				            							style={{ margin : 'auto', marginTop : '40px'}}
				            						>
				            							Reset
				            						</Button>	      							
				      							</div>
				      						</Form>
										</div>
									: 
										<div className="App-SQ-tab-form-loader">
			      							<Loader />
			      						</div>
									}
									</div>

						</div>
						<div className="App-DT-tab-mongo-data-container">
							<div className="App-DT-table-container-search-box-container">
							{showDashButton && <div className="App-DT-table-container-task-link">
								<Button
									type='primary'
								> 
									<a href={jiraUrl} target="#"> Open Dashboard </a>
								</Button>
							</div> }

							<div className="App-DT-table-container-search-box">

								{!showSearch && <img src={SearchIcon} onClick={handleShowSearch} /> }
    							{showSearch && <Search autoFocus placeholder="input search text" allowClear={true} onSearch={onSearch} style={{ width: 400 }} /> }
    						</div>
    						</div>
							{showTable ?
								<div className="App-DT-tab-body-table-container">
									<NewTable data={slowQueryData} columns={tableColumns} hiddenColumns={hiddenColumns} />
								</div>
							: 
								<div className="App-DT-tab-body-table-loader">
									<Loader />
								</div> 
							}
						</div>
					</div>				
				</div>
				: 
				 <div className="App-SQ-tab-form-loader">
				 	<Loader />
				 </div>
				}
			</div>
		)
}

export default DatabaseTasksAssignee